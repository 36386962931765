import { forwardRef } from "react";
import { ListViewBody } from "./components/list-view-body";
import { ListViewContainer } from "./components/list-view-container";
import { ListViewHeader } from "./components/list-view-header";
import { ListViewProvider } from "./list-view-context";
import { ListViewProps } from "./list-view.types";

export const ListView = forwardRef<HTMLDivElement, ListViewProps>(
  ({ columns, rows, tableColor, rowActionsMenuItems, children, id }, ref) => (
    <ListViewProvider
      tableColor={tableColor}
      columns={columns}
      rows={rows}
      rowActionsMenuItems={rowActionsMenuItems}
      ref={ref}
      id={id}
    >
      <ListViewContainer>
        <ListViewHeader />
        <ListViewBody />
      </ListViewContainer>
      {children}
    </ListViewProvider>
  ),
);
