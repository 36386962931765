import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { inviteToApply } from "~/mutations/candidate/inviteToApply";

export const useInviteToApply = (
  options: UseMutationOptions<
    any,
    unknown,
    { applicationId: number; inviteMessage: string },
    unknown
  >,
) => useMutation({ mutationFn: inviteToApply, ...options });
