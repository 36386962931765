import React from "react";
import { cn } from "~/utils/cn";
import { EmptyStateProps } from "./empty-state.types";
import { ListStatusPlaceholderTemplate } from "../../template";

export const EmptyState: React.FC<EmptyStateProps> = ({
  message,
  title,
  className,
  actionButton,
  name,
}) => {
  return (
    <ListStatusPlaceholderTemplate
      className={className}
      Icon={({ className }: { className: string }) => (
        <i
          className={cn(
            className,
            "fa-regular fa-magnifying-glass !h-9 !w-9 !text-4xl text-primary-text",
          )}
        />
      )}
      title={title ?? `No ${name} found!`}
      message={
        message ??
        `We're sorry, but it seems like there are no ${name} for you to review at this stage.`
      }
      actionButton={actionButton}
    />
  );
};
