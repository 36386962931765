import * as R from "ramda";
import { Sources } from "./source-select.types";
import { SOURCES } from "~/scalis-components";

export const getSourceOptions = (sources: Sources) => {
  return R.map((source: keyof typeof SOURCES) => {
    return {
      label: SOURCES[source].name,
      sourcesNames: sources[source],
      value: source,
    };
  }, R.keys(sources));
};
