"use client";

import React, { createContext, useEffect, useMemo } from "react";
import { useGetMilestones } from "~/hooks/pipelines/use-get-milestones";
import {
  GetFilteredCandidatesContextProps,
  GetFilteredCandidatesContextTypes,
} from "./get-filtered-candidates-context.types";

//TODO: this parse here is no good. Could lead to bugs because everyone using it would be
//receiving a context that suggest a type but could be a empty object
export const GetFilteredCandidatesContext = createContext(
  {} as GetFilteredCandidatesContextTypes,
);

export const GetFilteredCandidatesProvider: React.FC<
  GetFilteredCandidatesContextProps
> = ({ children, jobId, filters, defaultQueryParams }) => {
  const queryParams = useMemo(
    () => ({ ...defaultQueryParams, ...filters }),
    [filters, defaultQueryParams],
  );

  const {
    milestones: data,
    isLoadingMilestones,
    refetch,
    milestonesError,
  } = useGetMilestones(jobId, queryParams);

  useEffect(() => {
    refetch();
  }, [filters]);

  return (
    <GetFilteredCandidatesContext.Provider
      value={{
        milestones: data?.milestones ?? [],
        isLoadingMilestones,
        milestonesError,
      }}
    >
      {children}
    </GetFilteredCandidatesContext.Provider>
  );
};
