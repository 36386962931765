"use client";

import { CheckedState } from "@radix-ui/react-checkbox";
import { format } from "date-fns";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import {
  ChangeEventHandler,
  FC,
  FormEventHandler,
  useMemo,
  useState,
} from "react";

import Spinner from "~/components/spinner";
import { Button } from "~/components/v2/Button";
import { Checkbox } from "~/components/v2/Checkbox";
import { useCreateNote } from "~/hooks/candidate/useCreateNote";
import { TabProps } from "~/scalis-components/pipeline/detailed-candidate-view";
import { NoteCard } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/notes/note-card";
import { CustomCombobox } from "../../../../custom-combobox";

export const NotesTab: React.FC<TabProps> = ({ data, notes, userId }) => {
  const [process, setProcess] = useState<number[]>([]);

  const options = useMemo(
    () =>
      notes.reduce(
        (acc: any, curr: any) => {
          if (
            !acc.find((option: any) => option.value === curr.application.jobId)
          ) {
            acc.push({
              label: curr.application.job_id.job_title,
              value: curr.application.jobId,
              details: `Applied on ${
                curr.application.createdAt
                  ? format(new Date(curr.application.createdAt), "P")
                  : ""
              }`,
            });
          }
          return acc;
        },
        [] as { label: string; value: number; details?: string }[],
      ),
    [notes],
  );

  const filteredNotes = notes.filter((note: any) => {
    return process.length ? process.includes(note.application.jobId) : true;
  });

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center justify-between border-b px-4 py-[10px]">
        <span className="font-semibold text-typography-color">Notes</span>
        <CustomCombobox
          setValue={setProcess}
          placeholder="Select job process"
          options={options}
          value={process}
          emptyTerm="process"
          name="process"
          searchPlaceholder="Search..."
          className="min-w-[12rem]"
        />
      </div>
      <div className="scroll-container">
        <div className="menu-candidate-view-details-scroll">
          <div className="flex flex-1 flex-col px-4">
            {filteredNotes.map((note: any) => (
              <NoteCard {...note} key={note.id} userId={userId} />
            ))}
          </div>
        </div>
      </div>

      <Input applicationId={data.id} />
    </div>
  );
};

const Input: FC<{ applicationId: number }> = ({ applicationId }) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { mutateAsync } = useCreateNote();
  const [value, setValue] = useState("");
  const [privateValue, setPrivate] = useState<CheckedState>(false);
  const [loading, setLoading] = useState(false);

  const asPath = `${pathname}?${searchParams?.toString()}`;

  const handleSubmit: FormEventHandler = e => {
    e.preventDefault();
    setLoading(true);
    mutateAsync({
      applicationId,
      data: { notes: value, private: !!privateValue },
    })
      .then(() => {
        setValue("");
        setPrivate(false);
        router.replace(asPath);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = e => {
    setValue(e.target.value);
  };

  return (
    <div className="px-4 pb-0">
      <form className="mx-auto max-lg:max-w-[32.5rem]" onSubmit={handleSubmit}>
        <div className="overflow-hidden rounded-[.25rem] border border-borders">
          <textarea
            placeholder="Add a note..."
            className="h-[3.75rem] w-full resize-none px-3 pt-2 text-sm placeholder:text-typography-low-contrast-text focus:outline-none"
            value={value}
            onChange={handleChange}
          />
          <div className="flex items-end justify-between px-3 pb-3">
            <div className="flex flex-col">
              <label className="flex items-center gap-2 text-sm text-dark-color-text-100">
                <Checkbox checked={privateValue} onCheckedChange={setPrivate} />
                Private note
              </label>
              <span className="text-xs text-typography-low-contrast-text">
                {privateValue
                  ? "Only admins can see this note."
                  : "The other users can see this note"}
              </span>
            </div>
            <Button variant="outline" type="submit" disabled={loading}>
              {loading ? <Spinner /> : "Done"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
