"use client";

import { Controller, useFormContext } from "react-hook-form";
import { PopUpFilter } from "../../pop-up-filter";
import { MatchScore } from "../../../../filter-type";
import {
  FIELD_NAME_MATCH_SCORE,
  FORM_FIELDS,
} from "~/scalis-components/pipeline/list-view/candidate-filters/candidate-flters.contants";
import { MatchScoreFilterProps } from "./match-score-filter.types";

export const MatchScoreFilter: React.FC<MatchScoreFilterProps> = ({
  onApplyFilters,
}) => {
  const { control } = useFormContext();

  const name = FIELD_NAME_MATCH_SCORE;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <PopUpFilter
          name={name}
          label={FORM_FIELDS[FIELD_NAME_MATCH_SCORE].label}
          active={value[0] !== 0 || value[1] !== 100}
          onApplyFilters={onApplyFilters}
        >
          <MatchScore onChange={onChange} value={value} />
        </PopUpFilter>
      )}
    />
  );
};
