"use client";

import {
  ColumnDef,
  PaginationState,
  SortingState,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";

import {
  TABLE_HEADER_HEIGHT,
  defaultColumnsID,
  getTableColumns,
  moveColumnToLastPosition,
} from "./list-view.utils";
import {
  ListViewContextType,
  ListViewProviderProps,
} from "./list-view-context.types";
import { STATUS } from "../status";

export const ListViewContext = createContext({} as ListViewContextType);

export const ListViewProvider = React.forwardRef<
  HTMLDivElement,
  ListViewProviderProps
>(({ children, columns, rows, tableColor, rowActionsMenuItems, id }, ref) => {
  const [isShowingShadow, setIsShowingShadow] = useState(false);
  const [data, setData] = useState(() => rows);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  const dataIds = useMemo<UniqueIdentifier[]>(
    () => data?.map(({ id }) => id),
    [data],
  );

  const [activeRowId, setActiveRowId] = useState<UniqueIdentifier | null>(null);

  const onAddColumn = (columnId: string) => {
    setColumnOrder(columnOrder => {
      return moveColumnToLastPosition(columnOrder, columnId);
    });
  };

  const tableColumns = useMemo<ColumnDef<any>[]>(() => {
    return getTableColumns({ columns, rowActionsMenuItems, onAddColumn });
  }, [columns]);

  const [columnOrder, setColumnOrder] = useState<string[]>(() =>
    tableColumns.map(c => c.id!),
  );

  const [columnVisibility, setColumnVisibility] = useState<
    Record<string, boolean>
  >({
    [defaultColumnsID.addColumn]: false,
  });

  const fixedColumnsIds = useMemo(() => {
    const variableFixedColumnIds = tableColumns.reduce((acc, column) => {
      if (column.meta?.isPinned) {
        acc.push(column.id!);
      }
      return acc;
    }, [] as string[]);

    return [
      defaultColumnsID.rowActions,
      defaultColumnsID.select,
      defaultColumnsID.addColumn,
      ...variableFixedColumnIds,
    ];
  }, [tableColumns]);

  const [activeColumnId, setActiveColumnId] = useState<UniqueIdentifier | null>(
    null,
  );

  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 50,
  });

  const table = useReactTable({
    data,
    columns: tableColumns,
    columnResizeMode: "onChange",
    columnResizeDirection: "ltr",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      columnOrder,
      columnVisibility,
      sorting,
      pagination,
    },
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    getRowId: row => row.id,
    enableRowSelection: row => row.original?.status !== STATUS.REJECTED.key,
  });

  const showHeaderShadow = (e: Event) => {
    const element = e.target as HTMLDivElement;

    setIsShowingShadow(element?.scrollTop > TABLE_HEADER_HEIGHT);
  };

  useEffect(() => {
    if (ref && typeof ref === "object") {
      const container = ref.current;

      if (container) {
        container.addEventListener("scroll", showHeaderShadow);

        return () => {
          container.removeEventListener("scroll", showHeaderShadow);
        };
      }
    }
  }, [ref]);

  return (
    <ListViewContext.Provider
      value={{
        table,
        tableColor,
        columnOrder,
        dataIds,
        data,
        setColumnOrder,
        setData,
        tableColumns,
        isShowingShadow,
        activeRowId,
        setActiveRowId,
        fixedColumnsIds,
        activeColumnId,
        setActiveColumnId,
        columnVisibility,
        setColumnVisibility,
        sorting,
        id,
        pagination,
        setPagination,
      }}
    >
      {children}
    </ListViewContext.Provider>
  );
});
