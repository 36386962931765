import React from "react";
import { SelectField } from "~/components/v2/SelectField";
import { FIELD_NAME_JOB_SELECT, LABEL_JOB_SELECT } from "./job-select.constants";
import { JobSelectProps } from "./job-select.types";
import { MatchCandidateDrawerContext } from "../match-candidate-drawer-context";

export const JobSelect: React.FC<JobSelectProps> = ({
  onValueChange,
  value,
  error,
  ...props
}) => {
  const ctx = React.useContext(MatchCandidateDrawerContext);
  const jobs = ctx?.jobs || props?.jobs || []
  const defaultValue = jobs?.[0]?.id?.toString() || "";

  React.useEffect(() => {
    onValueChange?.(FIELD_NAME_JOB_SELECT, defaultValue);
  }, []);

  return (
    <SelectField
      label={LABEL_JOB_SELECT}
      aria-label={LABEL_JOB_SELECT}
      name={FIELD_NAME_JOB_SELECT}
      placeholder="Select"
      searchPlaceholder="Search..."
      emptyTerm="job"
      options={jobs?.map((job) => ({
        label: job.title || "",
        value: job.id?.toString() || "",
      }))}
      className="w-full"
      setValue={onValueChange}
      value={value || defaultValue}
      error={error}
      required={true}
    />
  );
};
