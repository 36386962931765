"use client";

import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { useContext } from "react";
import { ListViewColumn } from "~/components/v2/list-view/list-view.types";
import { industries } from "~/utils/industries";

import { MatchScore } from "./components/match-score/match-score";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/v2/Tooltip";
import { DetailedCandidateContext } from "~/scalis-components/pipeline/detailed-candidate-view/detailed-candidate-context";
import { Status } from "~/components/v2/status";

const dateFormater = (date?: string) => {
  if (!date) return null;

  return format(new Date(date), "EEEE',' LLL d',' u h:maaa");
};

const matchScoreFormater = (score?: number) => {
  if (!score) return null;

  const formattedNumber = Math.round(score * 100);

  return <MatchScore score={formattedNumber} />;
};

const industryFormatter = (industry?: string) => {
  if (!industry) return null;

  return industries.find(i => i.value === industry)?.label || industry;
};

const applicationTypeFormatter = (applicationType?: string) => {
  if (!applicationType) return null;
  if (applicationType === "STATIC") return "Uploaded Profile";
  if (applicationType === "DYNAMIC") return "SCALIS Profile";
  return null;
};

const copyCell = (value: string) => {
  return (
    <div className="group relative flex w-full items-center justify-between">
      <div className="overflow-hidden text-ellipsis text-sm text-typography-high-contrast">
        {value}
      </div>
      <div className="invisible absolute right-0 cursor-pointer rounded-full p-0.5 active:bg-background-pressed group-hover:visible group-hover:static">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <DocumentDuplicateIcon
                onClick={() => {
                  navigator.clipboard.writeText(value);
                }}
                fontSize={16}
                className="h-4 w-4"
              />
            </TooltipTrigger>
            <TooltipContent>
              <p className="z-50">Copy</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
};

const candidateNameCell = (value: string, row?: any) => {
  const { jobId, applyApplicationId, openDetailedCandidateDrawer } = useContext(
    DetailedCandidateContext,
  );

  const handleOpenDetailedCandidateDrawer = () => {
    applyApplicationId(Number(row.id));
    openDetailedCandidateDrawer();
  };

  if (!row || !jobId) return value;

  return (
    <a className="w-full" onClick={handleOpenDetailedCandidateDrawer}>
      {value}
    </a>
  );
};

const statusFormater = (status?: string) => {
  return (
    <div className="flex">
      <Status status={status} lastUpdatedStatusAt={""} />
    </div>
  );
};

export const candidateListViewColumns = [
  {
    key: "name",
    value: "Candidate Name",
    meta: {
      isPinned: true,
      header: {
        isDraggable: false,
        canHide: false,
      },
      rows: {
        isDraggable: true,
        contentRender: candidateNameCell,
      },
    },
    size: 190,
  },
  {
    key: "status",
    value: "Status",
    meta: {
      rows: {
        isDraggable: true,
        contentRender: statusFormater,
      },
    },
    size: 190,
  },
  {
    key: "matchScore",
    value: "Match Score",
    meta: {
      rows: {
        contentRender: matchScoreFormater,
        lessPadding: true,
      },
    },
    size: 120,
  },
  // {
  //   key: "currentJob",
  //   value: "Current Job",
  //   size: 220,
  // },
  // {
  //   key: "currentCompany",
  //   value: "Current Company",
  //   size: 180,
  // },
  // {
  //   key: "currentLocation",
  //   value: "Current Location",
  //   size: 220,
  // },
  {
    key: "mostRecentJob",
    value: "Most Recent Job",
    size: 220,
  },
  {
    key: "mostRecentCompany",
    value: "Most Recent Company",
    size: 180,
  },
  {
    key: "mostRecentLocation",
    value: "Most Recent Location",
    size: 220,
  },
  {
    key: "mostRecentIndustry",
    value: "Most Recent Industry",
    size: 220,
    meta: {
      rows: {
        contentRender: industryFormatter,
      },
    },
  },
  {
    key: "applicationType",
    value: "Application Type",
    meta: {
      rows: {
        contentRender: applicationTypeFormatter,
      },
    },
  },
  {
    key: "email",
    value: "Email",
    size: 240,
    meta: {
      rows: {
        contentRender: copyCell,
      },
    },
  },
  {
    key: "educationLevel",
    value: "Education Level",
  },
  {
    key: "educationInstitution",
    value: "Education Institution",
    size: 280,
  },
  {
    key: "phone",
    value: "Phone Number",
    meta: {
      rows: {
        contentRender: copyCell,
      },
    },
  },
  {
    key: "appliedAt",
    value: "Aplication Date",
    meta: {
      rows: {
        contentRender: dateFormater,
      },
    },
    size: 270,
  },
] as ListViewColumn[];
