import { UploadIcon } from "@radix-ui/react-icons";
import { useS3Upload } from "next-s3-upload";
import { Button } from "../Button";
import { FileItem } from "./components";
import { FileInputProps } from "./file-input.types";
import { useUploadFiles } from "./hooks/use-upload-files";
import * as R from 'ramda'

export const FileInput = ({
    allowedExtension, name, isMultiple, label, error: formError, ...props
}: FileInputProps) => {
    const { resetFiles, uploadToS3 } = useS3Upload();

    const onFileChange = async (files: FileList) => {
        const formattedFiles = [];
        for (const file of files) {
            const { key } = await uploadToS3(file);
            formattedFiles.push({ key, name: file.name });
        }
        const fileNames = R.pluck('key', formattedFiles);
        props.onFileChange(fileNames);
        return formattedFiles
    };

    const { isUploading, error, files, handleFileChange, handleRemove } = useUploadFiles({
        allowedExtension,
        onFileChange,
        onFileRemoval: resetFiles,
    })

    return (
        <div className="flex flex-col gap-6">
            <div>
                <input
                    type="file"
                    name={name}
                    multiple={isMultiple}
                    className="hidden"
                    id="fileInput"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleFileChange(event.target.files!)}
                />
                <Button variant="outline" className="w-full relative" isLoading={isUploading} type="button">
                    <label className="w-full flex gap-2 justify-center cursor-pointer" htmlFor="fileInput">{label}<UploadIcon width="20" height="20" /></label>
                </Button>
            </div>
            <div>
                {files?.map?.(({ name, key }, index) => (
                    <div key={name}>
                        <FileItem name={name} fileKey={key} index={index} handleRemove={handleRemove} />
                    </div>
                ))}
            </div>
            {formError || error &&
                <div className="mt-1 text-xs text-error text-left" hidden={!error}>
                    {formError || error}
                </div>
            }
        </div>
    );
};
