import React from "react";
import { ConnectCalendarNoteProps } from "./connect-calendar-note.type";

export const ConnectCalendarNote: React.FC<ConnectCalendarNoteProps> = () => {
  return (
    <div className="flex gap-2 px-3 py-2 border rounded-lg border-borders-ring bg-primary-background ">
      <i className="mt-1 fa-regular fa-info-circle text-icons" />
      <div className="text-sm text-typography-high-contrast">
        <span className="text-sm font-semibold">Note:</span> To send a scheduler
        link please connect your calendar to SCALIS.
      </div>
    </div>
  );
};
