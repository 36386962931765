import { usePathname, useRouter, useSearchParams } from "next/navigation";
import axios from "~/services/axios.service";
import { ScalisCandidate } from "~/types/pipeline";

type Action = ScalisCandidate["actions"][number];

type Command = {
  jobId?: number;
  candidateId: number;
  action: Action;
};

const actions: { [action in Action]: (command: Command) => Promise<void> } = {
  async advance(command) {
    return axios.put(`/api/candidates/${command.candidateId}/advance`);
  },
  async chat() {},
  async invite_to_apply(command) {
    return axios.post(`/api/candidates/${command.candidateId}/inviteToApply`);
  },
  async match_to_job() {},
  async reject() {},
  async reject_x() {},
};

export const useActions = () => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const asPath = `${pathname}?${searchParams?.toString()}`;

  const refresh = async () => router.replace(asPath);

  const setStage = async (applicantId: number, stageId: number) => {
    await axios.put(`/api/candidates/${applicantId}/set`, {
      stage_id: stageId,
    });
    await refresh();
  };
  const setPipeline = async (jobId: number, pipelineId: number) => {
    await axios.patch(`/api/job/${jobId}`, {
      pipeline_id: pipelineId,
    });

    await router.replace(asPath);
  };

  return {
    setStage,
    setPipeline,
    dispatch: async (command: Command) =>
      actions[command.action](command).then(refresh),
  };
};
