export const stateCodeToName: { [key: string]: string } = {
  AA: "Armed Forces America",
  AE: "Armed Forces",
  AK: "Alaska",
  AL: "Alabama",
  AP: "Armed Forces Pacific",
  AR: "Arkansas",
  AZ: "Arizona",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DC: "Washington DC",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  IA: "Iowa",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  MA: "Massachusetts",
  MD: "Maryland",
  ME: "Maine",
  MI: "Michigan",
  MN: "Minnesota",
  MO: "Missouri",
  MS: "Mississippi",
  MT: "Montana",
  NC: "North Carolina",
  ND: "North Dakota",
  NE: "Nebraska",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NV: "Nevada",
  NY: "New York",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VA: "Virginia",
  VI: "Virgin Islands",
  VT: "Vermont",
  WA: "Washington",
  WI: "Wisconsin",
  WV: "West Virginia",
  WY: "Wyoming",
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NF: "Newfoundland",
  NT: "Northwest Territories",
  NS: "Nova Scotia",
  NU: "Nunavut",
  ON: "Ontario",
  PE: "Prince Edward Island",
  PQ: "Quebec",
  SK: "Saskatchewan",
  YT: "Yukon",
};

export const stateNameToCode: { [key: string]: string } = {
  "Armed Forces America": "AA",
  "Armed Forces": "AE",
  Alaska: "AK",
  Alabama: "AL",
  "Armed Forces Pacific": "AP",
  Arkansas: "AR",
  Arizona: "AZ",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  "Washington DC": "DC",
  Delaware: "DE",
  Florida: "FL",
  Georgia: "GA",
  Guam: "GU",
  Hawaii: "HI",
  Iowa: "IA",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Massachusetts: "MA",
  Maryland: "MD",
  Maine: "ME",
  Michigan: "MI",
  Minnesota: "MN",
  Missouri: "MO",
  Mississippi: "MS",
  Montana: "MT",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Nebraska: "NE",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  Nevada: "NV",
  "New York": "NY",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Puerto Rico": "PR",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Virginia: "VA",
  "Virgin Islands": "VI",
  Vermont: "VT",
  Washington: "WA",
  Wisconsin: "WI",
  "West Virginia": "WV",
  Wyoming: "WY",
  Alberta: "AB",
  "British Columbia": "BC",
  Manitoba: "MB",
  "New Brunswick": "NB",
  Newfoundland: "NF",
  "Northwest Territories": "NT",
  "Nova Scotia": "NS",
  Nunavut: "NU",
  Ontario: "ON",
  "Prince Edward Island": "PE",
  Quebec: "PQ",
  Saskatchewan: "SK",
  Yukon: "YT",
};
