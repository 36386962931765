"use client";

import { Controller, useFormContext } from "react-hook-form";
import { PopUpFilter } from "../../pop-up-filter";
import { DatePicker } from "~/components/v2/DatePicker";
import { DateFilterProps } from "./date-filter.types";

export const DateFilter: React.FC<DateFilterProps> = ({
  label,
  name,
  mode = "range",
  onApplyFilters,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <PopUpFilter
          name={name}
          label={label}
          active={!!value}
          onApplyFilters={onApplyFilters}
        >
          <DatePicker
            date={value}
            onSelect={onChange}
            mode={mode}
            className="w-full rounded-md border-borders"
          />
        </PopUpFilter>
      )}
    />
  );
};
