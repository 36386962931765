import { Drawer, DrawerContent } from "~/components/v2/Drawer";
import { CandidateApplicationView } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-drawer/components/candidate-application-view";
import { CandidateDrawerActions } from "./components/candidate-drawer-actions";
import { DetailedCandidateDrawerProvider } from "./detailed-candidate-drawer-context";
import { DetailedCandidateDrawerProps } from "./detailed-candidate-drawer.types";

export const DetailedCandidateDrawer = ({
  jobId,
  initialApplicationId,
  open,
  onOpenChange,
}: DetailedCandidateDrawerProps) => {
  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent
        asChild
        onResize={() => false}
        className="w-[60rem] bg-background-blue"
      >
        <div>
          <DetailedCandidateDrawerProvider
            jobId={jobId}
            initialApplicationId={initialApplicationId}
            onDrawerClose={onOpenChange}
          >
            <CandidateDrawerActions />
            <CandidateApplicationView />
          </DetailedCandidateDrawerProvider>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
