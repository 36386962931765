import { Transform, CSS } from "@dnd-kit/utilities";
import { CSSProperties } from "react";
import { tv } from "tailwind-variants";

export const dndStyles = ({
  transform,
  transition,
  isSorting,
}: {
  transform: Transform | null;
  transition?: string;
  isSorting: boolean;
}): CSSProperties => ({
  transform: isSorting ? undefined : CSS.Transform.toString(transform),
  transition: transition,
});

export const tr = tv({
  base: "flex relative group after:absolute after:right-0 after:z-30 after:block after:h-1 after:w-full after:content-['']",
  variants: {
    insertPosition: {
      before: "after:top-0 after:bg-actions-muted",
      after: "after:bottom-0 after:bg-actions-muted",
    },
  },
});

export const td = tv({
  base: "last:flex-1 relative select-none bg-white text-sm font-normal text-typography-high-contrast group-hover:bg-background-hover",
  variants: {
    isActive: {
      true: "bg-background-pressed group-hover:bg-background-pressed",
    },
    isHideHeaderShadow: { true: "!z-40" },
    isHidden: { true: "bg-white group-hover:bg-white" },
    hasHoverBg: { false: "bg-white group-hover:bg-white" },
    isPinned: { true: "sticky z-30" },
    colorColumn: {
      true: "before:content-[''] before:w-2 before:absolute before:top-0 before:bottom-0 before:left-0",
    },
    isLastColumn: { true: "before:rounded-bl-lg" },
    tableColor: {
      "01": "before:bg-extra-01",
      "02": "before:bg-extra-02",
      "03": "before:bg-extra-03",
      "04": "before:bg-extra-04",
      "05": "before:bg-extra-05",
      "06": "before:bg-extra-06",
      "07": "before:bg-extra-07",
      "08": "before:bg-extra-08",
      "09": "before:bg-extra-09",
      "10": "before:bg-extra-10",
    },
  },
});

export const containerDiv = tv({
  base: "p-2 flex items-center justify-center group-hover:visible h-full",
  variants: {
    isHidden: { true: "invisible p-0 " },
    hasBorder: {
      true: "border-b border-r border-solid border-borders-border",
    },
    hasElipseContent: {
      true: "justify-start",
      false: "justify-center",
    },
    hasLessPadding: { true: "py-1" },
    isColoredColumn: { true: "last:rounded-bl-lg" },
  },
});

export const content = tv({
  base: "w-full",
  variants: {
    hasElipseContent: {
      true: "text-ellipsis overflow-hidden whitespace-nowrap",
    },
  },
});
