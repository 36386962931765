const colors = ["#ce2021", "#f76521", "#ffba10", "#cedf29", "#42b64a"];

export const Score: React.FC<{ radius: number; percent: number }> = ({
  radius,
  percent,
}) => {
  const strokeWidth = radius / 6;
  const innerRadius = radius - strokeWidth;
  const quintile = Math.round(percent * colors.length) - 1;
  const center = [radius, 0];

  const outerX = center[0] + radius * Math.cos(Math.PI - percent * Math.PI);
  const outerY = center[1] + radius * Math.sin(Math.PI - percent * Math.PI);
  const innerX =
    center[0] + innerRadius * Math.cos(Math.PI - percent * Math.PI);
  const innerY =
    center[1] + innerRadius * Math.sin(Math.PI - percent * Math.PI);
  return (
    <svg viewBox={`0 0 ${radius * 2} ${radius + strokeWidth / 2}`}>
      <g transform={`translate(0,${radius})`}>
        <g transform="scale(1,-1)">
          <path
            d={`
        M 0 0 
        A ${radius} ${radius} 0 0 0 ${radius * 2} 0
        A ${strokeWidth / 2} ${strokeWidth / 2} 0 0 0 ${
          radius * 2 - strokeWidth
        } 0
        A ${innerRadius} ${innerRadius} 0 0 1 ${strokeWidth} 0
        A ${strokeWidth / 2} ${strokeWidth / 2} 0 0 0 0 0
      `}
            fill="#E2E8F0"
          />
          <path
            d={`
        M 0 0 
        A ${radius} ${radius} 0 0 0 ${outerX} ${outerY}
        A ${strokeWidth / 2} ${strokeWidth / 2} 0 0 0 ${innerX} ${innerY}
        A ${innerRadius} ${innerRadius} 0 0 1 ${strokeWidth} 0
        A ${strokeWidth / 2} ${strokeWidth / 2} 0 0 0 0 0
      `}
            fill={colors[quintile]}
          />
        </g>
      </g>
      <text
        x="50%"
        y="88%"
        width={radius * 2}
        textAnchor="middle"
        fontWeight={600}
        className="text-2xl font-bold text-typography-high-contrast"
      >
        {(percent * 100).toFixed(0)}
      </text>
      ‍
    </svg>
  );
};
