import { ApplicationType, MilestoneType } from "@prisma/client";
import React from "react";
import { HandleIcon } from "~app/company/(dashboard)/pipeline/components/icons";
import { Status } from "~/components/v2/status";
import useAdvanceCandidate from "~/hooks/candidate/useAdvanceCandidate";
import { cn } from "~/utils/cn";
import { ActionTemplate } from "../candidate-card/components/actions/action-template";
import { CandidateBoxProps } from "./candidate-box.types";
import { CandidateInfo } from "./components";
import { useRouter } from "next/navigation";

export const CandidateBox: React.FC<CandidateBoxProps> = ({
  candidate,
  jobId,
  nextStageId,
  companyId,
  milestoneType,
  jobTitle,
  jobReqNumber,
  snapshot,
  provided,
}) => {
  const advance = useAdvanceCandidate();
  const router = useRouter();

  const isRejected = candidate.status === "REJECTED";

  const handleAdvance = advance({
    stageId: nextStageId,
    applicationId: candidate.applicationId,
    companyId,
    milestoneType,
    jobSeekerId: candidate.seekerId,
    candidateName: candidate.name || "",
    applicationType: candidate.applicationType as ApplicationType,
    jobTitle,
    jobNumber: jobReqNumber,
    alreadyInvitedToJob: candidate.alreadyInvitedToJob,
    recentlyInvitedToAnotherJob: candidate.recentlyInvitedToAnotherJob,
    actionType: milestoneType === MilestoneType.SOURCING ? "advance" : "set",
  });

  return (
    <div
      role="region"
      aria-label="Candidate Box"
      className={cn(
        "group flex w-[17.5rem] flex-col gap-3 border-b border-borders-borders",
        {
          "border-none": snapshot?.isDragging,
        },
      )}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
    >
      <div
        className={cn(
          "flex flex-row bg-white transition-colors ",
          {
            "rounded-xl bg-background-hover shadow-candidate-box-dragging":
              snapshot?.isDragging,
          },
          {
            "group-hover:bg-background-hover": !isRejected,
          },
        )}
      >
        <div
          className={cn(
            "flex w-0 items-center bg-primary-background transition-all ",
            { "w-5 rounded-l-xl bg-background-pressed": snapshot?.isDragging },
            {
              "group-hover:w-5": !isRejected,
            },
          )}
          {...provided?.dragHandleProps}
        >
          <HandleIcon width={16} height={16} />
        </div>
        <div className="w-full px-3 py-2">
          <CandidateInfo
            candidate={candidate}
            isDragging={!!snapshot?.isDragging}
          />
          <div
            className={cn("mt-3 w-full flex-row", {
              hidden: snapshot?.isDragging,
            })}
          >
            <div
              className={cn(
                "flex h-0 w-full items-center justify-between overflow-hidden transition-all ",
                { "h-auto opacity-0": snapshot?.isDragging },
                {
                  "group-hover:h-auto": !isRejected,
                },
              )}
            >
              <ActionTemplate
                small
                onClick={() =>
                  router.replace(
                    `/company/pipeline/${jobId}/${candidate.applicationId}`,
                  )
                }
              >
                See candidate
              </ActionTemplate>
              <ActionTemplate small onClick={() => handleAdvance()}>
                Advance
              </ActionTemplate>
            </div>
            <div
              className={cn(
                "flex h-6 w-full items-center justify-between overflow-hidden transition-all ",
                { "h-0": snapshot?.isDragging },
                {
                  "group-hover:h-0": !isRejected,
                },
              )}
            >
              <div className="text-sm text-typography-low-contrast">
                {candidate.sourceName}
              </div>{" "}
              <Status
                status={candidate.status}
                lastUpdatedStatusAt={candidate.lastUpdatedStatusAt}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
