import { FlagIcon } from "@heroicons/react/24/outline";
import { DotFilledIcon } from "@radix-ui/react-icons";
import { useRouter } from "next/navigation";
import React from "react";
import * as R from "remeda";
import { Score } from "~/components/score";
import { Divider } from "~/components/v2/Divider";
import { ProfilePicture } from "~/components/v2/ProfilePicture";
import TruncatedText from "~/components/v2/TruncatedText";
import { Status } from "~/components/v2/status";
import { STATUS } from "~/components/v2/status/status.constants";
import { cn } from "~/utils/cn";
import { CandidateCardProps } from "./candidate-card.type";
import { Actions, Experiences, Notes, Skills } from "./components";

export const CandidateCard: React.FC<CandidateCardProps> = ({ ...props }) => {
  const {
    currentCompany: company,
    educationLevel: degree,
    experience,
    name,
    candidateNotes,
    actions,
    photo: profilePicture,
    currentJob: role,
    matchScore: score,
    skillsMatched,
    skills,
    educationInstitution: university,
    collapsed,
    currentLocation,
    headline,
    status,
    lastUpdatedStatusAt,
    email,
    phone,
    sourceName,
    seekerId,
    jobId,
    applicationId,
  } = { ...props };

  const { push } = useRouter();
  return (
    <div className="flex flex-col gap-2 rounded-lg bg-white px-4 py-2">
      {!seekerId ? (
        <div className="flex rounded-lg bg-white p-3">
          <div className="w-1/2">
            <div className="flex flex-row space-x-2">
              <h1
                role="heading"
                aria-label="Candidate Name"
                onClick={() =>
                  push(`/company/pipeline/${jobId}/${applicationId}`)
                }
                className="cursor-pointer text-base font-medium"
              >
                {name}
              </h1>
              <div className="pt-1">
                <FlagIcon className="h-4 w-4" />
              </div>
            </div>
            <p className="text-xs">{email}</p>
            <p className="text-xs">{phone}</p>

            {sourceName && <p className="text-xs">{sourceName}</p>}
          </div>
        </div>
      ) : (
        <>
          <div className="flex w-full items-center gap-2 rounded-t-lg bg-white py-2 transition-all delay-500">
            <ProfilePicture
              fullName={name}
              src={profilePicture}
              className="h-16 !w-16 text-xl"
              size="custom"
            />
            <div className="flex w-full flex-col gap-1 text-dark-color-text-100">
              <div className="flex flex-row items-center">
                <h1
                  role="heading"
                  aria-label="Candidate Name"
                  onClick={() =>
                    push(`/company/pipeline/${jobId}/${applicationId}`)
                  }
                  className="cursor-pointer text-base font-medium"
                >
                  {name}
                </h1>

                {currentLocation && (
                  <>
                    <DotFilledIcon />
                    <div className="text-sm">{currentLocation}</div>
                    <div className="pl-2">
                      <FlagIcon className="h-4 w-4" />
                    </div>{" "}
                  </>
                )}
              </div>
              <div>
                <p className={cn("text-xs", { hidden: !degree })}>
                  <span className="text-xs font-semibold">{degree}</span> from{" "}
                  {university}
                </p>
                <p className={cn("text-xs", { hidden: !role })}>
                  <span className="text-xs font-semibold">{role}</span> at{" "}
                  {company}
                </p>
              </div>
            </div>
            <div
              className={cn("w-44 border-r border-borders", {
                hidden: !skillsMatched,
              })}
            >
              <p className="pb-2 text-center text-xs text-typography-low-contrast-text">
                Skills Matched
              </p>
              <div className="text-center font-semibold text-typography-high-contrast">
                {skillsMatched?.matched} / {skillsMatched?.total}{" "}
              </div>
            </div>

            <div
              className={cn("flex w-fit flex-col items-center", {
                hidden: R.isNullish(score),
              })}
            >
              <p className="whitespace-nowrap pb-2 text-center text-xs text-typography-low-contrast-text">
                Match Percentage
              </p>
              <div className="h-10 w-20">
                <Score radius={41.5} percent={score ?? 0} />
              </div>
            </div>
          </div>
          <div
            className={cn(
              "flex max-h-[999vh] flex-col gap-2 overflow-hidden transition-all duration-500",
              {
                "max-h-0 opacity-0": collapsed,
              },
            )}
          >
            <TruncatedText
              className="pt-0 text-sm"
              text={headline || ""}
              breakLineButton
              buttonClassName="text-xs"
            />
            <Experiences experience={experience} />
            <Skills skills={skills!} />
            <div
              className={cn("flex w-full flex-col gap-2 text-sm", {
                hidden: !candidateNotes?.length,
              })}
            >
              <Divider />
              <Notes candidateNotes={candidateNotes} />
            </div>
          </div>
        </>
      )}
      <div
        className={cn("flex w-full flex-col gap-2", {
          hidden: !actions?.length,
        })}
      >
        <Divider />
        <div className="flex">
          {status && (
            <Status
              status={status}
              lastUpdatedStatusAt={lastUpdatedStatusAt ?? ""}
            />
          )}
          {status !== STATUS.REJECTED.key && (
            <Actions actions={actions} {...props} />
          )}
        </div>
      </div>
    </div>
  );
};
