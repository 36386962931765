import React from "react";
import { StatusProps } from "./status.types";
import { formatTime } from "./status.utils";
import { STATUS } from "./status.constants";
import { statusStyles } from "./status.styles";

export const Status: React.FC<StatusProps> = ({
  status,
  lastUpdatedStatusAt,
  matchedTo,
}: StatusProps) => {
  const statusName = STATUS[status as keyof typeof STATUS]?.name;
  const statusKey = STATUS[status as keyof typeof STATUS]?.key;
  const formattedTime = formatTime(lastUpdatedStatusAt);
  return (
    <div className={statusStyles({ type: statusKey as keyof typeof STATUS })}>
      <span className="line-clamp-1 text-sm font-semibold">{statusName}</span>
      {formattedTime && <span className="text-sm italic">{formattedTime}</span>}
      {matchedTo && (
        <span className="text-sm italic">Matched to {matchedTo}</span>
      )}
    </div>
  );
};
