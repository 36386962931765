import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/v2/Tooltip";
import { cn } from "~/utils/cn";
import { ToolTipButtonProps } from "./tooltip-button.types";

export const ToolTipButton: React.FC<ToolTipButtonProps> = ({
  Icon,
  disabled,
  onClick,
  tooltip,
  className,
}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <button
            className={cn("px-3 py-1", className)}
            disabled={disabled}
            onClick={onClick}
          >
            <Icon
              fontSize={16}
              className="h-4 w-4 text-typography-low-contrast-text"
            />
          </button>
        </TooltipTrigger>
        <TooltipContent>
          <p className="z-50">{tooltip}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
