import React from "react";
import { validateFile } from "~/utils";
import * as R from 'ramda'
import { errorHandler } from "~/utils/error";
import { UploadFilesProps, UploadedFile } from "../file-input.types";

export const useUploadFiles = ({ onFileChange, onFileRemoval, allowedExtension = 'pdf' }: UploadFilesProps) => {
  const [isUploading, setIsUploading] = React.useState(false);
  const [files, setFiles] = React.useState<UploadedFile[]>([]);
  const [error, setError] = React.useState("");

  const handleFileChange = async (newFiles: FileList) => {
    setError("");
    setIsUploading(true);
    const isValidFile = Array.from(newFiles)?.filter((file) => validateFile(file, allowedExtension))?.length;
    if (isValidFile) {
      try {
        const formattedFiles = await onFileChange?.(newFiles);
        const currentFiles = R.concat(Array.from(files), formattedFiles);
        setIsUploading(false);
        return setFiles(currentFiles);
      } catch (e) {
        errorHandler('', e);
        setError("Please try again, something wrong happened!",);
      };
    }
    setError("Invalid file format.",);
    setIsUploading(false);
  };

  const handleRemove = (index: number) => {
    setError("");
    const filteredFiles = files.filter((_, i) => i !== index);
    setFiles(filteredFiles);
    onFileRemoval?.()
  }

  return { isUploading, handleFileChange, handleRemove, error, files };
};

