import React, { createContext } from 'react';
import { useGetApplication } from '~/hooks/applications/use-get-application';
import { useGetStagesByApplication } from '~/hooks/applications/use-get-stages-by-application';
import { APPLICATION_TYPE, ROUND_TYPE } from '../../advance-candidate-drawer.constants';
import { AdvanceCandidateDrawerContextProps, AdvanceCandidateDrawerContextState } from './advance-candidate-drawer-context.types';
import useGetCompanyDefaultMessages from '~/hooks/defaultMessages/useGetCompanyDefaultMessages';

export const AdvanceCandidateDrawerContext = createContext<AdvanceCandidateDrawerContextState>({} as AdvanceCandidateDrawerContextState);

export const AdvanceCandidateDrawerProvider: React.FC<AdvanceCandidateDrawerContextProps> = ({ children, applicationId, stageId, companyId, defaultValues }) => {
    const [stageVisibility, setStageVisibility] = React.useState<string>(defaultValues?.stageVisibility || ROUND_TYPE.INTERNAL);
    const [isStaticProfile, setIsStaticProfile] = React.useState<boolean>(defaultValues?.isStaticProfile || false);
    const [showInterviewerSelection, setShowInterviewerSelection] = React.useState<boolean>(defaultValues?.showInterviewerSelection || false);
    const [isNotifyCandidate, setIsNotifyCandidate] = React.useState<boolean>(defaultValues?.isNotifyCandidate || false);
    const [isPublicStage, setIsPublicStage] = React.useState<boolean>(defaultValues?.isPublicStage || false);

    const { stages, isLoadingStages } = useGetStagesByApplication(applicationId!);
    const { data: defaultMessages, } = useGetCompanyDefaultMessages(companyId!);
    const { application, isLoadingApplication } = useGetApplication(applicationId!)

    React.useEffect(() => {
        if (application) {
            const isStaticProfile = application?.applicationType === APPLICATION_TYPE.STATIC
            setIsStaticProfile(isStaticProfile);
            if (isStaticProfile) return;
            setIsNotifyCandidate(true);
        }
    }, [application])

    React.useEffect(() => {
        if (stageVisibility) {
            setIsPublicStage(stageVisibility === ROUND_TYPE.PUBLIC)
        }
    }, [stageVisibility])

    const contextValue: AdvanceCandidateDrawerContextState = React.useMemo(() => ({

        stageVisibility,
        setStageVisibility,
        isStaticProfile,
        setIsStaticProfile,
        showInterviewerSelection,
        setShowInterviewerSelection,
        isNotifyCandidate,
        setIsNotifyCandidate,
        isPublicStage,
        setIsPublicStage,
        stages,
        applicationId,
        stageId,
        isLoadingStages,
        defaultMessages,
        isLoadingApplication,
        ...defaultValues,
    }), [defaultValues, applicationId, stageId,
        isPublicStage, isNotifyCandidate, isStaticProfile,
        stageVisibility, showInterviewerSelection, isLoadingStages, 
        stages, defaultMessages, isLoadingApplication]);

    return (
        <AdvanceCandidateDrawerContext.Provider value={contextValue}>
            {children}
        </AdvanceCandidateDrawerContext.Provider>
    );
};

