import { PostUploadCandidateSchema } from "~/types/jobseeker/upload-candidate";
import { FormValues } from "./components/upload-candiate-drawer-form";

export const parseFormData = (
  data: FormValues,
  jobId?: number,
  companyId?: number,
) => {
  const body = {
    data: {
      ...data,
      empUserCreditsId: parseInt(data.empUserCreditsId ?? ""),
      companyId,
      jobId,
    } as PostUploadCandidateSchema,
  };
  return body;
};
