import { QueryFunctionContext } from "@tanstack/react-query";
import qs from "qs";
import axios from "~/services/axios.service";

export interface FetchCandidateFilterOptionsReturn {
  [key: string]: string[];
}

interface FetchCandidateFilterOptionsParams {
  filterSelects: string[];
  jobId: number;
}

export const fetchCandidateFilterOptionsView = (
  { signal }: QueryFunctionContext,
  { jobId, filterSelects }: FetchCandidateFilterOptionsParams,
): Promise<FetchCandidateFilterOptionsReturn> =>
  axios
    .get<{ data: { result: FetchCandidateFilterOptionsReturn } }>(
      `/api/candidates/filters`,
      {
        signal,
        params: { jobId, filterSelects },
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      },
    )
    .then(({ data }) => data as unknown as FetchCandidateFilterOptionsReturn);
