import React from "react";
import { ListStatusPlaceholderTemplate } from "../../template";
import { cn } from "~/utils/cn";
import { ErrorStateProps } from "./error-state.types";

export const ErrorState: React.FC<ErrorStateProps> = ({
  message,
  title,
  className,
  actionButton,
}) => {
  return (
    <ListStatusPlaceholderTemplate
      className={className}
      Icon={({ className }) => (
        <i
          className={cn(
            "fa-regular fa-triangle-exclamation text-primary-text",
            className,
          )}
        />
      )}
      title={title ?? "Something went wrong!"}
      message={message ?? "Please reload the page."}
      actionButton={actionButton}
    />
  );
};
