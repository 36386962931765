import { useQuery } from "@tanstack/react-query";
import { Application } from "~/scalis-components/pipeline/detailed-candidate-view";
import { getApplication } from "~/queries/applications/getAplication";

export const useGetApplication = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["get-application", id],
    queryFn: () => getApplication({ id }),
    enabled: !!id,
  });
  return {
    application: data?.data as Application,
    isLoadingApplication: isLoading,
  };
};
