import axios from "~/services/axios.service";

export const advanceUploadedCandidate = ({
  applicationId,
  stageId,
}: {
  applicationId: number;
  stageId: number;
}) =>
  axios
    .put(`/api/candidates/${applicationId}/uploaded/move`, { stageId })
    .then(response => response.data);
