import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { SelectField } from "~/components/v2/SelectField";
import { useGetApplicationsByApplicants } from "~/hooks/applications/useGetApplicationsByApplicants";
import { useGetJobOpenings } from "~/hooks/jobOpening/useGetJobOpenings";
import { FormButtons } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/form-buttons";
import {
  DetailsEntry,
  DetailsEntryTypeEnum,
  MedalistDesignationEntrySchema as schema,
} from "~/services/jobSeekerApplications.types";

interface MedalistFormProps {
  onSubmit: (data: DetailsEntry) => Promise<void>;
  loading?: boolean;
  onCancel: () => void;
  seekerId?: number | null;
  companyId?: number | null;
}

export const MedalistForm: React.FC<MedalistFormProps> = ({
  onSubmit,
  loading,
  onCancel,
  seekerId,
  companyId,
}) => {
  const { data: jobs } = useGetApplicationsByApplicants("medalist", seekerId);
  const { data: allJobs } = useGetJobOpenings(
    { filters: { companyId: companyId ?? undefined } },
    undefined,
    !seekerId,
  );

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: { type: DetailsEntryTypeEnum.MedalistDesignation },
  });

  const submit = (data: z.infer<typeof schema>) => {
    onSubmit(data).then(() => {
      reset();
    });
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex flex-col gap-2 text-dark-color-text-100"
    >
      <div className="flex flex-1 items-start gap-4">
        <SelectField
          setValue={setValue}
          options={[
            { label: "Silver", value: "Silver" },
            { label: "Bronze", value: "Bronze" },
          ]}
          emptyTerm="Medal"
          searchPlaceholder="Search..."
          placeholder="Medal"
          name="medal"
          value={watch("medal")}
          label="Medal"
          containerClassName="flex-1"
          className="w-full"
          error={errors.medal?.message as string}
          required
        />
        <div className="h-px w-[4.5rem]" />
      </div>
      <div className="flex flex-1 items-start gap-4">
        <SelectField
          setValue={setValue}
          options={
            (jobs || allJobs)?.docs?.map?.(job => ({
              label: job.job_title || "",
              value: job.job_title || "",
            })) || []
          }
          emptyTerm="Job"
          searchPlaceholder="Search..."
          placeholder="Job"
          name="job"
          value={watch("job") || ""}
          label="Job"
          containerClassName="flex-1"
          className="w-full"
          error={errors.job?.message as string}
          required
        />
        <FormButtons
          className="mt-[27px]"
          loading={loading}
          onCancel={onCancel}
        />
      </div>
    </form>
  );
};
