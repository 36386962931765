import { tv } from "tailwind-variants";

export const pageButton = tv({
  base: "rounded-lg px-3 py-1 text-typography-high-contrast",
  variants: {
    disabled: {
      true: "bg-primary-background-pressed",
    },
  },
});
