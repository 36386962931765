import React from "react";
import { Dialog, DialogContent } from "~/components/v2/AltDialog";
import { Button } from "~/components/v2/Button";

interface Props {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirm: () => void;
  sharing: boolean;
}

export const ShareDialog: React.FC<Props> = ({
  open,
  onConfirm,
  onOpenChange,
  sharing,
}) => {
  return (
    <Dialog onOpenChange={onOpenChange} open={open}>
      <DialogContent className="max-w-96">
        <span className="text-center font-semibold text-typography-high-contrast">
          Are you sure you want to {sharing ? "share" : "unshare"} this document
          with the candidate?
        </span>
        <div className="mt-6 flex justify-between">
          <Button
            className="w-36"
            variant="outline"
            onClick={() => onOpenChange(false)}
          >
            Cancel
          </Button>
          <Button className="w-36" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
