import { useQuery } from "@tanstack/react-query";
import {
  QueryParams,
  fetchCandidateListView,
} from "~/queries/fetch-candidate-list-view";

export const useGetMilestones = (jobId: number, queryParams?: QueryParams) => {
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ["milestones", jobId, queryParams],
    queryFn: params => fetchCandidateListView(params, { jobId, queryParams }),
  });

  return {
    milestones: data,
    isLoadingMilestones: isLoading,
    refetch,
    milestonesError: error,
  };
};
