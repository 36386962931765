import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/v2/Form";
import { ScheduleInterviewContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/schedule-interview-context";
import { GuestSelect } from "~/src/app/company/(dashboard)/calendar/components/components/guest-select";
import { CandidateOptionalType } from "~/src/app/company/(dashboard)/calendar/components/components/guest-select/guest-select-context.types";
import {
  FIELD_NAME_CANDIDATES,
  FORM_FIELDS,
} from "../../scheduler-link-form.constants";
import { CandidatesFieldProps } from "./candidates-field.types";

export const CandidatesField = ({ candidate }: CandidatesFieldProps) => {
  const { candidates } = useContext(ScheduleInterviewContext);
  const { control, setValue } = useFormContext();

  const { label, emptyMessage, placeholder } =
    FORM_FIELDS[FIELD_NAME_CANDIDATES];

  useEffect(() => {
    if (candidate.id) {
      setValue(FIELD_NAME_CANDIDATES, [candidate]);
    }
  }, [candidate]);

  return (
    <FormField
      control={control}
      name={FIELD_NAME_CANDIDATES}
      render={({ field }) => (
        <FormItem
          className="grid items-center w-full"
          data-testid="candidates-input"
        >
          <FormLabel className="text-sm font-semibold opaticy-50 text-typography-high-contrast">
            {label}
          </FormLabel>
          <FormControl>
            <GuestSelect
              name={FIELD_NAME_CANDIDATES}
              emptyMessage={emptyMessage}
              placeholder={placeholder}
              options={candidates ?? []}
              value={field.value}
              onChange={(selectedGuests: CandidateOptionalType[]) =>
                field.onChange(selectedGuests)
              }
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
