export const NOTIFICATION_RADIO_OPTIONS = [
  {
    value: "0",
    label: "Send to Sourcing Stage (candidate will not be notified)",
  },
  {
    value: "1",
    label: "Invite to Apply (candidate will be notified of request)",
  },
];

export const UPLOADED_CANDIDATE_NOTE =
  "This candidate will not be notified unless you advance them from the sourcing round to the screening round of the job and request that they fill out a SCALIS profile.";
