import React from "react";
import { useRecruiterTeam } from "~/hooks/pipelines/useRecruiterTeam";
import { InterviewerSelection } from "../../interviewer-selection";
import { FIELD_NAME_INTERVIEWER_SELECTION } from "../../interviewer-selection.constants";
import { InterviewerSelectionContainerProps } from "./interviewer-selection-container.types";

export const InterviewerSelectionContainer: React.FC<
  InterviewerSelectionContainerProps
> = ({
  name = FIELD_NAME_INTERVIEWER_SELECTION,
  error,
  onValueChange,
  label,
  companyTeam,
  values = new Map<number, string>(),
}) => {
    const {
      interviewer,
      addFieldInformation,
      handleAddInterviewer,
      handleRemoveInterviewer,
      companyTeam: getCompanyTeam,
    } = useRecruiterTeam(companyTeam);

    const handleChangeValue = (name: string, value: string, index: number) => {
      values?.set(index, value);
      onValueChange?.(FIELD_NAME_INTERVIEWER_SELECTION, values, index);
      addFieldInformation(name, index, value);
    };

    const onRemoveInterviewer = (name: string, value: string, index: number) => {
      values.delete(index);
      onValueChange?.(FIELD_NAME_INTERVIEWER_SELECTION, values, index);
      handleRemoveInterviewer?.(value);
    }

    return (
      <InterviewerSelection
        companyTeam={companyTeam || getCompanyTeam}
        interviewers={interviewer}
        name={name}
        label={label}
        error={error}
        onValueChange={handleChangeValue}
        onAddInterviewer={handleAddInterviewer}
        onRemoveInterviewer={onRemoveInterviewer}
      />
    );
  };
