import React from "react";
import { CandidateSectionProps } from "./candidates-section.types";
import { SectionContainer } from "../../../section-container";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/v2/Form";
import { GuestSelect } from "~app/company/(dashboard)/calendar/components/components/guest-select";
import { CandidateOptionalType } from "~app/company/(dashboard)/calendar/components/components/guest-select/guest-select-context.types";
import { useFormContext } from "react-hook-form";
import {
  FIELD_CANDIDATES,
  FORM_FIELDS,
} from "../../step-select-candidates.constants";

export const CandidatesSection: React.FC<CandidateSectionProps> = ({
  candidates,
}) => {
  const { control } = useFormContext();

  const { label, emptyMessage, placeholder } = FORM_FIELDS[FIELD_CANDIDATES];

  return (
    <SectionContainer title="Candidates">
      <FormField
        control={control}
        name={FIELD_CANDIDATES}
        render={({ field }) => (
          <FormItem
            className="mt-4 grid w-full items-center"
            data-testid="candidates-input"
          >
            <FormLabel className="opaticy-50 text-sm font-semibold text-typography-high-contrast">
              {label}
            </FormLabel>
            <FormControl>
              <GuestSelect
                name={FIELD_CANDIDATES}
                emptyMessage={emptyMessage}
                placeholder={placeholder}
                options={candidates ?? []}
                value={field.value}
                onChange={(selectedGuests: CandidateOptionalType[]) =>
                  field.onChange(selectedGuests)
                }
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </SectionContainer>
  );
};
