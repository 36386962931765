import { Job } from "@prisma/client";

import { stateNameToCode } from "~/utils/statesMap";

interface ClosedPanelProps {
  job: Job;
}

export const ClosedPanel: React.FC<ClosedPanelProps> = ({ job }) => {
  const renderSeparatorDot = () => (
    <div className="h-1 w-1 rounded-full bg-typography-high-contrast" />
  );

  return (
    <div
      data-testid="closed-panel"
      className="flex rotate-180 flex-nowrap items-center gap-2 text-nowrap px-2 py-10 text-sm text-typography-high-contrast [writing-mode:_vertical-rl]"
    >
      <span>{job.requisition_num.toString().padStart(5, "0")}</span>
      {renderSeparatorDot()}
      <span className="font-semibold">{job.job_title}</span>
      {renderSeparatorDot()}
      <span className="italic">
        {job.city}, {stateNameToCode[job.state ?? ""] ?? job.state}
      </span>
    </div>
  );
};
