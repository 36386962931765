export const FIELD_NAME_SEARCH = "search";
export const FIELD_NAME_MATCH_SCORE = "matchScore";
export const FIELD_NAME_EMAIL = "emails";
export const FIELD_NAME_SCREENED_BY = "screenedBy";
export const FIELD_NAME_SOURCE = "source";
export const FIELD_NAME_PHONE = "phone";
export const FIELD_NAME_DEMOGRAPHICS = "demographics";
export const FIELD_NAME_STATUS = "status";
export const FIELD_NAME_CANDIDATE_TYPE = "candidateType";
export const FIELD_NAME_MEDALIST_DESIGNATION = "medalistDesignation";
export const FIELD_NAME_SCREENING_QUESTIONS = "screeningQuestions";
export const FIELD_NAME_DEGREE = "degrees";
export const FIELD_NAME_CANDIDATE_TIME_ZONE = "timezones";
export const FIELD_NAME_SKILLS = "skills";
export const FIELD_NAME_COMPANY = "companies";
export const FIELD_NAME_MAJOR = "major";
export const FIELD_NAME_JOB_TITLE = "jobTitles";
export const FIELD_NAME_VETERAN_STATUS = "veteranStatus";
export const FIELD_NAME_WILLING_TO_RELOCATE = "willingToRelocate";
export const FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY = "nonTraditional";
export const FIELD_NAME_SCHOOL = "school";
export const FIELD_NAME_CANDIDATE_NAME = "candidateNames";
export const FIELD_NAME_YEARS_OF_EXPERIENCE = "yearsOfExperience";
export const FIELD_NAME_LAST_ACTIVITY_DATE = "lastActivityDate";

export const FORM_FIELDS = {
  [FIELD_NAME_SEARCH]: {
    label: "Search",
  },
  [FIELD_NAME_MATCH_SCORE]: {
    label: "Match Score",
  },
  [FIELD_NAME_EMAIL]: {
    label: "Email",
  },
  [FIELD_NAME_SCREENED_BY]: {
    label: "Screened By",
  },
  [FIELD_NAME_SOURCE]: {
    label: "Source",
  },
  [FIELD_NAME_PHONE]: {
    label: "Phone",
  },
  [FIELD_NAME_DEMOGRAPHICS]: {
    label: "Demographics",
    options: [
      { value: "white", label: "White" },
      {
        value: "black_or_african_amercian",
        label: "Black or African American",
      },
      {
        value: "native_hawaiian_or_pacific_islander",
        label: "Native Hawaiian or Pacific Islander",
      },
      {
        value: "native_american_or_alaska_native",
        label: "Native American or Alaska Native",
      },
      { value: "middle_eastern", label: "Middle Eastern" },
      { value: "asian", label: "Asian" },
      { value: "hispani_or_latino", label: "Hispanic or Latino" },
    ],
  },
  [FIELD_NAME_STATUS]: {
    label: "Status",
    options: [
      { value: "PROSPECT", label: "Prospect" },
      { value: "INVITED_TO_APPLY", label: "Invited to Apply" },
      { value: "INVITE_DECLINED", label: "Invite Declined" },
      { value: "INTERVIEW_NOT_SCHEDULED", label: "Interview Not Scheduled" },
      { value: "INTERVIEW_REQUESTED", label: "Interview Requested" },
      { value: "INTERVIEW_SCHEDULED", label: "Interview Scheduled" },
      { value: "INTERVIEW_COMPLETED", label: "Interview Completed" },
    ],
  },
  [FIELD_NAME_CANDIDATE_TYPE]: {
    label: "Candidate Type",
  },
  [FIELD_NAME_MEDALIST_DESIGNATION]: {
    label: "Medalist Designation",
  },
  [FIELD_NAME_SCREENING_QUESTIONS]: {
    label: "Screening Questions",
  },
  [FIELD_NAME_DEGREE]: {
    label: "Degree",
    optionsName: "degrees",
  },
  [FIELD_NAME_CANDIDATE_TIME_ZONE]: {
    label: "Candidate Time Zone",
  },
  [FIELD_NAME_SKILLS]: {
    label: "Skills",
  },
  [FIELD_NAME_COMPANY]: {
    label: "Company",
  },
  [FIELD_NAME_MAJOR]: {
    label: "Major",
  },
  [FIELD_NAME_JOB_TITLE]: {
    label: "Job Title",
  },
  [FIELD_NAME_VETERAN_STATUS]: {
    label: "Veteran Status",
    options: [
      {
        label: "Veterans only",
        value: true,
      },
    ],
  },
  [FIELD_NAME_WILLING_TO_RELOCATE]: {
    label: "Willing to Relocate",
    options: [
      {
        label: "Yes",
        value: true,
      },
    ],
  },
  [FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY]: {
    label: "Non-Traditional Work History",
    options: [
      {
        label: "Yes",
        value: true,
      },
    ],
  },
  [FIELD_NAME_SCHOOL]: {
    label: "School",
  },
  [FIELD_NAME_CANDIDATE_NAME]: {
    label: "Candidate Names",
  },
  [FIELD_NAME_YEARS_OF_EXPERIENCE]: {
    label: "Years of Experience",
    placeholder: "Ex: 5",
  },
  [FIELD_NAME_LAST_ACTIVITY_DATE]: {
    label: "Last activity date",
  },
};
