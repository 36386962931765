"use client";

import {
  horizontalListSortingStrategy,
  SortableContext,
} from "@dnd-kit/sortable";
import { useEffect } from "react";
import { closestCenter, DndContext, DragOverlay } from "@dnd-kit/core";

import { HeaderCell } from "./components/header-cell";
import { useListViewHeader } from "./use-list-view-header";
import { thead } from "./list-view-header.styled";

export const ListViewHeader = () => {
  const {
    table,
    columnOrder,
    handleDragEnd,
    handleDragCancel,
    handleDragStart,
    sensors,
    getActiveColumnName,
    isShowingShadow,
  } = useListViewHeader();

  useEffect(() => {
    table.getAllColumns().forEach(column => {
      const { meta } = column.columnDef;

      if (meta?.isPinned && !column.getIsPinned()) {
        column.pin("left");
      }
    });
  }, []);

  const HeaderOverlay = () => {
    const columnName = getActiveColumnName();

    return (
      <DragOverlay>
        <div className="border-borders-border z-10 h-full w-full cursor-pointer select-none overflow-hidden text-ellipsis rounded-t-xl border border-solid bg-background-pressed p-2 text-left text-sm font-normal text-typography-high-contrast">
          {columnName}
        </div>
      </DragOverlay>
    );
  };

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      sensors={sensors}
    >
      <div
        className={thead({
          shadow: isShowingShadow,
        })}
        role="rowgroup"
      >
        {table.getHeaderGroups().map(headerGroup => (
          <SortableContext
            items={columnOrder}
            strategy={horizontalListSortingStrategy}
            key={headerGroup.id}
          >
            {headerGroup.headers.map(header => (
              <HeaderCell key={header.id} header={header} />
            ))}
          </SortableContext>
        ))}
      </div>
      <HeaderOverlay />
    </DndContext>
  );
};
