export const APPLICATION_TYPE = {
  DYNAMIC: "DYNAMIC",
  STATIC: "STATIC",
} as const;

export const ROUND_TYPE = {
  INTERNAL: "INTERNAL",
  PUBLIC: "PUBLIC",
} as const;

export const LABEL_NOTIFICATION_RADIO = "Candidate notification";

export const NOTIFICATION_RADIO_OPTIONS = [
  {
    value: "0",
    label: "Do not notify candidate",
  },
  {
    value: "1",
    label: "Notify Candidate",
  },
];

export const POPUP_TYPE_ADVANCE_CANDIDATE_DRAWER = "advance_candidate_drawer";
