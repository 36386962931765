import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { SetpSelectCandidatesProps } from "./step-select-candidates.types";
import { CandidatesSection } from "./components/candidates-section";
import { MessageSection } from "./components/message-section";
import { InternalNotesSection } from "./components/internal-notes-section";
import { Button } from "~/components/v2/Button";
import { FormValues, formSchema } from "./step-select-candidates.schema";
import { FORM_ID, formDefaultValues } from "./step-select-candidates.constants";
import { DrawerFooter } from "~/components/v2/Drawer";
import Spinner from "~/components/spinner";
import { useRejectCandidate } from "../../../reject-candidate-context";
import { parseDataToRejectContext } from "./step-select-candidates.utils";

export const StepSelectCandidates: React.FC<SetpSelectCandidatesProps> = ({
  jobId,
  candidates,
  isLoading,
  defaultMessages,
  onSubmit,
  onCancel,
}) => {
  const {
    resetRejectCandidateData,
    updateRejectCandidateData,
    previousSelectCandidates,
  } = useRejectCandidate();

  const formDefaultValuesWithSelectedCandidates = {
    ...formDefaultValues,
    candidates: previousSelectCandidates,
  };

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValuesWithSelectedCandidates as FormValues,
  });

  const onFormSubmit = (data: FormValues) => {
    resetRejectCandidateData();
    const parsedData = parseDataToRejectContext(data, defaultMessages);
    updateRejectCandidateData({ ...parsedData, jobId });
    onSubmit();
  };

  const handleCancel = () => {
    onCancel();
  };

  const selectedCandidates = form.watch("candidates");

  return (
    <FormProvider {...form}>
      <form
        className="h-full overflow-y-auto  p-4"
        onSubmit={form.handleSubmit(onFormSubmit)}
        id={FORM_ID}
      >
        {isLoading ? (
          <div className="flex h-full flex-1 items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            <CandidatesSection candidates={candidates} />
            <MessageSection defaultMessages={defaultMessages} />
            <InternalNotesSection
              showMedalistStatus={selectedCandidates.length < 2}
            />
          </>
        )}
      </form>
      <DrawerFooter className="justify-end">
        <Button variant="outline" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          aria-label="next"
          className="min-w-20"
          isLoading={false}
          type="submit"
          form={FORM_ID}
        >
          Next
        </Button>
      </DrawerFooter>
    </FormProvider>
  );
};
