import { id } from "date-fns/locale";
import { tabs } from "./candidate-tab-view.styles";
import { VIEW_TYPES, View } from "./candidate-tab-view.types";

import {
  RectangleStackIcon,
  ViewColumnsIcon,
} from "@heroicons/react/24/outline";
import { ListBulletIcon } from "@heroicons/react/24/solid";

const CARDS_VIEW_NAME = "Cards";
const KANBAN_VIEW_NAME = "Kanban";
const LIST_VIEW_NAME = "List";

export const getCandidateViewDetails = ({ Component, type }: View) => {
  const { triggerIcon } = tabs();

  switch (type) {
    case VIEW_TYPES.CARDS:
      return {
        id: VIEW_TYPES.CARDS,
        viewName: CARDS_VIEW_NAME,
        Component,
        Icon: () => <RectangleStackIcon className={triggerIcon()} />,
      };
    case VIEW_TYPES.KANBAN:
      return {
        id: VIEW_TYPES.KANBAN,
        viewName: KANBAN_VIEW_NAME,
        Component,
        Icon: () => <ViewColumnsIcon className={triggerIcon()} />,
      };
    case VIEW_TYPES.LIST:
      return {
        id: VIEW_TYPES.LIST,
        viewName: LIST_VIEW_NAME,
        Component,
        Icon: () => <ListBulletIcon className={triggerIcon()} />,
      };
    default:
      throw new Error("Invalid view type");
  }
};
