import { tv } from "tailwind-variants";

export const pagination = tv({
  slots: {
    container: "flex items-center",
    rowsContainer: "text-xs text-typography-low-contrast-text",
    buttonsContainer: "flex items-center",
  },
  variants: {
    position: {
      sticky: {
        container: "w-screen",
        rowsContainer: "sticky left-0",
        buttonsContainer: "sticky left-[calc(50%-150px)]",
      },
      normal: {
        container: "w-full",
        buttonsContainer: "flex-1 justify-center",
      },
    },
  },
  defaultVariants: {
    position: "normal",
  },
});
