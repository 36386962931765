import axios from "~/services/axios.service";
import logger from "~/utils/logger";

export const createFile = async ({
  file,
  uploadToS3,
  ...data
}: {
  applicationId?: number;
  empId?: number;
  file: File;
  uploadToS3: any;
  uploader: "jobseeker" | "employer";
}) => {
  try {
    const response = await uploadToS3(file);
    axios
      .post("/api/files", { ...data, fileName: file.name, file: response.url })
      .then(response => response.data)
      .catch(e => e.message);
  } catch (error) {
    logger.error(`createFile: ${error}`);
    return null;
  }
};
