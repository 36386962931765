import { CollapsibleFilterField } from "../collapsible-filter-field";
import { Controller, useFormContext } from "react-hook-form";
import { formDefaultValues } from "~/scalis-components/pipeline/list-view/candidate-filters/candidate-filters.schema";
import {
  FIELD_NAME_MATCH_SCORE,
  FORM_FIELDS,
} from "~/scalis-components/pipeline/list-view/candidate-filters/candidate-flters.contants";
import { MatchScore } from "~/scalis-components/pipeline/list-view/candidate-filters/components/filter-type";

export const MatchScoreField = () => {
  const { control, resetField } = useFormContext();

  const name = FIELD_NAME_MATCH_SCORE;

  const [minValue, maxValue] = formDefaultValues[name];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <CollapsibleFilterField
          label={FORM_FIELDS[name].label}
          onResetField={() => resetField(name)}
          showReset={value[0] !== minValue || value[1] !== maxValue}
          defaultOpen={value[0] !== minValue || value[1] !== maxValue}
        >
          <MatchScore onChange={onChange} value={value} />
        </CollapsibleFilterField>
      )}
    />
  );
};
