import React from "react";

import { ProfilePicture } from "~/components/v2/ProfilePicture";
import SortDialog from "~/components/v2/SortDialog";

interface Props {
  activity: any[];
}

const sortOptions = [
  {
    label: "Newest",
    value: "newest",
  },
  {
    label: "Oldest",
    value: "oldest",
  },
  {
    label: "Alphabetical",
    value: "alphabetical",
  },
];

export const ScreenedByTab: React.FC<Props> = ({ activity }) => {
  const [sort, setSort] = React.useState("newest");

  const screenedByActivity = activity.filter(act => act.action === "VIEWED");

  function formatCreatedAt(createdAt: Date): string {
    const formatter = new Intl.DateTimeFormat("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    return formatter.format(createdAt);
  }

  const orderedActivity = screenedByActivity
    .map(item => {
      return {
        ...item,
        _createdAt: formatCreatedAt(new Date(item.createdAt)),
      };
    })
    .sort((a: any, b: any) => {
      switch (sort) {
        case "newest":
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
        case "oldest":
          return (
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          );
        case "alphabetical":
          return a.hr?.name?.localeCompare(b.hr?.name);
        default:
          return (
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
          );
      }
    });

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center justify-between border-b border-borders px-4 py-3">
        <span className="font-semibold text-typography-color">Screened By</span>
        <div className="flex gap-3">
          <SortDialog onChange={setSort} options={sortOptions} value={sort} />
        </div>
      </div>

      <div className="scroll-container">
        <div className="menu-candidate-view-details-scroll">
          <div className="h-[200px] w-full px-4">
            {orderedActivity.map(item => (
              <div
                className="flex cursor-pointer items-center gap-2 py-2"
                key={item.id}
              >
                <ProfilePicture
                  size="medium"
                  src={item.hrProfileImage}
                  fullName={item.hrFullName}
                />
                <div className="flex flex-col text-start text-sm">
                  <p className="font-semibold text-typography-high-contrast">
                    {item.hrFullName}
                  </p>
                  <p className="italic text-typography-low-contrast-text">
                    {item.logDate} - {item.logTime}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
