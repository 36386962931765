import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";

export interface JobSummary {
  id: number;
  title: string;
  company_name: string;
  accepting_candidates: boolean;
  createdAt: string;
}

export interface JobSummaryQueryParams {
  onlyJobsWithPipeline?: boolean;
}

export const fetchJobsSummary = (
  { signal }: QueryFunctionContext,
  queryParams?: JobSummaryQueryParams,
) =>
  axios
    .get<JobSummary[]>("/api/jobs/summary-list", {
      signal,
      params: queryParams,
    })
    .then(({ data }) => data);
