"use client";

import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";

import Spinner from "~/components/spinner";
import { Button } from "~/components/v2/Button";
import { useDeleteDetailsEntry } from "~/hooks/applications/useDeleteDetailsEntry";
import { useToast } from "~/hooks/useToast";

interface Props {
  value: string;
  index: number;
  applicationId: number;
}

export const SectionItem: React.FC<Props> = ({
  value,
  index,
  applicationId,
}) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const { mutate: deleteItem, isPending: isLoading } = useDeleteDetailsEntry({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["candidate-application", applicationId],
      });
    },
    onError: error =>
      toast({
        description: (error as any)?.message ?? "Unexpected error ocurred",
        title: "Error",
        variant: "destructive",
      }),
  });

  const handleDelete = () => deleteItem({ id: applicationId, index });

  return (
    <span className="flex justify-between text-typography-high-contrast">
      {value}
      <Button
        variant="ghost"
        size="icon"
        className="rounded-full"
        onClick={handleDelete}
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner className="relative" />
        ) : (
          <MinusCircleIcon className="h-5 w-5" />
        )}
      </Button>
    </span>
  );
};
