import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React from "react";

import Spinner from "~/components/spinner";
import { Button } from "~/components/v2/Button";
import { cn } from "~/utils/cn";

interface Props {
  loading?: boolean;
  onCancel: () => void;
  className?: string;
}

export const FormButtons: React.FC<Props> = ({
  loading,
  onCancel,
  className,
}) => {
  return (
    <div className={cn("mt-[3px]", className)}>
      <Button
        className="rounded-full text-error-90 hover:bg-error-40 active:bg-error-50"
        variant="ghost"
        size="icon"
        type="button"
        onClick={onCancel}
        data-testid="cancel-button"
      >
        <XMarkIcon className="h-5 w-5" />
      </Button>
      <Button
        className="rounded-full text-typography-color"
        variant="ghost"
        size="icon"
        disabled={loading}
      >
        {loading ? (
          <Spinner className="relative" />
        ) : (
          <CheckCircleIcon className="h-5 w-5" />
        )}
      </Button>
    </div>
  );
};
