import { useState } from "react";

import { useGetApplicationsByApplicants } from "~/hooks/applications/useGetApplicationsByApplicants";

import { CustomCombobox } from "../../../../custom-combobox";
import {
  ScreeningQuestion,
  ScreeningQuestionsTabProps,
} from "./screening-questions.types";
import { StagePanel } from "./stage-panel";

export const ScreeningQuestionsTab = ({ data }: ScreeningQuestionsTabProps) => {
  const [process, setProcess] = useState<number[]>([data.jobId]);

  const { data: applications } = useGetApplicationsByApplicants(
    "all",
    data.jobseeker_account.id,
    {
      filters: { companyId: data.job_id.user.companyId },
      limit: 100,
      includeStages: false,
      sortBy: "job_title",
      sortDirection: "desc",
    },
  );

  const options =
    applications?.docs.map(item => ({
      label: item?.job_title || "",
      value: item?.jobId,
    })) || [];

  const filteredApplications = applications?.docs
    .filter(item => process.includes(item.jobId))
    .map(item => ({
      questions: (item.job_id.question as ScreeningQuestion[]).map(
        question => ({
          ...question,
          ansJobSeeker: (item.screeningQuestions as Record<string, string>)[
            question.question
          ],
        }),
      ),
      jobTitle: item.job_title,
    }));

  return (
    <div className="flex h-full flex-col">
      <div className="flex items-center justify-between border-b px-4 py-[10px]">
        <span className="font-semibold text-typography-color">
          Screening Questions
        </span>
        <CustomCombobox
          setValue={setProcess}
          placeholder="Select job process"
          searchPlaceholder="Search..."
          options={options}
          value={process}
          emptyTerm="process"
          name="job process"
          className="w-full xs:w-40 sm:w-48 md:w-64"
        />
      </div>

      <div className="scroll-container">
        <div className="menu-candidate-view-details-scroll">
          {filteredApplications?.map((item, index) => (
            <StagePanel item={item} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};
