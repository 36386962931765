export const FIELD_NAME_CALENDAR = "calendar";
export const FIELD_NAME_CANDIDATES = "candidates";
export const FIELD_NAME_INTERVIEW_TEAM = "interviewers";
export const FIELD_NAME_INTERVIEW_LENGHT = "interviewLength";
export const FIELD_NAME_CUSTOM_MESSAGE = "custom_message";
export const FIELD_NAME_HAS_DEFAULT_MESSAGE = "hasDefaultMessage";
export const FIELD_NAME_MESSAGE = "message";

export const formDefaultValues = {
  [FIELD_NAME_CALENDAR]: null,
  [FIELD_NAME_CANDIDATES]: [],
  [FIELD_NAME_INTERVIEW_TEAM]: [],
  [FIELD_NAME_INTERVIEW_LENGHT]: null,
  [FIELD_NAME_CUSTOM_MESSAGE]: "",
  [FIELD_NAME_HAS_DEFAULT_MESSAGE]: false,
  [FIELD_NAME_MESSAGE]: null,
};

export const FORM_FIELDS = {
  [FIELD_NAME_CALENDAR]: {
    label: "Calendar",
    placeholder: "Choose an option",
    emptyMessage: "No calendars found",
    errorMessage: "Please select a calendar",
  },
  [FIELD_NAME_CANDIDATES]: {
    label: "Candidates",
    placeholder: "Please select candidate(s)",
    emptyMessage: "No candidates found",
    searchPlaceholder: "Search...",
    errorMessage: "Please select a candidate",
  },
  [FIELD_NAME_INTERVIEW_TEAM]: {
    label: "Interview Team",
    placeholder: "Select interview team",
    emptyMessage: "interview team",
    searchPlaceholder: "Search...",
    errorMessage: "Please select an interview team",
  },
  [FIELD_NAME_INTERVIEW_LENGHT]: {
    label: "Interview Length",
    defaultValue: "30",
    placeholder: "Please select an interview length",
    emptyMessage: "No interview length found",
    searchPlaceholder: "Search...",
    errorMessage: "Please select an interview length",
  },
  [FIELD_NAME_CUSTOM_MESSAGE]: {
    label: "Message for the candidate",
    description: "Message",
    placeholder: "Insert here your message to the candidate",
  },
  [FIELD_NAME_HAS_DEFAULT_MESSAGE]: {
    label: "Default Message",
  },
  [FIELD_NAME_MESSAGE]: {
    label: "Message",
    placeholder: "Choose an option",
    emptyMessage: "No messages found",
    searchPlaceholder: "Search...",
    errorMessage: "Please select a message",
  },
};
