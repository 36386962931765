import { useState } from "react";
import { Checkbox } from "~/components/v2/Checkbox";
import { cn } from "~/utils/cn";
import { CustomMessage, SelectDefaultMessage } from "./components";
import {
  FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE,
  LABEL_CHECKBOX_DEFAULT_MESSAGE,
} from "./employer-message.constants";
import { EmployerMessageProps } from "./employer-message.types";

export const EmployerMessage: React.FC<EmployerMessageProps> = ({
  onValueChange,
  customMessage,
  defaultMessage,
  customMessageError,
  defaultMessageError,
  triggerValidation,
  defaultMessages,
  editorContentClassname,
}) => {
  const [showSelectDefaultMessages, setShowSelectDefaultMessages] =
    useState(false);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-1">
        <CustomMessage
          onValueChange={onValueChange}
          value={customMessage}
          isDisabled={showSelectDefaultMessages}
          error={customMessageError}
          triggerValidation={triggerValidation}
          editorContentClassname={editorContentClassname}
        />
        <div className="flex h-5 items-center">
          <Checkbox
            className="rounded"
            aria-label={LABEL_CHECKBOX_DEFAULT_MESSAGE}
            name={FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE}
            onCheckedChange={checked => {
              onValueChange("cb_default_message", checked);
              setShowSelectDefaultMessages(!!checked);
            }}
          />
          <label htmlFor="default_message" className="ms-2 text-sm font-medium">
            {LABEL_CHECKBOX_DEFAULT_MESSAGE}
          </label>
        </div>
      </div>
      <div className={cn(showSelectDefaultMessages ? "" : "hidden")}>
        <SelectDefaultMessage
          defaultMessages={defaultMessages || []}
          onValueChange={onValueChange}
          value={defaultMessage ?? ""}
          error={defaultMessageError}
        />
      </div>
    </div>
  );
};
