import {
  getMilestoneTypeProps,
  getTitle,
} from "~/scalis-components/pipeline/list-view/candidate-list-view/utils";
import { GetMappedMilestoneProps } from "./milestone-detail-list-view.types";

export const getMappedMilestone = ({
  milestone,
  ...props
}: GetMappedMilestoneProps) => {
  return milestone.stages.map(stage => {
    const milestoneType = milestone.milestoneType as string;

    const { color, rowActionsMenuItems, actionsBarItems } =
      getMilestoneTypeProps({ milestoneType, ...props });

    const title = getTitle(milestone.name, stage.name);

    return {
      id: stage.stageId,
      title,
      squareColor: color,
      listView: {
        rows: stage.candidates.map(candidate => ({
          ...candidate,
          id: candidate.applicationId,
        })),
        tableColor: color,
        rowActionsMenuItems,
        actionsBarItems,
      },
    };
  });
};
