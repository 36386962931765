import { Switch } from "~/components/v2/Switch";
import { cn } from "~/utils/cn";

interface ViewModeProps {
  sideBySide: boolean;
  onChangeViewMode: (checked: boolean) => void;
}

export const ViewMode: React.FC<ViewModeProps> = ({
  sideBySide,
  onChangeViewMode,
}) => {
  return (
    <div className="hidden justify-start rounded-xl bg-white p-2 xl:flex">
      <label
        htmlFor="side-by-side"
        className={cn(
          "flex items-center gap-2 text-sm text-typography-low-contrast-text",
          {
            "text-typography-high-contrast": sideBySide,
          },
        )}
      >
        <Switch
          id="side-by-side"
          className="data-[state=unchecked]:bg-background-muted-background"
          checked={sideBySide}
          onCheckedChange={onChangeViewMode}
        />
        Side by side view
      </label>
    </div>
  );
};
