"use client";

import { useEffect, useState } from "react";
import { MilestoneType } from "@prisma/client";
import { differenceInYears } from "date-fns";
import { FaRegEnvelope } from "react-icons/fa";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import { formatPhoneNumber } from "react-phone-number-input";

import { Score } from "~/components/score";
import { Button } from "~/components/v2/Button";
import { ProfilePicture } from "~/components/v2/ProfilePicture";
import { JobApplicationStarRating } from "~/components/v2/job-application-star-rating";
import { AdvanceCandidatePopUps } from "~app/company/(dashboard)/pipeline/components/advance-candidate-context";
import { StagePill } from "~/components/v2/stage-pill";
import useAdvanceCandidate from "~/hooks/candidate/useAdvanceCandidate";
import { useGetAvatar } from "~/hooks/useGetAvatar";
import { Application } from "~/scalis-components/pipeline/detailed-candidate-view";
import { CandidateStat } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-header/components/candidate-stat/candidate-stat";
import { ScreeningQuestion } from "~/types/postJob";
import { distance } from "~/utils/distance";
import { checkAnswer } from "~/utils/screeningQuestions";
import { stateNameToCode } from "~/utils/statesMap";
import { useSearchParams } from "next/navigation";
import { cn } from "~/utils/cn";
import { AvatarType } from "~/queries/fetchAvatar";
import {
  RejectionFlow,
  useRejectCandidate,
} from "~app/company/(dashboard)/pipeline/components/reject-candidates";
import { Status, STATUS } from "~/components/v2/status";

export interface DetailedCandidateHeaderProps {
  data: Application;
  userId: number;
  alreadyInvitedToJob: boolean;
  recentlyInvitedToAnotherJob: boolean;
}

export const DetailedCandidateHeader: React.FC<
  DetailedCandidateHeaderProps
> = ({ data, userId, alreadyInvitedToJob, recentlyInvitedToAnotherJob }) => {
  const { setPreviousSelectCandidates, setOpenDrawer: setOpenRejectDrawer } =
    useRejectCandidate();

  const advance = useAdvanceCandidate();
  const searchParams = useSearchParams();

  useEffect(() => {
    setPreviousSelectCandidates([
      {
        id: data.id,
        email: data.candidate.email,
        name: data.candidate.fullName,
      },
    ]);
  }, [data]);

  const currentProfile = searchParams?.get("currentProfile");

  const nextStageIndex =
    (data.stages?.findIndex?.(stage => stage?.id === data.stage?.id) ?? -1) + 1;

  const handleAdvance = advance({
    jobSeekerId: data.seekerId!,
    applicationId: data.id,
    candidateName: data.candidate.fullName,
    companyId: data.job_id.user.companyId!,
    stageId: data.stages?.[nextStageIndex]?.id || -1,
    jobNumber: data.job_id.requisition_num,
    jobTitle: data.job_id.job_title,
    applicationType: data.applicationType,
    milestoneType:
      data.stage?.milestone?.milestoneType || MilestoneType.SOURCING,
    alreadyInvitedToJob,
    recentlyInvitedToAnotherJob,
  });

  const { data: avatar } = useGetAvatar(
    data.jobseeker_account?.id,
    AvatarType.jobSeeker,
  );

  const {
    fullName,
    currentEducation,
    currentJob,
    email,
    phone,
    hasSCALISProfile,
  } = data.candidate;

  const experience = hasSCALISProfile
    ? data.candidate?.jobExpericences.reduce((acc, curr) => {
        if (!curr.start_month || !curr.start_year) return acc;
        const startDate = new Date(curr.start_year, curr.start_month, 1);
        const endDate =
          !curr.end_month || !curr.end_year
            ? new Date()
            : new Date(curr.end_year, curr.end_month, 1);
        const diffYears = differenceInYears(endDate, startDate);
        return acc + diffYears;
      }, 0)
    : "N/A";

  const jobCoord = [data.job_id.latitude, data.job_id.longitude];

  const seekerCoord = hasSCALISProfile
    ? [
        data.candidate?.job_seeker_basicinfo?.latitude,
        data.candidate?.job_seeker_basicinfo?.longitude,
      ]
    : [undefined, undefined];

  const isValidCoordinate = (coord: any) =>
    coord !== undefined && coord !== null;

  const isDistanceApplicable = [
    jobCoord[0],
    jobCoord[1],
    seekerCoord[0],
    seekerCoord[1],
  ].every(isValidCoordinate);

  const distanceInMiles = isDistanceApplicable
    ? Math.round(
        distance(
          {
            lat: jobCoord[0] as unknown as number,
            lng: jobCoord[1] as unknown as number,
          },
          {
            lat: seekerCoord[0] as unknown as number,
            lng: seekerCoord[1] as unknown as number,
          },
        ),
      )
    : undefined;

  const questions = data.job_id.question as ScreeningQuestion[];
  const answers = data.screeningQuestions as Record<string, string>;

  const correctAnswers = questions.filter(question =>
    checkAnswer(answers[question.question], question),
  ).length;

  return (
    <div className="flex flex-col gap-2 rounded-xl bg-white px-4 py-2 text-typography-high-contrast">
      <div className="flex h-full items-stretch justify-between">
        <div className="flex items-start gap-4">
          <ProfilePicture
            imageClassName="text-3xl"
            src={hasSCALISProfile ? avatar : "uploaded"}
            fullName={fullName}
            size="large"
            icon={
              !hasSCALISProfile ? (
                <i className="fa-solid fa-user text-2xl" />
              ) : undefined
            }
          />

          <div className="flex flex-col gap-1 text-xs text-typography-high-contrast">
            <span className="flex items-center gap-2 text-base font-semibold">
              {fullName}
              {hasSCALISProfile && (
                <JobApplicationStarRating
                  applicationId={data.id}
                  jobId={data.jobId}
                  seekerId={data.candidate.id}
                  empId={userId}
                  className="gap-0.5 text-score-colors-06"
                />
              )}
            </span>
            <span>{currentEducation}</span>
            <span>{currentJob}</span>
            <div className="flex items-center gap-1">
              <FaRegEnvelope className="h-4 w-4" />
              <span>{email}</span>
            </div>
            <span className="flex items-start gap-1">
              <FiPhone className="h-4 w-4" />{" "}
              {formatPhoneNumber(phone) || phone}
            </span>
          </div>
        </div>
        <div className="flex items-stretch gap-3">
          <CandidateStat title="Experience" value={`${experience}yrs`} />
          <CandidateStat
            title="Distance"
            value={`${distanceInMiles ?? "0"}mi`}
          />
          <CandidateStat title="Skills Matched" value={"0"} />
          <CandidateStat
            title="Screening Questions"
            value={`${correctAnswers}/${questions.length}`}
          />
          <div className="h-full w-px bg-borders-borders" />
          <CandidateStat
            title="Match Percentage"
            value={
              <div className="my-auto h-10 w-20">
                <Score radius={41.5} percent={data.score_v1} />
              </div>
            }
          />
        </div>
      </div>

      {!currentProfile && (
        <>
          <hr className={cn("border-t-borders-borders")} />
          <div className={cn("flex justify-between")}>
            <div className="flex flex-col items-start gap-1">
              <div className="flex items-center gap-1 text-sm">
                <span>
                  {data.job_id.requisition_num.toString().padStart(5, "0")}
                </span>
                <span>•</span>
                <span className="font-semibold">{data.job_id.job_title}</span>
                <span>•</span>
                <span className="italic">
                  {data.job_id.city},{" "}
                  {stateNameToCode[data.job_id.state ?? ""] ??
                    data.job_id.state}
                </span>
              </div>
              <div className="flex gap-4">
                <StagePill stage={data.stage} />
                <Status
                  status={data.status}
                  lastUpdatedStatusAt={String(data?.lastUpdatedStatusAt ?? "")}
                />
              </div>
            </div>
            {data.status !== STATUS.REJECTED.key && (
              <div className="flex items-center gap-2">
                <Button
                  variant="tonal"
                  size="sm"
                  className="gap-1 rounded-lg font-semibold"
                  onClick={handleAdvance}
                  aria-label="Advance"
                >
                  Advance <FaCheck className="text-sm" />
                </Button>
                <Button
                  variant="tonal"
                  size="sm"
                  className="gap-1 rounded-lg font-semibold"
                  aria-label="Reject"
                  onClick={() => setOpenRejectDrawer(true)}
                >
                  Reject <FaXmark className="text-sm" />
                </Button>
              </div>
            )}
          </div>
        </>
      )}
      <AdvanceCandidatePopUps />
      <RejectionFlow jobId={data.jobId} companyId={data?.companyId} />
    </div>
  );
};
