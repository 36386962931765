import { z } from "zod";
import {
  EmployeeSelectProps,
  FIELD_NAME_EMPLOYEE_SELECT,
} from "../employee-select";
import {
  FIELD_NAME_EMAIL,
  FIELD_NAME_FIRST_NAME,
  FIELD_NAME_LAST_NAME,
  FIELD_NAME_NOTES,
  FIELD_NAME_PHONE,
  FIELD_NAME_SOURCE,
} from "./upload-candidate-drawer-form.constants";
import {
  CUSTOM_SOURCE_NAME,
  FIELD_NAME_SOURCE_CUSTOM,
  FIELD_NAME_SOURCE_NAME,
  Source,
  SourceSelectProps,
} from "../source-select";

export interface UploadCandidateDrawerFormProps {
  isSubmit?: boolean;
  onSubmit?: (data: FormValues) => void;
  onErrorSubmit?: (error: any) => void;
  defaultValues?: Partial<FormValues>;
}

export interface FormValues extends EmployeeSelectProps, SourceSelectProps {
  f_name: string;
  l_name: string;
  email: string;
  phone_number: string;
  source: Source;
  sourceName?: string;
  customSourceName?: string;
  empUserCreditsId?: string;
  notes: string;
  documents: string[];
}

export const createSchema = (sourcesWithCustomSource: string[]) => {
  return z
    .object({
      [FIELD_NAME_FIRST_NAME]: z.string({
        required_error: "First name is required",
      }),
      [FIELD_NAME_LAST_NAME]: z.string({
        required_error: "Last name is required",
      }),
      [FIELD_NAME_EMAIL]: z
        .string({
          required_error: "Email address is required",
        })
        .email("Please enter a valid email"),
      [FIELD_NAME_PHONE]: z.string().optional(),
      [FIELD_NAME_SOURCE]: z.string({
        required_error: "Source is required",
      }),
      [FIELD_NAME_SOURCE_NAME]: z.string().optional(),
      [FIELD_NAME_SOURCE_CUSTOM]: z.string().optional(),
      [FIELD_NAME_EMPLOYEE_SELECT]: z.string().optional(),
      [FIELD_NAME_NOTES]: z.string().optional(),
    })
    .superRefine(
      (
        { source, sourceName, customSourceName }: any,
        ctx: {
          addIssue: (arg0: {
            code: "custom";
            message: string;
            path: string[];
          }) => any;
        },
      ) => {
        const isSourceNameRequired =
          !sourcesWithCustomSource.includes(source) && !sourceName;
        if (isSourceNameRequired) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Source name is required",
            path: [FIELD_NAME_SOURCE_NAME],
          });
        }
        const isCustomSourceRequired =
          sourcesWithCustomSource.includes(source) ||
          sourceName === CUSTOM_SOURCE_NAME;
        if (isCustomSourceRequired && !customSourceName) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Custom source is required",
            path: [FIELD_NAME_SOURCE_CUSTOM],
          });
        }
      },
    );
};
