"use client";

import { useGlobalListView } from "../context/global-list-view-context";
import { ListViewContext } from "../list-view-context";
import { useContext } from "react";

interface ListViewContainerProps {
  children: React.ReactNode;
}

export const ListViewContainer: React.FC<ListViewContainerProps> = ({
  children,
}) => {
  const { table, id } = useContext(ListViewContext);
  useGlobalListView({
    id,
    selectedItems: table?.getSelectedRowModel().rows.length,
  });

  return (
    <div
      {...{
        style: {
          minWidth: table.getCenterTotalSize(),
        },
      }}
      className="grid-cols-auto grid min-w-full"
    >
      {children}
    </div>
  );
};
