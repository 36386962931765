import { DotFilledIcon } from "@radix-ui/react-icons";

import { Application } from "~/scalis-components/pipeline/detailed-candidate-view";
import { stateNameToCode } from "~/utils/statesMap";
import { StagePanel } from "./stage-panel";

type Props = {
  data: Application;
  userId: number;
};

export const InterviewsTab: React.FC<Props> = ({ data, userId }) => {
  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row items-center justify-between p-4 border-b">
        <div className="flex flex-col">
          <h2 className="font-semibold text-typography-color">Interview(s)</h2>
          <div className="flex items-center text-sm text-typography-high-contrast">
            <span>
              {data.job_id.requisition_num.toString().padStart(5, "0")}
            </span>
            <DotFilledIcon />
            <span className="font-semibold">{data.job_title}</span>
            <DotFilledIcon />
            <span className="italic">
              {data.job_id.city},{" "}
              {stateNameToCode[data.job_id.state ?? ""] ?? data.job_id.state}
            </span>
          </div>
        </div>
      </div>

      <div className="scroll-container !my-0">
        <div className="menu-candidate-view-details-scroll">
          {data.stages
            ?.filter(
              stage =>
                stage !== null &&
                (stage.milestone.milestoneType === "PRE_SCREEN" ||
                  stage.milestone.milestoneType === "CUSTOM"),
            )
            .map(
              stage =>
                stage && (
                  <div key={stage.id} className="px-4 py-2 border-b">
                    <StagePanel
                      key={stage.id}
                      data={data}
                      stage={stage}
                      userId={userId}
                    />
                  </div>
                ),
            )}
        </div>
      </div>
    </div>
  );
};
