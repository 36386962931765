import { CandidateListViewViewerProps } from "./candidate-list-view-viewer.types";
import { CandidateListView } from "~/scalis-components/pipeline/list-view/candidate-list-view";
import { CandidateListViewTitle } from "../candidate-list-view-title";
import { cn } from "~/utils/cn";
import { useRef } from "react";
import { ListStatusPlaceholder } from "~/components/v2/list-status-placeholder";

export const CandidateListViewViewer: React.FC<
  CandidateListViewViewerProps
> = ({
  squareColor,
  title,
  listView,
  className,
  id,
  showActionBar,
  excludeColumns = [],
}) => {
  const { rows } = listView;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="h-full px-4 py-5">
      <CandidateListViewTitle squareColor={squareColor} title={title} />

      <div
        className={cn(
          "list-view-container mt-5 h-[calc(100%-40px)] overflow-hidden bg-white hover:overflow-x-auto hover:overflow-y-auto",
          className,
        )}
        ref={containerRef}
      >
        <div className="pb-4">
          {rows.length > 0 ? (
            <CandidateListView
              id={id}
              {...listView}
              ref={containerRef}
              excludeColumns={excludeColumns}
              showActionBar={showActionBar}
            />
          ) : (
            <div className="rounded-xl border border-solid border-borders-borders p-8">
              <ListStatusPlaceholder name="candidates" isEmpty={true} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
