import Image from 'next/image'
import AddPeopleSVG from './AddPeople.svg'
import AnalyticsAndEngagementSVG from './AnalyticsAndEngagement.svg'
import CreateEventSVG from './CreateEvent.svg'
import CreateTaskSVG from './CreateTask.svg'
import EditJobListingSVG from './EditJobListing.svg'
import EditPipelineSVG from './EditPipeline.svg'
import PostJobSVG from './PostJob.svg'
import RecruitingPipelineSVG from './RecruitingPipeline.svg'
import RejectRequisitionSVG from './RejectRequisition.svg'
import UploadCandidateSVG from './UploadCandidate.svg'
import RejectIconSVG from './RejectIcon.svg'
import ChatIconSVG from './ChatIcon.svg'
import AdvanceIconSVG from './AdvanceIcon.svg'
import HandleSVG from './Handle.svg'


type Dimensions = {
  width?: number
  height?: number
}

export const AddPeople: React.FC<Dimensions> = ({ width = 12, height = 40 }) =>
  <Image src={AddPeopleSVG} alt='' width={width} height={height} />

export const AnalyticsAndEngagement: React.FC<Dimensions> = ({ width = 12, height = 48 }) =>
  <Image src={AnalyticsAndEngagementSVG} alt='' width={width} height={height} />

export const CreateEvent: React.FC<Dimensions> = ({ width = 12, height = 48 }) =>
  <Image src={CreateEventSVG} alt='' width={width} height={height} />

export const CreateTask: React.FC<Dimensions> = ({ width = 12, height = 48 }) =>
  <Image src={CreateTaskSVG} alt='' width={width} height={height} />

export const EditJobListing: React.FC<Dimensions> = ({ width = 12, height = 48 }) =>
  <Image src={EditJobListingSVG} alt='' width={width} height={height} />

export const EditPipeline: React.FC<Dimensions> = ({ width = 12, height = 48 }) =>
  <Image src={EditPipelineSVG} alt='' width={width} height={height} />

export const PostJob: React.FC<Dimensions> = ({ width = 12, height = 48 }) =>
  <Image src={PostJobSVG} alt='' width={width} height={height} />

export const RecruitingPipeline: React.FC<Dimensions> = ({ width = 12, height = 48 }) =>
  <Image src={RecruitingPipelineSVG} alt='' width={width} height={height} />

export const RejectRequisition: React.FC<Dimensions> = ({ width = 12, height = 48 }) =>
  <Image src={RejectRequisitionSVG} alt='' width={width} height={height} />

export const UploadCandidate: React.FC<Dimensions> = ({ width = 12, height = 48 }) =>
  <Image src={UploadCandidateSVG} alt='' width={width} height={height} />

export const RejectIcon: React.FC<Dimensions> = ({ width = 12, height = 12 }) =>
  <Image src={RejectIconSVG} alt='' width={width} height={height} />

export const ChatIcon: React.FC<Dimensions> = ({ width = 12, height = 16 }) =>
  <Image src={ChatIconSVG} alt='' width={width} height={height} />

export const AdvanceIcon: React.FC<Dimensions> = ({ width = 12, height = 12 }) =>
  <Image src={AdvanceIconSVG} alt='' width={width} height={height} />

export const HandleIcon: React.FC<Dimensions> = ({ width = 12, height = 12 }) =>
  <Image src={HandleSVG} alt='' width={width} height={height} />
