import { RejectCandidateData } from "../../../reject-candidate-context";
import type { dataProps } from ".";

export const parseDataToRejectContext = (
  data: dataProps,
): Partial<RejectCandidateData> => {
  const questions = Object.keys(data).map(key => ({
    questionId: Number(key),
    score: data[Number(key)],
  }));

  return { questions };
};
