"use client";

import React, { useState } from "react";
import { FiltersDrawerProps } from "./filters-drawer.types";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/v2/Drawer";
import { Button } from "~/components/v2/Button";
import { FilterForm } from "./components/filter-form";
import { FieldValues, useFormContext, useFormState } from "react-hook-form";
import { setAllFormValues } from "./filters-drawer.utils";
import { FormValues } from "@scalis/components/pipeline/list-view/candidate-filters/candidate-filters.schema";

export const FiltersDrawer: React.FC<FiltersDrawerProps> = ({
  open,
  onOpenChange,
  onApplyFilters,
  filterOptions,
}) => {
  const { reset, getValues, setValue } = useFormContext();
  const { dirtyFields, isSubmitting } = useFormState();
  const [startFormValues, setStartFormValues] = useState({});

  const handleOpenChange = (open: boolean) => {
    onOpenChange(open);

    if (open) {
      setStartFormValues(getValues());
      return;
    }

    setAllFormValues(startFormValues, setValue);
  };

  const activeFiltersNumber = Object.keys(dirtyFields).length;

  const onFormSubmit = (data: FieldValues) => {
    setStartFormValues(data);
    onApplyFilters(
      dirtyFields as {
        [K in keyof FormValues]: boolean;
      },
    );
    onOpenChange(false);
  };

  return (
    <Drawer open={open} onOpenChange={handleOpenChange}>
      <DrawerContent asChild>
        <div data-vaul-no-drag>
          <DrawerHeader
            className="p-2"
            actions={
              <Button
                variant="ghost"
                onClick={() => {
                  reset();
                }}
              >
                Reset All
              </Button>
            }
          >
            <h1 className="text-base font-semibold text-typography-high-contrast">
              Advanced filters{" "}
              {activeFiltersNumber > 0 && `(${activeFiltersNumber})`}
            </h1>
          </DrawerHeader>

          <div className="overflow-y-auto">
            <FilterForm
              onFormSubmit={onFormSubmit}
              filterOptions={filterOptions}
            />
          </div>
          <DrawerFooter className="justify-end">
            <DrawerClose asChild>
              <Button variant="outline">Cancel</Button>
            </DrawerClose>
            <Button
              aria-label="Apply filters"
              className="min-w-20"
              disabled={isSubmitting}
              form="advanced-filters-form"
              type="submit"
            >
              Apply filters
            </Button>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
