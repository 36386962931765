import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { TextAreaField } from "~/components/jobseeker/EditForm";
import PhoneField from "~/components/jobseeker/EditForm/PhoneField";
import { TextField } from "~/components/v2/TextField";
import { useGetSources } from "~/hooks/jobseeker/uploaded-candidate/use-get-sources";
import { useRecruiterTeam } from "~/hooks/pipelines/useRecruiterTeam";
import { EmployeeSelect, FIELD_NAME_EMPLOYEE_SELECT } from "../employee-select";
import {
  FIELD_NAME_SOURCE_CUSTOM,
  FIELD_NAME_SOURCE_NAME,
  FIELD_NAME_SOURCE_SELECT,
  SourceSelect,
  Sources,
} from "../source-select";
import {
  FIELD_NAME_DOCUMENTS,
  FIELD_NAME_EMAIL,
  FIELD_NAME_FIRST_NAME,
  FIELD_NAME_LAST_NAME,
  FIELD_NAME_NOTES,
  FIELD_NAME_PHONE,
  FORM_FIELDS,
  SOURCES_WITH_CUSTOM_SOURCE,
} from "./upload-candidate-drawer-form.constants";
import {
  FormValues,
  UploadCandidateDrawerFormProps,
  createSchema,
} from "./upload-candidate-drawer-form.types";
import { DocumentUpload } from "../document-upload";

export const UploadCandidateDrawerForm: React.FC<
  UploadCandidateDrawerFormProps
> = ({ isSubmit, onErrorSubmit, onSubmit, defaultValues }) => {
  const schema = createSchema(SOURCES_WITH_CUSTOM_SOURCE);
  const { sources: sourceData } = useGetSources();
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control,
    register,
    trigger,
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues,
  });
  const { companyTeam } = useRecruiterTeam();
  const employees = companyTeam?.length
    ? companyTeam
    : defaultValues?.employees;
  const sources = sourceData ?? defaultValues?.sources;
  const formRef = React.useRef<HTMLFormElement | null>(null);
  const [documents, setDocuments] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (isSubmit) {
      formRef?.current?.requestSubmit();
    }
  }, [isSubmit]);

  return (
    <form
      ref={formRef}
      className="flex flex-col overflow-auto"
      onSubmit={handleSubmit(data => {
        onSubmit?.({ ...data, documents });
      }, onErrorSubmit)}
    >
      <div className="flex flex-col gap-6 px-6 py-4">
        <div className="flex w-full flex-col gap-4">
          <span className="text-base font-semibold">Profile Information</span>
          <div>
            <TextField
              onValueChange={(_, value) => {
                setValue(FIELD_NAME_FIRST_NAME, value);
                trigger(FIELD_NAME_FIRST_NAME);
              }}
              required
              name={FIELD_NAME_FIRST_NAME}
              label={FORM_FIELDS[FIELD_NAME_FIRST_NAME].label}
              placeholder={FORM_FIELDS[FIELD_NAME_FIRST_NAME].placeholder}
              error={errors[FIELD_NAME_FIRST_NAME]?.message}
              defaultValue={defaultValues?.[FIELD_NAME_FIRST_NAME]}
            />
          </div>
          <div>
            <TextField
              onValueChange={(_, value) => {
                setValue(FIELD_NAME_LAST_NAME, value);
                trigger(FIELD_NAME_LAST_NAME);
              }}
              required
              name={FIELD_NAME_LAST_NAME}
              label={FORM_FIELDS[FIELD_NAME_LAST_NAME].label}
              placeholder={FORM_FIELDS[FIELD_NAME_LAST_NAME].placeholder}
              error={errors[FIELD_NAME_LAST_NAME]?.message}
              defaultValue={defaultValues?.[FIELD_NAME_LAST_NAME]}
            />
          </div>
          <div>
            <TextField
              onValueChange={(_, value) => {
                setValue(FIELD_NAME_EMAIL, value);
                trigger(FIELD_NAME_EMAIL);
              }}
              required
              name={FIELD_NAME_EMAIL}
              label={FORM_FIELDS[FIELD_NAME_EMAIL].label}
              placeholder={FORM_FIELDS[FIELD_NAME_EMAIL].placeholder}
              error={errors[FIELD_NAME_EMAIL]?.message}
              defaultValue={defaultValues?.[FIELD_NAME_EMAIL]}
            />
          </div>
          <div>
            <PhoneField
              {...register(FIELD_NAME_PHONE)}
              control={control}
              placeholder={FORM_FIELDS[FIELD_NAME_PHONE].placeholder}
              label={FORM_FIELDS[FIELD_NAME_PHONE].label}
              error={errors[FIELD_NAME_PHONE]?.message}
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-4">
          <span className="text-base font-semibold">Source Information</span>
          <div>
            <SourceSelect
              errorSource={errors[FIELD_NAME_SOURCE_SELECT]?.message}
              errorSourceName={errors[FIELD_NAME_SOURCE_NAME]?.message}
              errorSourceCustom={errors[FIELD_NAME_SOURCE_CUSTOM]?.message}
              sources={sources as Sources}
              onValueChange={setValue}
              valueSource={watch(FIELD_NAME_SOURCE_SELECT)!}
              valueSourceName={watch(FIELD_NAME_SOURCE_NAME)!}
              valueSourceCustom={defaultValues?.[FIELD_NAME_SOURCE_CUSTOM]}
              triggerValidation={trigger}
            />
          </div>
          <div>
            <EmployeeSelect
              error={errors[FIELD_NAME_EMPLOYEE_SELECT]?.message}
              employees={employees}
              onValueChange={setValue}
              value={
                watch(FIELD_NAME_EMPLOYEE_SELECT)! ||
                defaultValues?.empUserCreditsId!
              }
            />
          </div>
        </div>
        <div className="flex w-full flex-col gap-4">
          <span className="text-base font-semibold">Notes and Documents</span>
          <div>
            <TextAreaField
              onValueChange={(_, value) => {
                setValue(FIELD_NAME_NOTES, value);
                trigger(FIELD_NAME_NOTES);
              }}
              name={FIELD_NAME_NOTES}
              label={FORM_FIELDS[FIELD_NAME_NOTES].label}
              placeholder={FORM_FIELDS[FIELD_NAME_NOTES].placeholder}
              error={errors[FIELD_NAME_NOTES]?.message}
            />
          </div>
          <div>
            <DocumentUpload
              setDocuments={setDocuments}
              error={errors[FIELD_NAME_DOCUMENTS]?.message}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
