import React, { ReactNode } from "react";
import { QueryParams } from "~/queries/fetch-candidate-list-view";

export const VIEW_TYPES = {
  CARDS: "CARDS",
  KANBAN: "KANBAN",
  LIST: "LIST",
} as const;

export type ViewType = keyof typeof VIEW_TYPES;

export interface View {
  type: ViewType;
  Component: ReactNode;
}

export interface ChildrenContainer {
  children: React.ReactNode;
}

export interface CandidateTabViewProps {
  views: View[];
  tabsContentClassName?: string;
  candidateFilters?: {
    jobId: number;
    defaultQueryParams: QueryParams;
  };
}
