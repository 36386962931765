import React from "react";
import { ActionButtonsProps } from "./action-buttons.types";
import { Button } from "~/components/v2/Button";

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  actionBarItems,
  candidate,
}) => {
  const filteredActionBarItems = actionBarItems.filter(({ render }) => {
    if (!render.shouldAlwaysRender && !render.renderif(candidate)) {
      return false;
    }

    return true;
  });

  return (
    <>
      {filteredActionBarItems.map(({ id, type, label, Icon, onClick }) => {
        return (
          <Button key={id} variant={type} onClick={() => onClick(candidate)}>
            {label} <Icon fontSize={16} className="ml-2 h-4 w-4" />
          </Button>
        );
      })}
    </>
  );
};
