import { zodResolver } from "@hookform/resolvers/zod";
import { MessageType } from "@prisma/client";
import { AxiosError } from "axios";
import React from "react";
import { FieldValues, UseFormSetValue, useForm } from "react-hook-form";
import Spinner from "~/components/spinner";
import { Button } from "~/components/v2/Button";
import { DrawerClose, DrawerFooter } from "~/components/v2/Drawer";
import { useInviteToApply } from "~/hooks/candidate/useInviteToApply";
import useGetCompanyDefaultMessages from "~/hooks/defaultMessages/useGetCompanyDefaultMessages";
import { useToast } from "~/hooks/useToast";

import {
  EmployerMessage,
  FIELD_NAME_CUSTOM_MESSAGE,
  FIELD_NAME_DEFAULT_MESSAGE,
  getDefaultMessage,
} from "~/scalis-components/pipeline/candidate-drawer";

import { errorHandler } from "~/utils/error";
import {
  InviteCandidateDrawerFormProps,
  schema,
} from "./invite-candidate-drawer.types";
import { useAdvanceCandidatePopUps } from "../advance-candidate-context";
import { POPUP_TYPE_ALREADY_INVITED_TO_ANOTHER_JOB_MODAL, POPUP_TYPE_ALREADY_INVITED_TO_SAME_JOB_MODAL } from "../modals";

export const InviteCandidateDrawerForm: React.FC<
  InviteCandidateDrawerFormProps
> = ({
  applicationId,
  onOpenChange,
  companyId,
  hideTitle,
  alreadyInvitedToJob,
  recentlyInvitedToAnotherJob,
  jobNumber,
  jobTitle,
}) => {
  const {
    setJobNumber,
    setJobTitle,
    onOpenChange: changeModal,
  } = useAdvanceCandidatePopUps();
  const { data: defaultMessages } = useGetCompanyDefaultMessages(
    companyId,
    MessageType.INVITE,
  );

  const {
    setValue,
    watch,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: zodResolver(schema) });
  const messageForCandidate = watch(FIELD_NAME_CUSTOM_MESSAGE);
  const defaultMessage = watch(FIELD_NAME_DEFAULT_MESSAGE);
  const onValueChange = (
    name: typeof FIELD_NAME_CUSTOM_MESSAGE | typeof FIELD_NAME_DEFAULT_MESSAGE,
    value: string,
  ) => {
    setValue(name, value);
  };

  const { toast } = useToast();

  const { mutate, isPending: isLoading } = useInviteToApply({
    onSuccess: () => {
      onOpenChange(false);
    },
    onError: e => {
      errorHandler(toast, e as AxiosError<any>);
    },
  });

  const modal = alreadyInvitedToJob
    ? POPUP_TYPE_ALREADY_INVITED_TO_SAME_JOB_MODAL
    : POPUP_TYPE_ALREADY_INVITED_TO_ANOTHER_JOB_MODAL;

  const onSubmit = (data: FieldValues) => {
    if (alreadyInvitedToJob || recentlyInvitedToAnotherJob) {
      setJobNumber(jobNumber);
      setJobTitle(jobTitle);
      changeModal(modal);
      return;
    }
    const defaultMessageId = data?.[FIELD_NAME_DEFAULT_MESSAGE];
    if (defaultMessageId) {
      const inviteMessage =
        getDefaultMessage(defaultMessages || [], defaultMessageId) ?? "";
      mutate({ applicationId, inviteMessage });
      return;
    }
    const inviteMessage = data?.[FIELD_NAME_CUSTOM_MESSAGE];
    mutate({ applicationId, inviteMessage });
  };

  const onErrorSubmit = (error: any) => {
    console.error(error);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit, onErrorSubmit)}
      className="flex h-full flex-col"
    >
      <div className="flex flex-col gap-6 px-6 py-4">
        {!hideTitle && (
          <div className="flex flex-col gap-2">
            <h3 className="font-semibold text-typography-high-contrast">
              Invite to apply
            </h3>
            <span className="text-sm text-typography-low-contrast">
              Advancing this candidate to the Resume Screen round means you will
              be inviting them to formally submit an application for this job.
              The candidate may or may not accept this invitation.
            </span>
          </div>
        )}
        <EmployerMessage
          customMessage={messageForCandidate}
          defaultMessage={defaultMessage}
          triggerValidation={trigger}
          defaultMessages={defaultMessages}
          defaultMessageError={
            errors[FIELD_NAME_DEFAULT_MESSAGE]?.message as string
          }
          customMessageError={
            errors[FIELD_NAME_CUSTOM_MESSAGE]?.message as string
          }
          onValueChange={onValueChange as UseFormSetValue<FieldValues>}
        />
      </div>
      <DrawerFooter className="justify-end">
        <DrawerClose asChild>
          <Button variant="outline" type="button">
            Cancel
          </Button>
        </DrawerClose>
        <Button disabled={isLoading} className="min-w-28" type="submit">
          {isLoading ? <Spinner /> : "Invite to apply"}
        </Button>
      </DrawerFooter>
    </form>
  );
};
