export const mapCandidatesDataToCandidates = (candidates = [] as any[]) => {
  return candidates?.map(
    ({ id, seekerName, jobseeker_account, uploadedCandidate }) => {
      return {
        id,
        name: seekerName,
        email: jobseeker_account?.email || uploadedCandidate?.email,
        type: jobseeker_account?.role || "",
      };
    },
  );
};
