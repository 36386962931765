import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { SelectField } from "~/components/v2/SelectField";
import { useGetBusinessJobUsers } from "~/hooks/businessUsers/useGetBusinessUsers";
import { FormButtons } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/form-buttons";
import {
  DetailsEntry,
  DetailsEntryTypeEnum,
  SourceMap,
  SourceEntrySchema as schema,
} from "~/services/jobSeekerApplications.types";

interface SourceFormProps {
  onSubmit: (data: DetailsEntry) => Promise<void>;
  loading?: boolean;
  onCancel: () => void;
  companyId?: number | null;
}

export const SourceForm: React.FC<SourceFormProps> = ({
  onSubmit,
  loading,
  onCancel,
  companyId,
}) => {
  const { data: users } = useGetBusinessJobUsers({ companyId }, !!companyId);

  const {
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: { type: DetailsEntryTypeEnum.Source },
  });

  const submit = (data: z.infer<typeof schema>) => {
    onSubmit(data).then(() => {
      reset();
    });
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex flex-col gap-2 text-dark-color-text-100"
    >
      <div className="flex flex-1 items-start gap-4">
        <SelectField
          setValue={setValue}
          options={Object.keys(SourceMap).map(sourceCategory => ({
            label: sourceCategory,
            value: sourceCategory,
          }))}
          emptyTerm="Source Category"
          searchPlaceholder="Search..."
          placeholder="Source Category"
          name="sourceCategory"
          value={watch("sourceCategory")}
          label="Source Category"
          containerClassName="flex-1"
          className="w-full"
          error={errors.sourceCategory?.message as string}
          required
        />
        <div className="h-px w-[4.5rem]" />
      </div>
      <div className="flex flex-1 items-start gap-4">
        <SelectField
          setValue={setValue}
          options={[
            ...(
              SourceMap[watch("sourceCategory") as keyof typeof SourceMap] || []
            ).map(source => ({
              label: source,
              value: source,
            })),
            { label: "Other", value: "Other" },
          ]}
          emptyTerm="Source"
          searchPlaceholder="Search..."
          placeholder="Source"
          name="source"
          value={watch("source")}
          label="Source"
          containerClassName="flex-1"
          className="w-full"
          error={errors.source?.message as string}
          required
          disabled={!watch("sourceCategory")}
        />
        <div className="h-px w-[4.5rem]" />
      </div>
      <div className="flex flex-1 items-start gap-4">
        <SelectField
          setValue={setValue}
          options={
            users?.map?.(user => ({
              label: user.full_name ?? "",
              value: user.full_name ?? "",
            })) || []
          }
          emptyTerm="Credit"
          searchPlaceholder="Search..."
          placeholder="Credit"
          name="credit"
          value={watch("credit") || ""}
          label="Credit"
          containerClassName="flex-1"
          className="w-full"
          error={errors.credit?.message as string}
          required
        />
        <FormButtons
          className="mt-[27px]"
          loading={loading}
          onCancel={onCancel}
        />
      </div>
    </form>
  );
};
