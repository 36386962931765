import React, { useContext, useEffect } from "react";
import { CandidateActionBarProps } from "./candidate-action-bar.types";
import { ListViewContext } from "~/components/v2/list-view/list-view-context";
import { cn } from "~/utils/cn";
import { ActionButtons } from "./components/ActionButtons";
import { useRejectCandidate } from "~/src/app/company/(dashboard)/pipeline/components/reject-candidates";

export const CandidateActionBar: React.FC<CandidateActionBarProps> = ({
  actionsBarItems,
  hideAllActionBars,
}) => {
  const { setPreviousSelectCandidates } = useRejectCandidate();

  const { table } = useContext(ListViewContext);
  const { rows } = table.getSelectedRowModel();

  useEffect(() => {
    if (!!setPreviousSelectCandidates) {
      const selectedCandidates = rows.map(({ id, original }) => ({
        id: Number(id),
        email: original.email,
        name: original.name,
      }));

      setPreviousSelectCandidates(selectedCandidates);
    }
  }, [rows, setPreviousSelectCandidates]);

  if (!rows?.length || hideAllActionBars) return null;

  return (
    <div className="fixed left-1/2 z-[100] translate-x-[-50%] animate-action-bar-up">
      <div
        className={cn(
          "border-border flex min-w-[800px] rounded-lg border border-solid bg-white shadow-md",
        )}
      >
        <div className="border-border flex items-center justify-center gap-3 border-r border-solid px-8 py-4">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-primary-background">
            <span className="text-sm font-semibold text-typography-high-contrast">
              {rows.length}
            </span>
          </div>
          <p className="text-base font-normal text-typography-high-contrast">{`Item${rows.length > 1 ? "s" : ""} selected`}</p>
        </div>
        <div className="flex gap-2 px-8 py-4">
          {rows.length === 1 ? (
            <ActionButtons
              actionBarItems={actionsBarItems.single}
              candidate={rows[0].original}
            />
          ) : (
            <ActionButtons
              actionBarItems={actionsBarItems.multiple}
              candidate={rows[0].original}
            />
          )}
        </div>
      </div>
    </div>
  );
};
