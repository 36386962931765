import { useQuery } from "@tanstack/react-query";
import {
  MessagType,
  fetchCompanyDefaultMessages,
} from "~/queries/defaultMessages/fetchCompanyDefaultMessages";

const useGetCompanyDefaultMessages = (id: number, type?: string) => {
  return useQuery({
    queryKey: ["companyDefaultMessages", id, type],
    queryFn: params => fetchCompanyDefaultMessages(params, id, type),
    enabled: !!id,
  });
};

export default useGetCompanyDefaultMessages;
