import { createContext, useEffect, useState } from "react";

import { useGetApplicationsByJobId } from "~/hooks/applications/useGetApplicationsByJobId";
import { useGetCandidateApplication } from "~/hooks/applications/useGetCandidateApplication";
import {
  DetailedCandidateDrawerContextProps,
  DetailedCandidateDrawerProviderProps,
  JobApplicationNav,
} from "./detailed-candidate-drawer-context.types";

export const DetailedCandidateDrawerContext = createContext(
  {} as DetailedCandidateDrawerContextProps,
);

export const DetailedCandidateDrawerProvider = ({
  jobId,
  initialApplicationId,
  onDrawerClose,
  children,
}: DetailedCandidateDrawerProviderProps) => {
  const [applicationId, setApplicationId] = useState(initialApplicationId);
  const [jobApplications, setJobApplications] = useState<JobApplicationNav[]>(
    [],
  );

  const { data: dataJobApplications, isLoading: isJobApplicationsLoading } =
    useGetApplicationsByJobId(jobId);

  const { data: dataCandidate, isLoading: isCandidateLoading } =
    useGetCandidateApplication(Number(applicationId));

  useEffect(() => {
    if (dataJobApplications) {
      const newJobApplications = dataJobApplications.map(
        (application: any, index: number) => {
          return {
            id: application.id,
            index,
          };
        },
      );
      setJobApplications(newJobApplications);
    }
  }, [dataJobApplications]);

  const handleCloseDrawer = () => {
    onDrawerClose(false);
  };

  const previousCandidate = () => {
    jobApplications.forEach((application, index) => {
      if (application.id === applicationId) {
        const previousApplication = jobApplications[index - 1];

        if (previousApplication) {
          setApplicationId(previousApplication.id);
        }
      }
    });
  };

  const nextCandidate = () => {
    jobApplications.forEach((application, index) => {
      if (application.id === applicationId) {
        const nextApplication = jobApplications[index + 1];

        if (nextApplication) {
          setApplicationId(nextApplication.id);
        }
      }
    });
  };

  useEffect(() => {
    if (dataJobApplications) {
    }
  }, [dataJobApplications]);

  const applicationPageUrl = `/company/pipeline/${jobId}/${applicationId}`;

  return (
    <DetailedCandidateDrawerContext.Provider
      value={{
        jobId,
        applicationId,
        isLoading: isCandidateLoading || isJobApplicationsLoading,
        candidateApplication: dataCandidate,
        applicationPageUrl,
        previousCandidate,
        nextCandidate,
        closeDrawer: handleCloseDrawer,
      }}
    >
      {children}
    </DetailedCandidateDrawerContext.Provider>
  );
};
