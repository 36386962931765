import { z } from "zod";
import { FIELD_NAME_INTERVIEWER_SELECTION } from "../advance-candidate-drawer/components/interviewer-selection/interviewer-selection.constants";
import { ScheduleOptionEnum } from "../advance-candidate-drawer/components/schedule-radio/schedule-radio.constants";
import { FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE, FIELD_NAME_CUSTOM_MESSAGE, FIELD_NAME_DEFAULT_MESSAGE } from "~/scalis-components/pipeline/candidate-drawer";

export interface InviteCandidateDrawerFormProps {
  applicationId: number;
  onOpenChange: (state: boolean) => void;
  companyId: number;
  hideTitle?: boolean;
  alreadyInvitedToJob?: boolean;
  recentlyInvitedToAnotherJob?: boolean;
  jobNumber?: number | null;
  jobTitle?: string | null;
}

export interface InviteScalisCandidateDrawerProps {
  open: boolean;
  onOpenChange: (state: boolean) => void;
  companyId: number;
  applicationId: number;
  candidateName: string;
}

export interface InviteUploadedCandidateDrawerProps {
  open: boolean;
  onOpenChange: (state: boolean) => void;
  companyId: number;
  applicationId: number;
  candidateName: string;
  stageId: number;
  alreadyInvitedToJob: boolean;
  jobNumber?: number | null;
  jobTitle?: string | null;
  recentlyInvitedToAnotherJob: boolean;
}

export const schema = z
  .object({
    [FIELD_NAME_CUSTOM_MESSAGE]: z.string().optional(),
    [FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE]: z.boolean().optional(),
    [FIELD_NAME_DEFAULT_MESSAGE]: z.string().optional(),
  })
  .superRefine(
    (
      {
        cb_default_message,
        default_message,
        custom_message,
        interview_schedule,
        interviewer,
      }: any,
      ctx: {
        addIssue: (arg0: {
          code: "custom";
          message: string;
          path: string[];
        }) => any;
      },
    ) => {
      if (!cb_default_message) {
        const parsedMessage = custom_message?.replace(/<[^>]*>/g, "");
        if (!parsedMessage) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Custom message is required",
            path: [FIELD_NAME_CUSTOM_MESSAGE],
          });
        }
      }
      if (cb_default_message && !default_message) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Default message is required",
          path: [FIELD_NAME_DEFAULT_MESSAGE],
        });
      }
      if (
        interview_schedule ===
          ScheduleOptionEnum.SendSchedulerLink.toString() &&
        !interviewer?.size
      ) {
        return ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "At least one interviewer is required",
          path: [FIELD_NAME_INTERVIEWER_SELECTION],
        });
      }
    },
  );
