import { z } from "zod";
import { Question } from "./step-rating-candidates.types";

export const generateZodSchema = (questions: Question[]) => {
  const schemaFields: Record<string, z.ZodTypeAny> = {};

  questions.forEach(question => {
    const { id } = question;

    schemaFields[id] = z
      .number({
        required_error: "Please select a score",
        invalid_type_error: "Please select a score",
      })
      .int();
  });

  return z.object(schemaFields);
};
