"use client";

import { TextField } from "../text-field";
import { SelectField } from "../select-field";
import { CheckboxField } from "../checkbox-field/checkbox-field";
import { MatchScoreField } from "../match-score-field";
import { useFormContext } from "react-hook-form";
import { DateRangeField } from "../date-range-field";
import { candidateFiltersContants as filterConst } from "~/scalis-components/pipeline/list-view/candidate-filters";
import { FilterFormProps } from "./filter-form.types";

export const FilterForm: React.FC<FilterFormProps> = ({
  onFormSubmit,
  filterOptions,
}) => {
  const { register, handleSubmit } = useFormContext();

  const { FORM_FIELDS } = filterConst;

  return (
    <form
      className="flex flex-col"
      id="advanced-filters-form"
      onSubmit={handleSubmit(onFormSubmit)}
    >
      {/* <SearchField /> */}
      <MatchScoreField />
      <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_EMAIL].label}
        name={filterConst.FIELD_NAME_EMAIL}
        options={filterOptions[filterConst.FIELD_NAME_EMAIL]}
      />
      {/* <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_SCREENED_BY].label}
        name={filterConst.FIELD_NAME_SCREENED_BY}
      /> */}
      {/* <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_SOURCE].label}
        name={filterConst.FIELD_NAME_SOURCE}
      /> */}
      {/* <TextField
        label={FORM_FIELDS[filterConst.FIELD_NAME_PHONE].label}
        {...register(filterConst.FIELD_NAME_PHONE)}
      /> */}
      <CheckboxField
        multi
        label={FORM_FIELDS[filterConst.FIELD_NAME_DEMOGRAPHICS].label}
        options={FORM_FIELDS[filterConst.FIELD_NAME_DEMOGRAPHICS].options}
        name={filterConst.FIELD_NAME_DEMOGRAPHICS}
      />
      <CheckboxField
        multi
        label={FORM_FIELDS[filterConst.FIELD_NAME_STATUS].label}
        options={FORM_FIELDS[filterConst.FIELD_NAME_STATUS].options}
        name={filterConst.FIELD_NAME_STATUS}
      />
      <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_CANDIDATE_NAME].label}
        name={filterConst.FIELD_NAME_CANDIDATE_NAME}
        options={filterOptions[filterConst.FIELD_NAME_CANDIDATE_NAME]}
      />
      {/* <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_CANDIDATE_TYPE].label}
        name={filterConst.FIELD_NAME_CANDIDATE_TYPE}
      /> */}
      {/* <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_MEDALIST_DESIGNATION].label}
        name={filterConst.FIELD_NAME_MEDALIST_DESIGNATION}
      /> */}
      {/* <ScreeningQuestionsField questions={10} /> */}
      <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_DEGREE].label}
        name={filterConst.FIELD_NAME_DEGREE}
        options={filterOptions[filterConst.FIELD_NAME_DEGREE]}
      />
      <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_CANDIDATE_TIME_ZONE].label}
        name={filterConst.FIELD_NAME_CANDIDATE_TIME_ZONE}
        options={filterOptions[filterConst.FIELD_NAME_CANDIDATE_TIME_ZONE]}
      />
      <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_SKILLS].label}
        name={filterConst.FIELD_NAME_SKILLS}
        options={filterOptions[filterConst.FIELD_NAME_SKILLS]}
      />
      <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_COMPANY].label}
        name={filterConst.FIELD_NAME_COMPANY}
        options={filterOptions[filterConst.FIELD_NAME_COMPANY]}
      />
      {/* <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_MAJOR].label}
        name={filterConst.FIELD_NAME_MAJOR}
      /> */}
      <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_JOB_TITLE].label}
        name={filterConst.FIELD_NAME_JOB_TITLE}
        options={filterOptions[filterConst.FIELD_NAME_JOB_TITLE]}
      />
      <CheckboxField
        multi={false}
        label={FORM_FIELDS[filterConst.FIELD_NAME_VETERAN_STATUS].label}
        name={filterConst.FIELD_NAME_VETERAN_STATUS}
        options={FORM_FIELDS[filterConst.FIELD_NAME_VETERAN_STATUS].options}
      />
      <CheckboxField
        multi={false}
        label={FORM_FIELDS[filterConst.FIELD_NAME_WILLING_TO_RELOCATE].label}
        name={filterConst.FIELD_NAME_WILLING_TO_RELOCATE}
        options={
          FORM_FIELDS[filterConst.FIELD_NAME_WILLING_TO_RELOCATE].options
        }
      />
      <CheckboxField
        multi={false}
        label={
          FORM_FIELDS[filterConst.FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY].label
        }
        name={filterConst.FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY}
        options={
          FORM_FIELDS[filterConst.FIELD_NAME_NON_TRADITIONAL_WORK_HISTORY]
            .options
        }
      />
      {/* <SelectField
        label={FORM_FIELDS[filterConst.FIELD_NAME_SCHOOL].label}
        name={filterConst.FIELD_NAME_SCHOOL}
      /> */}
      <TextField
        {...register(filterConst.FIELD_NAME_YEARS_OF_EXPERIENCE)}
        label={FORM_FIELDS[filterConst.FIELD_NAME_YEARS_OF_EXPERIENCE].label}
        type="number"
        min={0}
        placeholder={
          FORM_FIELDS[filterConst.FIELD_NAME_YEARS_OF_EXPERIENCE].placeholder
        }
      />
      <DateRangeField
        label={FORM_FIELDS[filterConst.FIELD_NAME_LAST_ACTIVITY_DATE].label}
        name={filterConst.FIELD_NAME_LAST_ACTIVITY_DATE}
        mode="range"
      />
    </form>
  );
};
