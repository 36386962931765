import TruncatedText from "~/components/v2/TruncatedText";
import { ScalisCandidate } from "~/types/pipeline";

export const Note: React.FC<ScalisCandidate["candidateNotes"][number]> = ({
    content,
    fromNow,
}) => (
    <div>
        <span className="text-xs text-typography-low-contrast-text">{fromNow} ago</span>
        <TruncatedText
            className="pt-0"
            buttonClassName="text-xs"
            text={content || ""}
            breakLineButton
            rowNumber={2}
        />
    </div>
);
