import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React from "react";
import Spinner from "~/components/spinner";
import { Button } from "~/components/v2/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/v2/Drawer";
import { useAdvanceProcessingCandidate } from "~/hooks/candidate/useAdvanceProcessingCandidate";
import { useToast } from "~/hooks/useToast";
import { errorHandler } from "~/utils/error";
import { AdvanceCandidateDrawerProps } from "./advance-candidate-drawer.types";
import { parseFormData } from "./advance-candidate-drawer.utils";
import { AdvanceCandidateDrawerContext } from "./components";
import { AdvanceCandidateForm } from "./components/advance-candidate-form/advance-candidate-form";
import { FormValues } from "./components/advance-candidate-form/advance-candidate-form.types";

export const AdvanceCandidateDrawer = ({
  open,
  onOpenChange,
  applicationId,
  candidateName,
  onSubmit,
}: AdvanceCandidateDrawerProps) => {
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { mutate, isPending: isLoading } = useAdvanceProcessingCandidate({
    onSuccess: () => {
      onOpenChange?.(false);
      queryClient.invalidateQueries({
        queryKey: ["milestones", ["candidate-application", applicationId]],
      });
    },
    onError: e => {
      errorHandler(toast, e as AxiosError<any>);
    },
  });

  const { defaultMessages, isLoadingApplication } = React.useContext(
    AdvanceCandidateDrawerContext,
  );

  const handleSubmit = (data: FormValues) => {
    const body = parseFormData(data, applicationId!, defaultMessages);
    setIsSubmit(false);
    mutate(body);
  };

  const onErrorSubmit = (error: any) => {
    console.error(error);
    setIsSubmit(false);
  };

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent asChild>
        <div>
          <DrawerHeader className="text-center">
            <h1 className="text-xl font-semibold text-typography-high-contrast">
              Advance Candidate
            </h1>
            <h2 className="text-sm text-typography-low-contrast">
              {candidateName}
            </h2>
          </DrawerHeader>
          {isLoadingApplication ? (
            <div className="align-center flex h-screen w-full justify-center overflow-hidden">
              <Spinner />
            </div>
          ) : (
            <AdvanceCandidateForm
              onSubmit={(data: FormValues) => {
                if (onSubmit) {
                  onSubmit(data);
                  return;
                }
                handleSubmit(data);
              }}
              isSubmit={isSubmit}
              onErrorSubmit={onErrorSubmit}
            />
          )}
          <DrawerFooter className="justify-end">
            <DrawerClose asChild>
              <Button variant="outline">Cancel</Button>
            </DrawerClose>
            <Button
              aria-label="Confirm"
              className="min-w-20"
              isLoading={isLoading}
              onClick={() => setIsSubmit(true)}
            >
              Confirm
            </Button>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
