import { useContext } from "react";
import { RejectCandiateContext } from "./reject-candidate-context";

export const useRejectCandidate = () => {
  const {
    rejectCandidateData,
    resetRejectCandidateData,
    updateRejectCandidateData,
    previousSelectCandidates,
    setPreviousSelectCandidates,
    openDrawer,
    setOpenDrawer,
  } = useContext(RejectCandiateContext);

  return {
    rejectCandidateData,
    resetRejectCandidateData,
    updateRejectCandidateData,
    previousSelectCandidates,
    setPreviousSelectCandidates,
    openDrawer,
    setOpenDrawer,
  };
};
