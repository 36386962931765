"use client";

import * as PopoverPrimitive from "@radix-ui/react-popover";
import { Popover, PopoverTrigger } from "~/components/v2/Popover";
import { useFormContext, useFormState, useWatch } from "react-hook-form";
import { cn } from "~/utils/cn";
import { Button } from "~/components/v2/Button";
import { useEffect, useState } from "react";
import { Reset } from "~/components/v2/filters-drawer/components/reset";

interface PopUpFilterProps {
  name: string;
  children?: React.ReactNode;
  label: string;
  active: boolean;
  onApplyFilters: (data: any) => void;
}

export const PopUpFilter: React.FC<PopUpFilterProps> = ({
  name,
  children,
  label,
  active,
  onApplyFilters,
}) => {
  const [open, setOpen] = useState(false);
  const { resetField } = useFormContext();
  const { touchedFields, dirtyFields } = useFormState();

  const value = useWatch({ name });

  useEffect(() => {
    if (open && (touchedFields[name] || dirtyFields[name])) {
      onApplyFilters(name);
    }
  }, [value, open]);

  const handleReset = () => {
    resetField(name);
    onApplyFilters(name);
  };

  return (
    <Popover open={open} onOpenChange={() => setOpen(state => !state)}>
      <PopoverTrigger asChild>
        <Button
          variant="ghost"
          className={cn(
            "flex cursor-pointer items-center gap-2 rounded-full px-3 py-1",
            active && "bg-primary-background-hover text-primary",
          )}
        >
          {label} <i className={cn("fa-solid fa-caret-down")} />
          {active && (
            <i
              className="fa-light fa-xmark text-icons-secondary"
              onClick={e => {
                e.stopPropagation();
                handleReset();
              }}
            />
          )}
        </Button>
      </PopoverTrigger>
      <PopoverPrimitive.Content
        className="text-popover-foreground w-[360px] rounded-xl border bg-white px-5 pb-11 pt-2 shadow-md outline-none"
        sideOffset={0}
      >
        <div className="flex items-center justify-between pb-2">
          {label} <Reset onClick={handleReset} />
        </div>
        {children}
      </PopoverPrimitive.Content>
    </Popover>
  );
};
