import React from 'react';
import { DocumentUploadProps } from './document-upload.types';
import { FileInput } from '~/components/v2/file-input';
import { FIELD_NAME_UPLOAD_DOCUMENTS, LABEL_UPLOAD_DOCUMENTS } from './document-upload.constants';


export const DocumentUpload: React.FC<DocumentUploadProps> = ({ setDocuments }) => {

    return (
        <div>
            <FileInput onFileChange={setDocuments} isMultiple name={FIELD_NAME_UPLOAD_DOCUMENTS} label={LABEL_UPLOAD_DOCUMENTS} />
        </div>
    );
};
