import { createElement } from "react";

import { Button } from "~/components/v2/Button";
import { CardButtonProps } from "./card-button.types";

export const CardButton = ({
  icon,
  title,
  description,
  ...rest
}: CardButtonProps) => {
  return (
    <Button
      variant="outline"
      type="button"
      className="flex items-start h-full gap-3 p-3 border-borders hover:border-borders-ring"
      {...rest}
    >
      {icon && (
        <div className="flex !h-10 !w-10 flex-shrink-0 items-center justify-center !rounded-[99999px] bg-primary-text">
          {createElement(icon, { className: "text-base text-white" })}
        </div>
      )}
      <div className="flex flex-col items-start gap-1">
        <span className="text-sm font-semibold text-typography-high-contrast">
          {title}
        </span>
        <p className="text-sm font-normal text-left text-typography-high-contrast">
          {description}
        </p>
      </div>
    </Button>
  );
};
