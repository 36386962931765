export const FIELD_NAME_SOURCE_SELECT = "source";
export const LABEL_SOURCE_SELECT = "Source categories";
export const FIELD_NAME_SOURCE_NAME = "sourceName";
export const LABEL_SOURCE_NAME_SELECT = "Source name";
export const FIELD_NAME_SOURCE_CUSTOM = "customSourceName";
export const LABEL_SOURCE_CUSTOM_INPUT = "Custom source";
export const PLACEHOLDER_SOURCE_CUSTOM_INPUT = "Enter custom source here";
export const CUSTOM_SOURCE_NAME = "Custom";


