export const MOCK_DEFAULT_MESSAGES = [
  {
    title: "Good news!",
    id: 2,
    message: "Good news! You have been selected for the next stage",
  },
  {
    title: "Hello!",
    id: 1,
    message: "Hello! Please contanct us as soon as possible",
  },
  {
    title: "Congratulations!",
    id: 3,
    message: "Congratulations! You have been selected for the next stage",
  },
];
