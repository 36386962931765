import { MouseEventHandler } from "react";

import { cn } from "~/utils/cn";

interface Props {
  label: string;
  onClick?: MouseEventHandler;
  active?: boolean;
}

export const SidebarItem: React.FC<Props> = ({ label, onClick, active }) => {
  return (
    <button
      className={cn(
        "whitespace-nowrap px-4 py-2 text-left text-sm text-typography-high-contrast transition-colors duration-300 active:bg-background-pressed",
        {
          "bg-background-hover font-semibold text-typography-color": active,
          "hover:bg-background-hover": !active,
        },
      )}
      disabled={active}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
