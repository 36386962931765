import { tv } from "tailwind-variants";
import { cn } from "~/utils/cn";

export const ratingButton = tv({
  base: cn(
    "flex-1 items-center justify-center px-4 py-2 ",
    "border border-l-0 border-solid border-neutral-rest first:border-l",
    "text-base font-medium text-neutral-primary",
    "first:rounded-s-xl last:rounded-e-xl",
    "cursor-pointer hover:bg-subtle-hover",
  ),
  variants: {
    isActive: {
      true: "bg-subtle-pressed hover:bg-subtle-pressed",
    },
  },
});
