"use client";

import React, { createContext, useState } from "react";
import {
  GlobalListViewContextValue,
  GlobalListViewProviderProps,
  Tables,
} from "./global-list-view-context.types";

export const GlobalListViewContext = createContext(
  {} as GlobalListViewContextValue,
);

export const GlobalListViewProvider: React.FC<GlobalListViewProviderProps> = ({
  children,
}) => {
  const [tables, setTables] = useState<Tables | null>(null);
  const [hideAllActionBars, setHideAllActionBars] = useState<boolean>(false);

  return (
    <GlobalListViewContext.Provider
      value={{
        tables,
        setTables,
        hideAllActionBars,
        setHideAllActionBars,
      }}
    >
      {children}
    </GlobalListViewContext.Provider>
  );
};
