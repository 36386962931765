"use client";

import React, { useState } from "react";
import { filterTabs } from "./candidate-filters.utils";
import { Tabs } from "./components/tabs";
import { FiltersBar } from "./components/filters-bar";
import {
  CandidateFiltersProps,
  ChildWithFilters,
} from "./candidate-filters.types";
import { useCandidateFilters } from "./context";
import { FiltersDrawer } from "~/components/v2/filters-drawer";

export const CandidateFilters: React.FC<CandidateFiltersProps> = ({
  children,
}) => {
  const [activeTab, setActiveTab] = useState(filterTabs[0].value);
  const { filters } = useCandidateFilters();

  const renderChildrenWithFilter = () => React.Children.map(children, (child: any) => {
    if (React.isValidElement<ChildWithFilters>(child)) {
      return React.cloneElement(child, { filters });
    }

    return child;
  });
  

  const RenderFilterDrawer = () => {
    const {
      isFilterDrawerOpen,
      setIsFilterDrawerOpen,
      onApplyAdvancedFilters,
      filterOptions,
    } = useCandidateFilters();

    return (
      <FiltersDrawer
        open={isFilterDrawerOpen}
        onOpenChange={setIsFilterDrawerOpen}
        onApplyFilters={onApplyAdvancedFilters}
        filterOptions={filterOptions}
      />
    );
  };

  return (
      <>
        <div className="w-full">
          <Tabs
            onClick={value => setActiveTab(value)}
            tabs={filterTabs}
            activeTab={activeTab}
          />
          <FiltersBar />
          <RenderFilterDrawer />
        </div>
        {renderChildrenWithFilter()}
      </>
  );
};
