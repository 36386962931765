import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/components/v2/Tooltip";

export interface ActionDrawerButtonProps
  extends React.ComponentProps<"button"> {
  icon: React.ElementType;
  tooltipText: string;
}

export const ActionDrawerButton = ({
  icon: Icon,
  tooltipText,
  ...rest
}: ActionDrawerButtonProps) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <button
          className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-s-lg bg-background-blue p-1.5 hover:bg-background-hover"
          {...rest}
        >
          <Icon />
        </button>
      </TooltipTrigger>
      <TooltipContent side="left">{tooltipText}</TooltipContent>
    </Tooltip>
  );
};
