import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";

export const fetchFileUrl = (
  { signal }: QueryFunctionContext,
  {
    key,
  }: {
    key: string;
  },
): Promise<string | undefined> => {
  if (!key) return Promise.resolve(undefined);
  return axios
    .get("/api/generateTemporaryUrl", {
      signal,
      params: {
        key,
      },
    })
    .then(res => res.data.temporaryUrl)
    .catch(() => {});
};
