import { Divider } from "~/components/v2/Divider";
import { Skill } from "./skill";
import { ScalisCandidate } from "~/types/pipeline";
import React from "react";
import { cn } from "~/utils/cn";

export const Skills: React.FC<Pick<ScalisCandidate, "skills">> = ({ skills }) => {
    const [open, setOpen] = React.useState(false);
    const toggleMore = () => {
        setOpen((current) => !current);
    };
    return (
        <div
            className={cn("flex w-full flex-col gap-2 text-sm", {
                hidden: !skills?.length,
            })}
        >
            <Divider />
            <h1>Relevant Skills</h1>
            <div
                className={cn(
                    "flex max-h-[3.25rem] flex-wrap gap-1 overflow-hidden transition-all",
                    {
                        "max-h-[99vh]": open,
                    },
                )}
            >
                {skills?.map((skill) => (
                    <Skill key={skill.name} {...skill} />
                ))}
            </div>
            <div className="cursor-pointer text-xs font-semibold" onClick={toggleMore}>
                see {!open ? "more" : "less"}
            </div>
        </div>
    );
};
