import { useEffect, useState } from "react";
import { useGetPlaceDetails } from "./useGetPlaceDetails";
import { PlaceDetailsReturn } from "~/queries/fetchPlaceDetails";
import { PlacesReturn } from "~/queries/fetchPlacesAutoComplete";
import { UseFormSetValue } from "react-hook-form";
import { CountryCodes } from "./location.consts";

interface Props {
  setValue: UseFormSetValue<any>;
  cityKey?: string;
  stateKey?: string;
  countryKey?: string;
  postalCodeKey?: string;
  addressLineKey?: string;
  locationKey?: string;
  setAddressLine?: boolean;
  setCountryCodes?: boolean;
  setLocation?: boolean;
  data?: any;
}

const useSetLocation = ({
  setValue,
  cityKey = "city",
  stateKey = "state",
  countryKey = "country",
  postalCodeKey = "zipcode",
  addressLineKey = "address_line_1",
  locationKey = "location",
  setAddressLine,
  setCountryCodes,
  setLocation,
  data,
}: Props) => {
  const [placeId, setPlaceId] = useState("");
  const { data: placeDetails } = useGetPlaceDetails(placeId);

  useEffect(() => {
    if (!placeDetails || !setValue) return;
    const location = {
      city: "",
      state: "",
      street: "",
      neighborhood: "",
      country: "",
    };
    (placeDetails as PlaceDetailsReturn).address_components.forEach(
      ({ long_name, types: [type] }) => {
        switch (type) {
          case "administrative_area_level_3":
          case "administrative_area_level_2":
          case "locality":
            if (!location.city) location.city = long_name;
            return;
          case "administrative_area_level_1":
            location.state = long_name;
            return;
          case "route":
            location.street = long_name;
            return;
          case "sublocality_level_1":
            location.neighborhood = long_name;
            return;
          case "country":
            location.country = long_name;
            return;
          default:
            return;
        }
      },
    );
    if (setCountryCodes) {
      location.country =
        CountryCodes[location.country as keyof typeof CountryCodes];
    }
    setValue(cityKey, location.city, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue(stateKey, location.state, {
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue(countryKey, location.country, {
      shouldDirty: true,
      shouldValidate: true,
    });
    if (setAddressLine && location.street && !data?.[addressLineKey]) {
      setValue(
        addressLineKey,
        location.street +
          (location.neighborhood ? `, ${location.neighborhood}` : ""),
        { shouldDirty: true, shouldValidate: true },
      );
    }

    if (setLocation) {
      setValue(
        locationKey,
        (placeDetails as PlaceDetailsReturn).geometry.location,
        {
          shouldDirty: true,
          shouldValidate: true,
        },
      );
    }
  }, [
    placeDetails,
    setValue,
    cityKey,
    stateKey,
    addressLineKey,
    setAddressLine,
    setLocation,
    locationKey,
    countryKey,
    setCountryCodes,
  ]);

  const handlePostalCode = (data: PlacesReturn) => {
    setValue(postalCodeKey, data.structured_formatting.main_text, {
      shouldValidate: true,
    });
    setPlaceId(data.place_id);
  };

  const handleLocationChange = (data: PlacesReturn) => {
    setValue(locationKey, data.description, { shouldValidate: true });
    setPlaceId(data.place_id);
  };

  return { handlePostalCode, handleLocationChange };
};

export default useSetLocation;
