import { MinusCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { ProfilePicture } from "~/components/v2/ProfilePicture";
import { SelectField } from "~/components/v2/SelectField";
import { InterviewerSelectProps } from "./interviewer-select.types";
import { FIELD_NAME_INTERVIEWER_SELECTION } from "../../interviewer-selection.constants";
import { getInterviewerOptions } from "./interviewer-select.utils";
import { Option } from "~/components/v2/Combobox";

export const InterviewerSelect: React.FC<InterviewerSelectProps> = ({
  interviewer,
  companyTeam,
  interviewers,
  index,
  onValueChange,
  onRemoveInterviewer,
}) => {
  const [options, setOptions] = React.useState<Option[]>([]);

  React.useEffect(() => {
    const options = getInterviewerOptions(
      companyTeam,
      interviewers,
      interviewer,
    );
    setOptions(options);
  }, [interviewers, companyTeam, interviewer]);

  return (
    <div
      className=" relative flex w-full items-center gap-2"
      key={interviewer.value}
    >
      <ProfilePicture
        src={interviewer?.profile_image}
        fullName={interviewer?.label}
      />
      <SelectField
        containerClassName="w-full"
        className="w-full"
        value={interviewer?.value}
        label=""
        name={FIELD_NAME_INTERVIEWER_SELECTION}
        placeholder="Select"
        options={options}
        setValue={(name, value) => {
          value &&
            onValueChange?.(FIELD_NAME_INTERVIEWER_SELECTION, value, index);
        }}
        emptyTerm=""
        searchPlaceholder=""
      />
      <button
        type="button"
        title="Remove interviewer"
        className="h-5 w-5 cursor-pointer  text-typography-color hover:text-actions-pressed"
        onClick={() => {
          interviewers?.length > 1 &&
            onRemoveInterviewer?.(
              FIELD_NAME_INTERVIEWER_SELECTION,
              interviewer?.value,
              index,
            );
        }}
      >
        <MinusCircleIcon />
      </button>
    </div>
  );
};
