import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { createDetailsEntry } from "~/mutations/jobApplication/createDetailsEntry";
import { DetailsEntry } from "~/services/jobSeekerApplications.types";

export const useCreateDetailsEntry = (
  options?: UseMutationOptions<
    any,
    unknown,
    {
      id: number;
      data: DetailsEntry;
    },
    unknown
  >,
) => useMutation({ mutationFn: createDetailsEntry, ...options });
