import { SOURCES } from "~/scalis-components";

export const FIELD_NAME_FIRST_NAME = "f_name";
export const FIELD_NAME_LAST_NAME = "l_name";
export const FIELD_NAME_EMAIL = "email";
export const FIELD_NAME_PHONE = "phone_number";
export const FIELD_NAME_SOURCE = "source";
export const FIELD_NAME_NOTES = "notes";
export const FIELD_NAME_DOCUMENTS = "documents";

export const FORM_FIELDS = {
  [FIELD_NAME_FIRST_NAME]: {
    label: "First name",
    placeholder: `Enter first name here`,
  },
  [FIELD_NAME_LAST_NAME]: {
    label: "Last name",
    placeholder: `Enter last name here`,
  },
  [FIELD_NAME_EMAIL]: {
    label: "Email address",
    placeholder: `Enter email address here`,
  },
  [FIELD_NAME_PHONE]: {
    label: "Phone number",
    placeholder: `Enter phone number here`,
  },
  [FIELD_NAME_NOTES]: {
    label: "Notes about the candidate",
    placeholder: `Insert here your notes about the candidate`,
  },
};

export const SOURCES_WITH_CUSTOM_SOURCE = [
  SOURCES.AGENCY.value,
  SOURCES.REFERRED.value,
  SOURCES.UNIVERSITY.value,
];
