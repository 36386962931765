import { ProfilePicture } from "~/components/v2/ProfilePicture";
import { MatchScore } from "~/components/v2/match-score";
import { cn } from "~/utils/cn";
import { CandidateInfoProps } from "./candidate-info.types";

export const CandidateInfo: React.FC<CandidateInfoProps> = ({ candidate, isDragging }) => {
    return <div className="flex h-14 w-full items-center gap-1">
        <div className="flex flex-col gap-4 w-full">
            <div className="flex w-full items-center gap-2">
                <ProfilePicture fullName={candidate.name} src={candidate.photo} />
                <div className="flex justify-between gap-2 w-full">
                    <div className="flex flex-col w-40 group-hover:w-full">
                        <strong className="line-clamp-1 text-xs font-semibold text-typography-high-contrast">
                            {candidate.name}
                        </strong>
                        <span className="line-clamp-2 text-xs text-typography-low-contrast">
                            {candidate.currentJob}
                        </span>
                    </div>
                    <div className={cn("overflow-hidden group-hover:hidden", { hidden: isDragging })}>
                        {!!candidate.matchScore && <MatchScore score={candidate.matchScore} />}
                    </div>
                </div>
            </div>

        </div>
    </div>
};