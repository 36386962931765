import { Checkbox as CheckboxInput } from "~/components/v2/Checkbox";
import { MultiCheckboxProps, SingleCheckboxProps } from "./checkbox.types";

export const MultiCheckbox: React.FC<MultiCheckboxProps> = ({
  options,
  values = [],
  setValue,
  name,
}) => {
  return (
    <>
      {options.map(option => (
        <div key={option.value} className="flex items-center gap-2">
          <CheckboxInput
            checked={values.includes(option.value)}
            onCheckedChange={isChecked => {
              if (isChecked) {
                setValue(name, [...values, option.value], {
                  shouldDirty: true,
                });
              }

              if (!isChecked) {
                setValue(
                  name,
                  values.filter(item => item !== option.value),
                  {
                    shouldDirty: false,
                  },
                );
              }
            }}
          />
          <span>{option.label}</span>
        </div>
      ))}
    </>
  );
};

export const SingleCheckbox: React.FC<SingleCheckboxProps> = ({
  options,
  value,
  setValue,
  name,
}) => {
  return (
    <div className="flex items-center gap-2">
      <CheckboxInput
        checked={value}
        onCheckedChange={isChecked => {
          setValue(name, isChecked, {
            shouldDirty: isChecked === true,
          });
        }}
      />
      <span>{options[0].label}</span>
    </div>
  );
};
