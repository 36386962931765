"use client";

import { useRouter } from "next/navigation";
import React, { useState } from "react";

import OpportunityCard from "~/components/v2/OpportunityCard";
import SearchInput from "~/components/v2/SearchInput";
import SortDialog from "~/components/v2/SortDialog";
import { useGetApplicationsByApplicants } from "~/hooks/applications/useGetApplicationsByApplicants";
import useDebounce from "~/hooks/useDebounce";
import { SeekerCard } from "~/scalis-components/pipeline/detailed-candidate-view/components/sidebar-opportunities/components/seeker-card";
import { FetchByJobSeekerPaginatedOptions } from "~/services/jobSeekerApplications.service";
import { Candidate } from "~/services/jobSeekerApplications.types";

interface OpenPanelProps {
  applicationId: number;
  jobId: number;
  employerId: number;
  jobSeeker?: Candidate;
  companyId: number | null;
  onClose: () => void;
}

export const OpenPanel: React.FC<OpenPanelProps> = ({
  applicationId,
  jobSeeker,
  employerId,
  jobId,
  companyId,
  onClose,
}) => {
  const [sortBy, setSortBy] = useState<string>();
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const { data } = useGetApplicationsByApplicants("all", jobSeeker?.id, {
    filters: { companyId },
    limit: 100,
    includeStages: true,
    sortBy: sortBy as FetchByJobSeekerPaginatedOptions["sortBy"],
    sortDirection: "desc",
    search: debouncedSearch,
    hasSCALISProfile: jobSeeker?.hasSCALISProfile,
  });

  const router = useRouter();

  const handleClick = (jobId: number, id: number) => async () => {
    await router.push(`/company/pipeline/${jobId}/${id}`);
    onClose();
  };

  return (
    <div
      data-testid="open-panel"
      className="flex h-full flex-col gap-2 overflow-y-auto p-4 text-typography-high-contrast"
    >
      <SeekerCard
        applicationId={applicationId}
        jobSeeker={jobSeeker}
        employerId={employerId}
        jobId={jobId}
        onClose={onClose}
      />
      <div className="flex items-center justify-between gap-2 border-b border-borders p-2">
        <span className="font-semibold">Opportunities</span>
        <div className="flex gap-2">
          <SearchInput value={search} onChangeFilter={setSearch} />
          <SortDialog
            onChange={setSortBy}
            value={sortBy ?? ""}
            options={[
              { label: "Applied At", value: "appliedAt" },
              { label: "Posted At", value: "postedAt" },
              { label: "Round", value: "round" },
              { label: "Interview Status", value: "status" },
              { label: "Title", value: "job_title" },
            ]}
            hideIcon
          />
        </div>
      </div>
      {data?.docs?.map(application => (
        <OpportunityCard
          key={application.id}
          application={application}
          job={application.job_id}
          milestone={application.stage?.milestone}
          stages={application.stages}
          onClick={handleClick(application.jobId, application.id)}
          active={application.id === applicationId}
        />
      ))}
    </div>
  );
};
