import axios from "~/services/axios.service";

export const updateFile = async ({
  id,
  ...data
}: {
  file?: string;
  fileName?: string;
  shared?: boolean;
  id: number;
}) => {
  return new Promise((resolve, reject) => {
    axios
      .put(`/api/files/${id}`, data)
      .then(response => resolve(response.data))
      .catch(e => reject(e.message));
  });
};
