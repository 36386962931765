import { DotFilledIcon } from "@radix-ui/react-icons";
import { ExperienceProps } from "./experiences.types";
import { cn } from "~/utils/cn";

export const Experience: React.FC<ExperienceProps> = ({
    title,
    company,
    level,
    hidden,
}) => (
    <div className={cn("leading-5 pb-2", { hidden })}>
        <div className="text-xs">{title}</div>
        <div className="flex items-center text-xs gap-1">
            {company}
            <DotFilledIcon />
            {level}
        </div>
    </div>
);
