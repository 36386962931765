import { format } from "date-fns";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useState } from "react";
import { FaRegFile, FaXmark } from "react-icons/fa6";

import Spinner from "~/components/spinner";
import { Button } from "~/components/v2/Button";
import { Switch } from "~/components/v2/Switch";
import { useDeleteFile } from "~/hooks/jobseeker/files/useDeleteFile";
import { useUpdateFile } from "~/hooks/jobseeker/files/useUpdateFile";
import { useToast } from "~/hooks/useToast";
import { UploadFile } from "~/scalis-components/pipeline/detailed-candidate-view";
import { DeleteDialog } from "../delete-dialog";
import { ShareDialog } from "../share-dialog";

export const Document: React.FC<UploadFile> = ({
  fileName,
  user,
  seekerAccount,
  uploadedByEmp,
  createdAt,
  shared,
  id,
}) => {
  const { mutateAsync: updateFile } = useUpdateFile();
  const { mutateAsync: deleteFile, isPending: deleting } = useDeleteFile();
  const { toast } = useToast();
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const [localShared, setLocalShared] = useState(shared);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const asPath = `${pathname}?${searchParams?.toString()}`;

  const uploadedBy = uploadedByEmp
    ? user?.full_name
    : `${seekerAccount?.job_seeker_basicinfo?.f_name} ${seekerAccount?.job_seeker_basicinfo?.l_name}`;

  const handleConfirmShareStatus = () => {
    setLocalShared(!shared);
    setShareDialogOpen(false);
    updateFile({ id, shared: !shared })
      .then(() => {
        router.replace(asPath);
      })
      .catch(() => {
        setLocalShared(shared);
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to update file sharing",
        });
      });
  };
  const handleCheckedChange = () => {
    setShareDialogOpen(true);
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDelete = () => {
    setDeleteDialogOpen(false);
    deleteFile({ id })
      .then(() => {
        router.replace(asPath);
      })
      .catch(() => {
        toast({
          variant: "destructive",
          title: "Error",
          description: "Failed to delete file",
        });
      });
  };

  return (
    <>
      <div className="grid grid-cols-3 border-b border-borders px-4 py-2 text-sm text-typography-high-contrast">
        <div className="col-span-1">
          <div className="flex gap-2">
            <div className="flex size-8 items-center justify-center rounded-full bg-primary-background">
              <FaRegFile className="text-primary" />
            </div>
            <div className="flex-1">
              <h2 className="text-sm font-semibold text-typography-high-contrast">
                {fileName}
              </h2>
              <div className="flex flex-col gap-0 text-xs italic">
                <span className="text-xs text-typography-high-contrast">
                  Uploaded by {uploadedBy}
                </span>
                <span className="text-typography-low-contrast-text">
                  {format(new Date(createdAt), "MMM dd, yyyy h:mm:ss a")}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 flex items-center gap-1 whitespace-nowrap">
          <span className="text-sm text-typography-high-contrast">
            0075
            <strong className="font-semibold">﹒Marketing Assistant</strong>
          </span>
        </div>
        <div className="col-span-1 flex items-center justify-between">
          <label className="flex items-center gap-2 text-sm text-typography-low-contrast-text [&:has(button[data-state=checked])]:text-typography-high-contrast">
            Share with candidate
            <Switch
              className="data-[state=unchecked]:bg-typography-high-contrast"
              checked={localShared}
              onCheckedChange={handleCheckedChange}
            />
          </label>

          <Button
            variant="ghost"
            size="icon"
            className="rounded-full"
            onClick={handleDeleteClick}
            disabled={deleting}
          >
            {deleting ? <Spinner /> : <FaXmark className="text-base" />}
          </Button>
        </div>
      </div>
      <ShareDialog
        open={shareDialogOpen}
        onOpenChange={setShareDialogOpen}
        onConfirm={handleConfirmShareStatus}
        sharing={!shared}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onOpenChange={setDeleteDialogOpen}
        onConfirm={handleDelete}
      />
    </>
  );
};
