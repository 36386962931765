import React from "react";
import { SelectField } from "~/components/v2/SelectField";
import { FIELD_NAME_EMPLOYEE_SELECT } from "./employee-select.constants";
import { EmployeeSelectProps } from "./employee-select.types";
import { LABEL_EMPLOYEE_SELECT } from "./employee-select.constants";

export const EmployeeSelect: React.FC<EmployeeSelectProps> = ({
    onValueChange,
    value,
    error,
    employees = []
}) => {

    return (
        <SelectField
            label={LABEL_EMPLOYEE_SELECT}
            aria-label={LABEL_EMPLOYEE_SELECT}
            name={FIELD_NAME_EMPLOYEE_SELECT}
            placeholder="Select"
            searchPlaceholder="Search..."
            emptyTerm="employee"
            options={employees?.map((employee) => ({
                label: employee.label || "",
                value: employee.value || "",
            }))}
            className="w-full"
            setValue={onValueChange}
            value={value}
            error={error}
        />
    );
};
