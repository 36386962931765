"use client";

import { useState } from "react";

interface UseSelectProps {
  setValue: any;
  multi: boolean;
  value: any;
  name: string;
  options: { label: string; value: string }[];
}

export const useSelect = ({
  setValue,
  multi,
  name,
  options,
  value,
}: UseSelectProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleSelect = (newValue: string) => {
    if (multi) {
      const values = value;
      if (values?.includes(newValue)) {
        setValue(
          name,
          values.filter((v: any) => v !== newValue),
          { shouldDirty: true, shouldTouch: true },
        );
      } else {
        setValue(name, [...(values || []), newValue], {
          shouldDirty: true,
          shouldTouch: true,
        });
      }
    } else {
      setValue(name, newValue, {
        shouldDirty: true,
        shouldTouch: true,
      });
      setOpen(false);
    }
  };

  const getLabel = (value: any) => {
    if (multi) {
      const labels = options
        .filter(option => value?.includes(option.value))
        .map(option => option.label);

      return labels.join(", ");
    } else {
      return options.find(option => option.value === value)?.label;
    }
  };

  const removeJob = (removeValue: any) => {
    if (!multi) return;
    const values = value;
    setValue(
      name,
      values.filter((v: any) => v !== removeValue),
      { shouldDirty: true, shouldTouch: true },
    );
  };

  return {
    open,
    setOpen,
    handleSelect,
    getLabel,
    removeJob,
  };
};
