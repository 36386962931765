import React from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/v2/Tabs";
import { cn } from "~/utils/cn";
import { GetFilteredCandidatesProvider } from "../get-filtered-candidates-context";
import { tabs } from "./candidate-tab-view.styles";
import { CandidateTabViewProps } from "./candidate-tab-view.types";
import { getCandidateViewDetails } from "./candidate-tab-view.utils";
import { DetailedCandidateProvider } from "../detailed-candidate-view";
import { CandidateFilters } from "../list-view";
import { CandidateFiltersProvider } from "../list-view/candidate-filters/context";

export const CandidateTabView: React.FC<CandidateTabViewProps> = ({
  views,
  tabsContentClassName,
  candidateFilters,
}) => {
  const { container, list, trigger } = tabs();

  const viewsDetails = views.map(view => getCandidateViewDetails(view));
  const defaultValue = viewsDetails[0].id;

  const renderTabsContent = () => {
    return viewsDetails.map(({ Component, id }) => (
      <TabsContent
        key={id}
        value={id}
        className={cn("mt-0 h-[calc(100%-70px)] w-full", tabsContentClassName)}
      >
        {Component}
      </TabsContent>
    ));
  };

  return (
    <Tabs defaultValue={defaultValue} className={container()}>
      <TabsList className={list()}>
        {viewsDetails.map(({ viewName, Icon, id }) => (
          <TabsTrigger className={trigger()} value={id} key={viewName}>
            <Icon />
            {viewName}
          </TabsTrigger>
        ))}
      </TabsList>
      {!candidateFilters ? (
        renderTabsContent()
      ) : (
        <CandidateFiltersProvider jobId={candidateFilters.jobId}>
          <DetailedCandidateProvider initialJobId={candidateFilters.jobId}>
            <CandidateFilters>
              <GetFilteredCandidatesProvider
                jobId={candidateFilters.jobId}
                defaultQueryParams={candidateFilters.defaultQueryParams}
              >
                {renderTabsContent()}
              </GetFilteredCandidatesProvider>
            </CandidateFilters>
          </DetailedCandidateProvider>
        </CandidateFiltersProvider>
      )}
    </Tabs>
  );
};
