import React from "react";

export type Candidate = {
  id: number;
  name?: string;
  email: string;
};

export type RejectCandidateContextProps = {
  rejectCandidateData: RejectCandidateData;
  updateRejectCandidateData: (data: Partial<RejectCandidateData>) => void;
  resetRejectCandidateData: () => void;
  previousSelectCandidates: Candidate[];
  setPreviousSelectCandidates: (data: Candidate[]) => void;
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
};

export type RejectCandidateData = {
  jobId?: number | string;
  applicationIds: number[];
  medalistDesignation?: string;
  reason: string;
  details: string;
  message: string;
  questions: {
    questionId: number;
    score: number;
  }[];
};

export interface RejectContextProviderProps {
  children: React.ReactNode;
}
