import { useContext } from "react";
import { CandidateFiltersContext } from ".";

export const useCandidateFilters = () => {
  const {
    filters,
    filterOptions,
    isFilterDrawerOpen,
    onApplyAdvancedFilters,
    onUpdateField,
    setIsFilterDrawerOpen,
    activeTab,
    setActiveTab,
  } = useContext(CandidateFiltersContext);

  return {
    filters,
    onApplyAdvancedFilters,
    onUpdateField,
    isFilterDrawerOpen,
    setIsFilterDrawerOpen,
    filterOptions,
    activeTab,
    setActiveTab,
  };
};
