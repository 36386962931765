import { TabsProps } from "./tabs.types";
import { Button } from "~/components/v2/Button";

export const Tabs: React.FC<TabsProps> = ({ tabs, onClick, activeTab }) => {
  return (
    <div className="ml-6 flex">
      {tabs.map(({ label, value }) => (
        <div className="mx-2 flex h-9 items-center" key={value}>
          {activeTab === value ? (
            <div className="flex h-full cursor-default select-none items-center rounded-b-none rounded-t-xl bg-white px-2 text-sm font-medium text-primary-text">
              {label}
            </div>
          ) : (
            <Button
              variant="ghost"
              className="h-6 select-none px-2 py-0 text-sm/3 text-typography-low-contrast"
              onClick={() => onClick(value)}
            >
              {label}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};
