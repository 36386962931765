import { TriangleDownIcon, TriangleUpIcon } from "@radix-ui/react-icons";
import { Header } from "@tanstack/react-table";
import React from "react";

type SortIconProps = {
  header: Header<unknown, unknown>;
  sorting: { id: string; desc: boolean }[];
};

export const SortColumnIcon: React.FC<SortIconProps> = ({
  header,
  sorting,
}) => {
  const isShowingUpTriangle = !(
    sorting[0]?.id === header.column.id && !sorting[0]?.desc
  );

  const isShowingDownTriangle = !(
    sorting[0]?.id === header.column.id && sorting[0]?.desc
  );

  return (
    <div
      className="relative flex h-5 w-5 flex-col rounded-full p-1 active:bg-background-pressed"
      onClick={header.column.getToggleSortingHandler()}
      role="button"
    >
      {isShowingUpTriangle && (
        <TriangleUpIcon className="absolute left-0.5 top-[-1px] h-4 w-4 cursor-pointer text-actions-secondary" />
      )}
      {isShowingDownTriangle && (
        <TriangleDownIcon className="absolute bottom-[-1px] left-0.5  h-4 w-4 cursor-pointer text-actions-secondary" />
      )}
    </div>
  );
};
