import { useRef, useState } from "react";

import { cn } from "~/utils/cn";
import { Star } from "./star";

interface IStarRating {
  activeStars: number;
  onClick?: (value: number) => void;
  className?: string;
}

export const StarRating = ({
  activeStars,
  onClick,
  className,
}: IStarRating) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [hoverValue, setHoverValue] = useState<number>(0);

  const calculateRating = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!ref?.current) return;
    const { width, left } = ref.current.getBoundingClientRect();
    const amountOfStars = ((e.clientX - left) / width) * 5;
    const result = Math.round(amountOfStars * 2) / 2;
    setHoverValue(result);
  };

  const getRating = (index: number) => {
    const value = isHovered ? hoverValue : activeStars;
    return value - index >= 1 ? 1 : value - index <= 0 ? 0 : value - index;
  };

  return (
    <div
      className={cn(
        "flex w-fit cursor-pointer text-score-colors-06",
        className,
      )}
      ref={ref}
      onMouseEnter={onClick ? () => setIsHovered(true) : undefined}
      onMouseLeave={onClick ? () => setIsHovered(false) : undefined}
      onMouseMoveCapture={e => calculateRating(e)}
      onClick={onClick ? () => onClick(hoverValue) : undefined}
    >
      {[...Array(5)].map((_elem, index) => (
        <Star key={index} status={getRating(index)} />
      ))}
    </div>
  );
};
