import axios from "~/services/axios.service";

export interface SchedulerInviteProps {
  data: {
    applicationId: number;
    stageId: number;
    calendarId: number;
    length: number;
    interviewers?: number[];
    candidates: {
      id: number;
      type: string;
    }[];
    message?: string;
  }[];
}

export interface SchedulerInviteResponse {
  completed?: boolean;
  updated?: boolean;
  message?: string;
}

export interface UpdateSchedulerInviteProps {
  id: number;
  data: SchedulerInviteProps["data"];
}

export interface DeleteSchedulerInviteProps {
  id: number;
}

export interface ResendSchedulerInviteProps {
  id: number;
}

export const createSchedulerInvite = async ({
  data,
}: SchedulerInviteProps): Promise<SchedulerInviteResponse> =>
  await axios
    .post("/api/interviews/scheduler/invite", data)
    .then(response => response.data)
    .catch(e => e.message);

export const updateSchedulerInvite = async ({
  id,
  data,
}: UpdateSchedulerInviteProps): Promise<SchedulerInviteResponse> =>
  await axios
    .put(`/api/interviews/scheduler/invite?id=${id}`, data)
    .then(response => response.data)
    .catch(e => e.message);

export const deleteSchedulerInvite = async ({
  id,
}: DeleteSchedulerInviteProps): Promise<SchedulerInviteResponse> =>
  await axios
    .delete(`/api/interviews/scheduler/invite?id=${id}`)
    .then(response => response.data)
    .catch(e => e.message);

export const resendSchedulerInvite = async ({
  id,
}: ResendSchedulerInviteProps): Promise<SchedulerInviteResponse> =>
  await axios
    .post(`/api/interviews/scheduler/invite/resend?id=${id}`)
    .then(response => response.data)
    .catch(e => e.message);
