import React from "react";

interface ResetProps {
  onClick: () => void;
}
export const Reset: React.FC<ResetProps> = ({ onClick }) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClick();
  };

  return (
    <span
      onClick={handleClick}
      className="cursor-pointer text-sm text-primary-text hover:underline"
    >
      Reset
    </span>
  );
};
