import axios from "~/services/axios.service";
import { DetailsEntry } from "~/services/jobSeekerApplications.types";

export const createDetailsEntry = ({
  id,
  data,
}: {
  id: number;
  data: DetailsEntry;
}) =>
  axios
    .post(`/api/applications/${id}/details`, data)
    .then(response => response.data)
    .catch(error => {
      throw error.response.data;
    });
