import { getMappedMilestone } from "./milestone-detail-list-utils";
import { MilestoneDetailListViewProps } from "./milestone-detail-list-view.types";
import { CandidateListViewViewer } from "~/scalis-components/pipeline/list-view/candidate-list-view-viewer";

export const MilestoneDetailListView: React.FC<
  MilestoneDetailListViewProps
> = ({ milestone, ...props }) => {
  const [mappedMilestone] = getMappedMilestone({ milestone, ...props });

  return <CandidateListViewViewer {...mappedMilestone} />;
};
