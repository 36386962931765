import { useQuery } from "@tanstack/react-query";
import { getStagesByApplication } from "~/queries/applications/getStagesByApplication";

export const useGetStagesByApplication = (id: number) => {
  const { data, isLoading } = useQuery({
    queryKey: ["stages-by-applicantion", id],
    queryFn: () => getStagesByApplication({ id }),
    enabled: !!id,
  });
  return { stages: data?.docs, isLoadingStages: isLoading };
};
