import React from "react";
import {
  CheckboxFieldProps,
  MultiOptions,
  SingleOptions,
} from "./checkbox-field.types";
import { CollapsibleFilterField } from "../collapsible-filter-field";
import { Controller, useFormContext } from "react-hook-form";
import {
  SingleCheckbox,
  MultiCheckbox,
} from "~/scalis-components/pipeline/list-view/candidate-filters/components/filter-type";

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  label,
  options,
  name,
  multi = false,
}) => {
  const { setValue, control, resetField, watch } = useFormContext();
  const value = watch(name);

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <CollapsibleFilterField
          label={label}
          onResetField={() => resetField(name)}
          showReset={multi ? !!value?.length : !!value}
          defaultOpen={multi ? !!value?.length : !!value}
        >
          {multi ? (
            <MultiCheckbox
              name={name}
              options={options as MultiOptions[]}
              setValue={setValue}
              values={value}
            />
          ) : (
            <SingleCheckbox
              name={name}
              options={options as SingleOptions[]}
              setValue={setValue}
              value={value}
            />
          )}
        </CollapsibleFilterField>
      )}
    />
  );
};
