import { PlusCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { InterviewerSelect } from "./components";
import { InterviewerSelectionProps } from "./interviewer-selection.types";
import { LABEL_ADD_INTERVIEWER } from "./interviewer-selection.constants";

export const InterviewerSelection: React.FC<InterviewerSelectionProps> = ({
  onValueChange,
  onAddInterviewer,
  onRemoveInterviewer,
  companyTeam,
  interviewers,
  label,
  error,
}) => {

  return (
    <div className="flex w-full flex-col gap-2">
      {<span className="text-sm font-semibold">{label}</span>}
      {interviewers.map((interviewer, index) => (
        <InterviewerSelect
          interviewer={interviewer}
          interviewers={interviewers}
          companyTeam={companyTeam}
          index={index}
          key={`${interviewer?.value}${index}`}
          onValueChange={onValueChange}
          onRemoveInterviewer={onRemoveInterviewer}
        />
      ))}
      <button
        type="button"
        title={LABEL_ADD_INTERVIEWER}
        onClick={onAddInterviewer}
        className="flex cursor-pointer items-center gap-2 text-left text-sm font-semibold leading-6 text-typography-color hover:text-actions-pressed"
      >
        <span>{LABEL_ADD_INTERVIEWER}</span>
        <PlusCircleIcon className="h-5 w-5" />
      </button>
      {error && <span className="flex items-center gap-1 text-xs text-error">
        <i className="mi-circle-error"></i>
        {error}
      </span>}
    </div>
  );
};
