"use client";

import { createContext, useState, useMemo } from "react";
import {
  FormValues,
  formDefaultValues,
  formSchema,
} from "../candidate-filters.schema";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FIELD_NAME_LAST_ACTIVITY_DATE,
  FIELD_NAME_MATCH_SCORE,
} from "../candidate-flters.contants";
import { useGetCandidateFilterOptions } from "~/hooks/use-get-cadidate-filter-options";
import {
  filterSelects,
  filterTabs,
  mapFieldOptions,
} from "../candidate-filters.utils";
import {
  CandidateFiltersContextProps,
  CandidateFiltersProviderProps,
} from "./candidate-filters-context.types";

export const CandidateFiltersContext = createContext(
  {} as CandidateFiltersContextProps,
);

export const CandidateFiltersProvider: React.FC<
  CandidateFiltersProviderProps
> = ({ jobId, children }) => {
  const [filters, setFilters] = useState<FormValues>({} as FormValues);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(filterTabs[0].value);

  const { filterOptions } = useGetCandidateFilterOptions(jobId, filterSelects);

  const mappedFlterOptions = useMemo(
    () => mapFieldOptions(filterOptions! ?? []),
    [filterOptions],
  );

  const methods = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: formDefaultValues as FormValues,
  });

  const onApplyAdvancedFilters = (dirtyFields: {
    [K in keyof FormValues]: boolean;
  }) => {
    const dirtyFieldsKeys = Object.keys(dirtyFields) as (keyof FormValues)[];

    const newFilters = dirtyFieldsKeys.reduce<any>((acc, key) => {
      return { ...acc, ...formatFieldData(key) };
    }, {});

    setFilters(newFilters);
  };

  const onUpdateField = (fieldName: keyof FormValues) => {
    setFilters({ ...filters, ...formatFieldData(fieldName) });
  };

  const formatFieldData = (fieldName: keyof FormValues) => {
    const { getValues } = methods;
    const formValues = getValues();

    switch (fieldName) {
      case FIELD_NAME_MATCH_SCORE: {
        const [min, max] = formValues[fieldName];
        return {
          matchScoreMin: min,
          matchScoreMax: max,
        };
      }

      case FIELD_NAME_LAST_ACTIVITY_DATE: {
        const lastActivityDate = formValues[fieldName];

        if (lastActivityDate) {
          const { from, to } = lastActivityDate;
          return {
            lastActivityFrom: from,
            lastActivityTo: to,
          };
        }

        return { lastActivityFrom: undefined, lastActivityTo: undefined };
      }

      default:
        return { [fieldName]: formValues[fieldName] };
    }
  };

  const value = useMemo(
    () => ({
      filters,
      onApplyAdvancedFilters,
      onUpdateField,
      isFilterDrawerOpen,
      setIsFilterDrawerOpen,
      filterOptions: mappedFlterOptions,
      activeTab,
      setActiveTab,
    }),
    [
      filters,
      onApplyAdvancedFilters,
      onUpdateField,
      isFilterDrawerOpen,
      setIsFilterDrawerOpen,
      mappedFlterOptions,
      activeTab,
      setActiveTab,
    ],
  );

  return (
    <CandidateFiltersContext.Provider value={value}>
      <FormProvider {...methods}>{children}</FormProvider>
    </CandidateFiltersContext.Provider>
  );
};
