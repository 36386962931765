import { formatPhoneNumber } from "react-phone-number-input";

import {
  DetailsEntry,
  DetailsEntryTypeEnum,
} from "~/services/jobSeekerApplications.types";
import { FormattedDetails } from "./details.types";

export const detailsReducer = (
  acc: FormattedDetails,
  item: DetailsEntry,
  index: number,
) => {
  switch (item.type) {
    case DetailsEntryTypeEnum.Email:
      acc.emails.push({ value: item.email, index });
      break;
    case DetailsEntryTypeEnum.Phone:
      acc.phoneNumbers.push({ value: formatPhoneNumber(item.phone), index });
      break;
    case DetailsEntryTypeEnum.Location:
      acc.locations.push({ value: `${item.city}, ${item.state}`, index });
      break;
    case DetailsEntryTypeEnum.Profile:
      acc.profiles.push({ value: item.link, index });
      break;
    case DetailsEntryTypeEnum.Source:
      acc.sources.push({
        value: `${item.source} - ${item.sourceCategory} ${item.credit ? `- ${item.credit}` : ""}`,
        index,
      });
      break;
    case DetailsEntryTypeEnum.MedalistDesignation:
      acc.medalistDesignations.push({
        value: `${item.medal} - ${item.job}`,
        index,
      });
      break;
    case DetailsEntryTypeEnum.Timezone:
      acc.timezone = { value: item.timezone, index };
      break;
  }
  return acc;
};
