import { Prisma, Stage } from "@prisma/client";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";
import { FetchByJobSeekerPaginatedOptions } from "~/services/jobSeekerApplications.service";
import { PaginatedResponse } from "~/types/api";

export type Response = PaginatedResponse<
  Prisma.JobSeekerApplicationGetPayload<{
    include: {
      job_id: { include: { job_seeker_application: true } };
      stage: { include: { milestone: true } };
    };
  }> & { publicStages?: Stage[]; stages?: Stage[] }
>;

export const fetchApplicationsByApplicant = (
  { signal }: QueryFunctionContext,
  id?: number | null,
  queryParams?: FetchByJobSeekerPaginatedOptions,
) =>
  axios
    .get<Response>(`/api/applications/by-applicant/${id}`, {
      signal,
      params: {
        ...queryParams,
        filters: queryParams?.filters
          ? JSON.stringify(queryParams.filters)
          : undefined,
      },
    })
    .then(response => response.data);
