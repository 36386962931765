import { matchScore } from "./match-score.styles";
import { MatchScoreProps } from "./match-score.types";

export const MatchScore: React.FC<MatchScoreProps> = ({ score }) => {
  const getBgColor = (score: number) => {
    const decimal = Math.round(score / 10);

    if (decimal < 1) return "1";

    return decimal >= 8 ? "8" : decimal.toString();
  };

  const color = getBgColor(score) as keyof typeof matchScore.variants.color;

  return (
    <div className={matchScore({ color })}>
      <span className="text-sm font-semibold text-typography-high-contrast">
        {score}
      </span>
    </div>
  );
};
