import React from "react";
import { SelectField } from "~/components/v2/SelectField";
import {
  CUSTOM_SOURCE_NAME,
  FIELD_NAME_SOURCE_CUSTOM,
  FIELD_NAME_SOURCE_NAME,
  FIELD_NAME_SOURCE_SELECT,
  LABEL_SOURCE_CUSTOM_INPUT,
  LABEL_SOURCE_NAME_SELECT,
  LABEL_SOURCE_SELECT,
  PLACEHOLDER_SOURCE_CUSTOM_INPUT,
} from "./source-select.constants";
import { SourceSelectProps } from "./source-select.types";
import { getSourceOptions } from "./source-select.utils";
import { TextField } from "~/components/v2/TextField";

export const SourceSelect: React.FC<SourceSelectProps> = ({
  onValueChange,
  valueSource,
  valueSourceName,
  valueSourceCustom,
  errorSource,
  errorSourceName,
  errorSourceCustom,
  sources,
  triggerValidation,
}) => {
  const sourceOptions = getSourceOptions(sources!);
  const defaultValue = sourceOptions[0]?.value;
  const [sourceNameOptions, setSourceNameOptions] = React.useState<
    string[] | undefined
  >([]);

  React.useEffect(() => {
    setSourceNameOptions(sources?.[valueSource]);
  }, [valueSource]);

  return (
    <div className="flex flex-col gap-4">
      <SelectField
        label={LABEL_SOURCE_SELECT}
        aria-label={LABEL_SOURCE_SELECT}
        name={FIELD_NAME_SOURCE_SELECT}
        placeholder="Select"
        searchPlaceholder="Search..."
        emptyTerm="source"
        options={sourceOptions?.map(source => ({
          label: source.label || "",
          value: source.value || "",
        }))}
        className="w-full"
        setValue={onValueChange}
        value={valueSource}
        error={errorSource}
        required
      />
      {valueSource && !!sourceNameOptions?.length && (
        <SelectField
          label={LABEL_SOURCE_NAME_SELECT}
          aria-label={LABEL_SOURCE_NAME_SELECT}
          name={FIELD_NAME_SOURCE_NAME}
          placeholder="Select"
          searchPlaceholder="Search..."
          emptyTerm="source name"
          options={
            sourceNameOptions?.map(sourceName => ({
              label: sourceName || "",
              value: sourceName || "",
            })) ?? []
          }
          className="w-full"
          setValue={onValueChange}
          value={valueSourceName || defaultValue}
          error={errorSourceName}
          required
        />
      )}
      {valueSource &&
        (valueSourceName === CUSTOM_SOURCE_NAME ||
          !sourceNameOptions?.length) && (
          <TextField
            onValueChange={(_, value) => {
              onValueChange(FIELD_NAME_SOURCE_CUSTOM, value);
              triggerValidation?.(FIELD_NAME_SOURCE_CUSTOM);
            }}
            required
            name={FIELD_NAME_SOURCE_CUSTOM}
            label={LABEL_SOURCE_CUSTOM_INPUT}
            placeholder={PLACEHOLDER_SOURCE_CUSTOM_INPUT}
            error={errorSourceCustom}
            defaultValue={valueSourceCustom}
          />
        )}
    </div>
  );
};
