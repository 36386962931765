import { SelectFieldProps } from "./select-field.types";
import { CollapsibleFilterField } from "../collapsible-filter-field";
import { Select } from "~/scalis-components/pipeline/list-view/candidate-filters/components/filter-type";
import { Controller, useFormContext } from "react-hook-form";

export const SelectField: React.FC<SelectFieldProps> = ({
  label,
  name,
  options,
}) => {
  const { setValue, control, resetField, watch } = useFormContext();
  const value = watch(name);

  return (
    <Controller
      name={name}
      control={control}
      render={() => {
        return (
          <CollapsibleFilterField
            label={label}
            onResetField={() => resetField(name)}
            showReset={!!value?.length}
            defaultOpen={!!value?.length}
          >
            <Select
              multi
              emptyTerm=""
              options={options}
              placeholder=""
              name={name}
              searchPlaceholder="search"
              value={value}
              setValue={setValue}
              className="rounded-lg"
            />
          </CollapsibleFilterField>
        );
      }}
    />
  );
};
