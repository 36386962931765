"use client";

import { useContext } from "react";
import { ListViewContext } from "../../list-view-context";
import {
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import {
  columnsIdWithoutText,
  defaultColumnsID,
  moveColumnToLastPosition,
} from "../../list-view.utils";
import { Header } from "@tanstack/react-table";
import { swapArrayElements } from "~/utils/arraySwapPositions";

export const useListViewHeader = () => {
  const {
    table,
    columnOrder,
    setColumnOrder,
    tableColumns,
    tableColor,
    isShowingShadow,
    activeColumnId,
    setActiveColumnId,
    fixedColumnsIds,
    columnVisibility,
    setColumnVisibility,
    sorting,
  } = useContext(ListViewContext);

  const DEFAULT_FIXED_COLUMNS = 2;
  const fixedLeftColumns = fixedColumnsIds.length - DEFAULT_FIXED_COLUMNS;
  const IS_SHOWING_ADD_COLUMN = !columnVisibility[defaultColumnsID.addColumn];

  const activeColumnIndex = tableColumns.findIndex(
    item => item.id === activeColumnId,
  );

  const getActiveColumnName = () => {
    if (activeColumnId) {
      const activeColumn = table.getColumn(String(activeColumnId));

      return String(activeColumn?.columnDef?.header);
    }

    return "Column Header";
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      if (
        columnOrder.includes(String(active.id)) &&
        !fixedColumnsIds.includes(String(over?.id!))
      ) {
        setColumnOrder(columnOrder => {
          const oldIndex = columnOrder.indexOf(active.id as string);
          const newIndex = columnOrder.indexOf(over?.id! as string);
          return arrayMove(columnOrder, oldIndex, newIndex);
        });
      }
    }
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveColumnId(active.id);
  };

  const handleDragCancel = () => {
    setActiveColumnId(null);
  };

  const getNumberOfHiddenColumns = () => {
    return Object.keys(columnVisibility).reduce((acc, id) => {
      if (!columnVisibility[id]) {
        acc++;
      }

      return acc;
    }, 0);
  };

  const getActionMenuMoveColumn = (header: Header<unknown, unknown>) => {
    if (header.column.columnDef.meta?.isPinned) {
      return null;
    }

    const minLeftMoveIndex = fixedLeftColumns - 1;

    const actionsMenuItems = [];

    if (header.index > minLeftMoveIndex) {
      actionsMenuItems.push({
        onClick: () => moveColumnLeft(header.column.id),
        label: "Move column left",
        id: "move-column-left",
      });
    }

    const BEFORE_LAST_COLUMN_INDEX = columnOrder.length - 2;

    const maxRightMoveIndex = IS_SHOWING_ADD_COLUMN
      ? BEFORE_LAST_COLUMN_INDEX
      : BEFORE_LAST_COLUMN_INDEX - getNumberOfHiddenColumns();

    if (header.index < maxRightMoveIndex) {
      actionsMenuItems.push({
        onClick: () => moveColumnRight(header.column.id),
        label: "Move column right",
        id: "move-column-right",
      });
    }

    return actionsMenuItems;
  };

  const moveColumnLeft = (columnId: string) => {
    const columnIndex = columnOrder.indexOf(columnId);

    setColumnOrder(columnOrder => {
      const newColumnOrder = swapArrayElements<string>(
        columnOrder,
        columnIndex,
        columnIndex - 1,
      );

      return newColumnOrder;
    });
  };

  const moveColumnRight = (columnId: string) => {
    const columnIndex = columnOrder.indexOf(columnId);

    setColumnOrder(columnOrder => {
      const newColumnOrder = swapArrayElements<string>(
        columnOrder,
        columnIndex,
        columnIndex + 1,
      );

      return newColumnOrder;
    });
  };

  const moveColumntoTheLast = (columnId: string) => {
    setColumnOrder(columnOrder => {
      return moveColumnToLastPosition(columnOrder, columnId);
    });
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {}),
    useSensor(TouchSensor, {}),
    useSensor(KeyboardSensor, {}),
  );

  const getSylesVariants = ({
    header,
  }: {
    header: Header<unknown, unknown>;
  }) => {
    const { meta } = header.column.columnDef;
    const metaHeader = meta?.header ?? { styles: {} };

    return {
      hasHoverBg: !metaHeader?.styles?.noHoverBg,
      isPinned: !!header.column.getIsPinned(),
      colorColumn: header.column.id === defaultColumnsID.select,
      isHidden: metaHeader?.styles?.hidden,
      hasActions: metaHeader?.hasActions,
      hasBorder: !metaHeader?.styles?.noBorders,
      select: header.column.id === defaultColumnsID.select,
      hasElipseContent: !columnsIdWithoutText.includes(header.column.id),
    };
  };

  const getHeaderActionsMenu = (header: Header<unknown, unknown>) => {
    const topActions = [
      {
        onClick: header.column.getToggleSortingHandler(),
        label: "Sort",
        id: "sort",
      },
    ];

    if (header.column.columnDef.meta?.header?.canHide) {
      topActions.push({
        id: "delete",
        label: "Delete",
        onClick: () => {
          setColumnVisibility(columnVisibility => ({
            ...columnVisibility,
            [header.column.id]: false,
            [defaultColumnsID.addColumn]: true,
          }));
          moveColumntoTheLast(header.column.id);
        },
      });
    }

    const moveActionsMenu = getActionMenuMoveColumn(header);

    const headerActionsMenu = [[...topActions]];

    if (moveActionsMenu) {
      headerActionsMenu.push(moveActionsMenu);
    }

    return headerActionsMenu;
  };

  return {
    table,
    columnOrder,
    handleDragEnd,
    handleDragStart,
    handleDragCancel,
    activeColumnIndex,
    getActiveColumnName,
    sensors,
    tableColor,
    isShowingShadow,
    getSylesVariants,
    moveColumnLeft,
    moveColumnRight,
    columnVisibility,
    moveColumntoTheLast,
    setColumnVisibility,
    getHeaderActionsMenu,
    sorting,
  };
};
