import { CheckIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { FC, MouseEventHandler, useState } from "react";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTrigger,
} from "~/components/v2/AltDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../DropdownMenu";
import { cn } from "~/utils/cn";

export interface Option {
  label: string;
  value: string;
}

export type OnSortChange = (state: string) => void;

interface SortDialogProps {
  onChange: OnSortChange;
  options: Option[];
  value: string;
  mobile?: boolean;
  hideIcon?: boolean;
}

const SortDialog: FC<SortDialogProps> = ({
  onChange,
  options,
  value,
  mobile,
  hideIcon,
}) => {
  const [open, setOpen] = useState(false);

  const handleChange = (newValue: string) => () => {
    onChange(newValue);
    setOpen(false);
  };

  const getLabel = () => {
    const selectedOption = options.find(option => option.value === value);
    return selectedOption?.label || "Sort by";
  };

  if (!mobile) {
    return (
      <DropdownMenu>
        <DropdownMenuTrigger
          className={cn(
            "pressed:border-borders-input flex items-center justify-center gap-1 whitespace-nowrap rounded-full px-3 py-1 text-sm font-medium leading-6 text-neutral-primary hover:bg-button-subtle-pressed focus:outline-none active:bg-background-pressed active:bg-button-subtle-pressed lg:h-8",
            { "bg-button-subtle-pressed": open },
          )}
        >
          {getLabel()}
          {!hideIcon && <i className="fa fa-chevron-down" aria-hidden="true" />}
        </DropdownMenuTrigger>
        <DropdownMenuContent
          align="end"
          sideOffset={0}
          className="rounded-xl border border-neutral-rest bg-neutral-00 p-2 shadow-sm"
        >
          {options.map(option => (
            <DropdownMenuItem
              key={option.value}
              onClick={handleChange(option.value)}
              className="w-48 text-neutral-primary hover:bg-button-subtle-hover"
            >
              {option.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="flex h-6 items-center justify-center gap-1 rounded-full border border-borders bg-white px-3 py-1 text-xs text-typography-low-contrast-text hover:bg-background-hover focus:outline-none active:border-borders-input active:bg-background-pressed ">
        {getLabel()}
        <i className="mi-caret-down text-xs" />
      </DialogTrigger>
      <DialogContent className="gap-0 p-2">
        <DialogHeader className="grid h-[3.25rem] grid-cols-3 items-center space-y-0 border-none px-5 py-3 text-xl font-semibold text-typography-high-contrast">
          <DialogClose className="h-6 w-6 justify-self-start">
            <ChevronLeftIcon className="h-6 w-6" />
          </DialogClose>
          <span className="text-center text-xl font-semibold text-typography-high-contrast">
            Sort by
          </span>
        </DialogHeader>
        <div className="flex flex-col overflow-auto pb-2">
          {options.map(option => (
            <Item
              key={option.value}
              {...option}
              selected={option.value === value}
              onClick={handleChange(option.value)}
            />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

interface ItemProps extends Option {
  selected: boolean;
  onClick: MouseEventHandler;
}

const Item: FC<ItemProps> = ({ label, selected, onClick }) => {
  return (
    <div
      className={`flex justify-between px-5 py-2 text-[#000D3D] hover:bg-[#F2F5FA] ${
        selected && "bg-[#FAFAFC]"
      }`}
      role="button"
      onClick={onClick}
    >
      {label}
      {selected && <CheckIcon className="h-6 w-6" />}
    </div>
  );
};

export default SortDialog;
