import { cn } from "~/utils/cn";
import { SectionContainerProps } from "./section-container.types";

export const SectionContainer: React.FC<SectionContainerProps> = ({
  title,
  children,
  className,
}) => {
  return (
    <div className={cn("mb-6", className)}>
      <h2 className="mb-4 text-base font-medium text-neutral-primary">
        {title}
      </h2>
      {children}
    </div>
  );
};
