import { DefaultMessages } from "@prisma/client";
import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";

export const MessagType = {
  ADVANCED: "ADVANCED",
  APPLY: "APPLY",
  INVITE: "INVITE",
  REJECT: "REJECT",
} as const;

export const fetchCompanyDefaultMessages = (
  { signal }: QueryFunctionContext,
  id: number,
  type?: string,
) =>
  axios
    .get<{ data: DefaultMessages[] }>(`/api/defaultMessages/company/${id}`, {
      params: { type },
      signal,
    })
    .then(({ data }) => data.data);
