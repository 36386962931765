import { ChevronDownIcon } from "@radix-ui/react-icons";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";

import { Combobox } from "~/components/v2/Combobox";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/v2/Form";

import { ScheduleInterviewContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/schedule-interview-context";
import {
  FIELD_NAME_INTERVIEW_TEAM,
  FORM_FIELDS,
} from "../../scheduler-link-form.constants";

export const InterviewersField = () => {
  const { control, setValue } = useFormContext();
  const { interviewers } = useContext(ScheduleInterviewContext);

  const { label, emptyMessage, placeholder, searchPlaceholder } =
    FORM_FIELDS[FIELD_NAME_INTERVIEW_TEAM];

  return (
    <FormField
      control={control}
      name={FIELD_NAME_INTERVIEW_TEAM}
      render={({ field }) => (
        <FormItem
          className="grid items-center w-full"
          data-testid="regardingcandidates-input"
        >
          <FormLabel className="text-sm font-semibold text-typography-high-contrast">
            {label}
          </FormLabel>
          <FormControl>
            <Combobox
              name={FIELD_NAME_INTERVIEW_TEAM}
              emptyTerm={emptyMessage}
              multi
              options={interviewers}
              searchByLabel
              placeholder={placeholder}
              searchPlaceholder={searchPlaceholder}
              setValue={setValue}
              value={field.value}
              viewType="list"
              dataType="candidate"
              className="h-8 rounded-lg border-borders-border"
              icon={
                <ChevronDownIcon className="w-4 h-4 text-icons-secondary" />
              }
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
