import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { deleteDetailsEntry } from "~/mutations/jobApplication/deleteDetailsEntry";

export const useDeleteDetailsEntry = (
  options?: UseMutationOptions<
    any,
    unknown,
    {
      id: number;
      index: number;
    },
    unknown
  >,
) => useMutation({ mutationFn: deleteDetailsEntry, ...options });
