import { FC, ReactNode } from "react";

export interface CandidateStatProps {
  title: string;
  value: ReactNode | string;
}

export const CandidateStat: FC<CandidateStatProps> = ({ title, value }) => {
  return (
    <div className="my-auto flex flex-col items-center gap-1">
      <span className="text-xs text-typography-low-contrast-text">{title}</span>
      <span className="font-semibold text-typography-high-contrast">
        {value}
      </span>
    </div>
  );
};
