import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { FC, InputHTMLAttributes, PropsWithChildren, forwardRef } from "react";
import { Control } from "react-hook-form";
import PhoneInputWithCountrySelect from "react-phone-number-input/react-hook-form";
import { cn } from "~/utils/cn";

const PhoneField = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    defaultValue?: string;
    name: string;
    control: Control<any>;
    error?: string;
    label?: string;
    containerClassName?: string;
    required?: boolean;
  }
>(({ error, label, onChange, onBlur, containerClassName, required, ...props }, ref) => {
  return (
    <div
      data-error={!!error}
      className={cn("group pt-0.5 flex flex-col gap-1", containerClassName)}
    >
      {label && <span className="text-sm font-semibold">{label}{required && '*'}</span>}
      <PhoneInputWithCountrySelect
        {...props}
        defaultCountry="US"
        containerComponent={Container}
        countrySelectComponent={CountrySelect}
        ref={ref}
        id={props.name}
        aria-label={props.placeholder}
      />
      {error && (
        <span className="flex items-center gap-1 text-xs text-error">
          <i className="mi-circle-error"></i>
          {error}
        </span>
      )}
    </div>
  );
});

const CountrySelect: FC<any> = ({
  options,
  onChange,
  value,
  iconComponent,
  ...props
}) => {
  return (
    <div className="relative w-[2.625rem] overflow-hidden text-dark-color-text-100">
      <select
        {...props}
        onChange={(e) => onChange(e.target.value)}
        className="opacity-0"
      >
        {options.map(({ value, label }: { value: string; label: string }) => (
          <option key={value || "ZZ"} value={value || "ZZ"}>
            {label}
          </option>
        ))}
      </select>
      <span className="pointer-events-none absolute top-0.5 flex w-full items-center gap-1">
        {value}
        <ChevronDownIcon className="h-4 w-4 text-dark-color-text-100" />
      </span>
    </div>
  );
};

const Container: FC<PropsWithChildren<{ error?: string }>> = ({
  children,
  error,
}) => {
  return (
    <div className="flex h-[2.625rem] text-sm gap-2 rounded-xl border border-borders-input px-3 py-2 group-data-[error=true]:border-error group-data-[error=true]:bg-[#FFF5F5] group-data-[error=true]:text-error [&_input]:w-full [&_input]:bg-transparent [&_input]:placeholder:text-white-85">
      {children}
    </div>
  );
};

PhoneField.displayName = "PhoneField";

export default PhoneField;
