import React from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { SelectField } from "~/components/v2/SelectField";
import { CustomMessage } from "~/scalis-components/pipeline";
import { SectionContainer } from "../../../section-container";
import {
  FIELD_NAME_ADDIONAL_DETAILS,
  FIELD_NAME_MEDALIST_STATUS,
  FIELD_NAME_REJECT_REASON,
  FORM_FIELDS,
} from "../../step-select-candidates.constants";
import {
  MEDALIST_STATUS_OPTIONS,
  REJECT_REASON_OPTIONS,
} from "./internal-notes-section.constants";
import { InternalNotesSectionProps } from "./internal-notes-section.types";

export const InternalNotesSection: React.FC<InternalNotesSectionProps> = ({
  showMedalistStatus,
}) => {
  const { watch, setValue, trigger } = useFormContext();
  const { errors } = useFormState();

  const rejectField = FORM_FIELDS[FIELD_NAME_REJECT_REASON];
  const medalistField = FORM_FIELDS[FIELD_NAME_MEDALIST_STATUS];
  const detailsField = FORM_FIELDS[FIELD_NAME_ADDIONAL_DETAILS];

  return (
    <SectionContainer title="Internal Notes">
      <div className="flex flex-col gap-4">
        <SelectField
          label={rejectField.label}
          aria-label={FIELD_NAME_REJECT_REASON}
          name={FIELD_NAME_REJECT_REASON}
          placeholder={rejectField.placeholder}
          searchPlaceholder={rejectField.searchPlaceholder}
          emptyTerm={rejectField.emptyTerm}
          options={REJECT_REASON_OPTIONS}
          className="w-full"
          setValue={setValue}
          value={watch(FIELD_NAME_REJECT_REASON)}
          error={errors[FIELD_NAME_REJECT_REASON]?.message as string}
          required={true}
        />

        {showMedalistStatus && (
          <SelectField
            label={medalistField.label}
            aria-label={FIELD_NAME_MEDALIST_STATUS}
            name={FIELD_NAME_MEDALIST_STATUS}
            placeholder={medalistField.placeholder}
            searchPlaceholder={medalistField.searchPlaceholder}
            emptyTerm={medalistField.emptyTerm}
            options={MEDALIST_STATUS_OPTIONS}
            className="w-full"
            setValue={setValue}
            value={watch(FIELD_NAME_MEDALIST_STATUS)}
            error={errors[FIELD_NAME_MEDALIST_STATUS]?.message as string}
            required={true}
          />
        )}

        <CustomMessage
          label={detailsField.label}
          onValueChange={setValue}
          triggerValidation={trigger}
          value={watch(FIELD_NAME_ADDIONAL_DETAILS)}
          placeholder={detailsField.placeholder}
          editorContentClassname="h-28"
          error={errors[FIELD_NAME_ADDIONAL_DETAILS]?.message as string}
          fieldName={FIELD_NAME_ADDIONAL_DETAILS}
        />
      </div>
    </SectionContainer>
  );
};
