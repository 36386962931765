import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { TextField } from "~/components/v2/TextField";
import { FormButtons } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/form-buttons";
import {
  DetailsEntry,
  DetailsEntryTypeEnum,
  ProfileEntrySchema as schema,
} from "~/services/jobSeekerApplications.types";

interface ProfileFormProps {
  onSubmit: (data: DetailsEntry) => Promise<void>;
  loading?: boolean;
  onCancel: () => void;
}

export const ProfileForm: React.FC<ProfileFormProps> = ({
  onSubmit,
  loading,
  onCancel,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: { type: DetailsEntryTypeEnum.Profile, link: "http://" },
  });

  const submit = (data: z.infer<typeof schema>) => {
    onSubmit(data).then(() => {
      reset();
    });
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex flex-1 items-start gap-4"
    >
      <TextField
        {...register("link")}
        placeholder="http://"
        autoFocus
        containerClassName="flex-1"
        error={errors.link?.message as string}
      />
      <FormButtons loading={loading} onCancel={onCancel} />
    </form>
  );
};
