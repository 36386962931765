export enum ScheduleTypeEnum {
  SCHEDULER_LINK = "SCHEDULER_LINK",
  MANUALLY_SCHEDULE = "MANUALLY_SCHEDULE",
}

export interface InterviewTypeSelectionDrawerProps {
  open: boolean;
  isCalendarConnected: boolean;
  onOpenChange: (open: boolean) => void;
  onSelect: (type: ScheduleTypeEnum) => void;
}
