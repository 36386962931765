import {
  ArrowRightIcon,
  CalendarIcon,
  ChatBubbleOvalLeftIcon,
  CheckIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { ActionBarItem } from "~/scalis-components/pipeline/list-view/candidate-action-bar/candidate-action-bar.types";
import { Candidate } from "~/scalis-components/pipeline/list-view/candidate-list-view";
import { TableColorTypes } from "~/components/v2/list-view/list-view.types";
import useAdvanceCandidate from "~/hooks/candidate/useAdvanceCandidate";
import {
  AdvanceProps,
  MILESTONE_TYPE,
  MatchTojobProps,
  RejectProps,
} from "./candidate-list-view-utils.types";
import { useRejectCandidate } from "~app/company/(dashboard)/pipeline/components/reject-candidates";

export const actionItemDetails = {
  chat: {
    label: "Chat",
  },
  makeOffer: {
    label: "Make offer",
  },
  advance: {
    label: "Advance",
  },
  scheduleInterview: {
    label: "Schedule Interview",
  },
  reject: {
    label: "Reject",
  },
  matchToJob: {
    label: "Match to job",
  },
  sendSchedulerLink: {
    label: "Send Scheduler Link",
  },
};

const isScalisProfile = (candidate: Candidate) => !!candidate.seekerId;

export const getRowActionsMenuItems = (props?: any) => {
  const action = useAdvanceCandidate();
  const { setPreviousSelectCandidates, setOpenDrawer: setOpenRejectDrawer } =
    useRejectCandidate();

  const chat = {
    label: actionItemDetails.chat.label,
    onClick: () => {},
    id: actionItemDetails.chat.label,
  };

  const makeOffer = {
    label: actionItemDetails.makeOffer.label,
    onClick: () => {},
    id: actionItemDetails.makeOffer.label,
  };

  const advance = {
    label: actionItemDetails.advance.label,
    onClick: ({
      name,
      applicationType,
      seekerId,
      uploadedCandidateId,
      applicationId,
      alreadyInvitedToJob,
      recentlyInvitedToAnotherJob,
    }: AdvanceProps) => {
      return action({
        candidateName: name,
        companyId: props?.companyId,
        isFromTalentPool: false,
        applicationType,
        jobSeekerId: seekerId,
        uploadedCandidateId,
        applicationId,
        stageId: props?.nextStageId ?? -1,
        jobNumber: props?.jobRequisitionNumber,
        jobTitle: props?.jobTitle,
        milestoneType: props?.milestoneType,
        alreadyInvitedToJob,
        recentlyInvitedToAnotherJob,
      })();
    },
    id: actionItemDetails.advance.label,
  };

  const scheduleInterview = {
    label: actionItemDetails.scheduleInterview.label,
    onClick: ({}) => {},
    id: actionItemDetails.scheduleInterview.label,
  };

  const reject = {
    label: actionItemDetails.reject.label,
    onClick: ({ name, email, applicationId }: RejectProps) => {
      setPreviousSelectCandidates([
        {
          id: applicationId,
          name,
          email,
        },
      ]);
      setOpenRejectDrawer(true);
    },
    id: actionItemDetails.reject.label,
  };

  const matchToJob = {
    label: actionItemDetails.matchToJob.label,
    onClick: ({
      name,
      applicationType,
      seekerId,
      uploadedCandidateId,
      applicationId,
      nextStageId,
      jobRequisitionNumber,
      jobTitle,
      milestoneType,
      alreadyInvitedToJob,
      recentlyInvitedToAnotherJob,
    }: MatchTojobProps) => {
      return action({
        candidateName: name,
        companyId: props?.companyId,
        isFromTalentPool: true,
        applicationType,
        jobSeekerId: seekerId,
        uploadedCandidateId,
        applicationId,
        stageId: nextStageId ?? -1,
        jobNumber: jobRequisitionNumber,
        jobTitle,
        milestoneType,
        alreadyInvitedToJob,
        recentlyInvitedToAnotherJob,
      })();
    },
    id: actionItemDetails.matchToJob.label,
  };

  return {
    chat,
    makeOffer,
    advance,
    reject,
    matchToJob,
    scheduleInterview,
  };
};

export const getRowActionsBarItems = (props?: any) => {
  const { matchToJob, chat, makeOffer, advance, reject, scheduleInterview } =
    getRowActionsMenuItems(props);

  const { setOpenDrawer: setOpenRejectDrawer } = useRejectCandidate();

  const chatBar = {
    ...chat,
    Icon: (props: { className: string; fontSize: number }) => (
      <ChatBubbleOvalLeftIcon {...props} />
    ),
    type: "tonal",
    render: {
      shouldAlwaysRender: false,
      renderif: isScalisProfile,
    },
  };

  const makeOfferBar = {
    ...makeOffer,
    render: {
      shouldAlwaysRender: true,
      renderif: () => true,
    },
  };

  const advanceBar = {
    ...advance,
    Icon: (props: { className: string; fontSize: number }) => (
      <ArrowRightIcon {...props} />
    ),
    type: "tonal",
    render: {
      shouldAlwaysRender: true,
      renderif: () => true,
    },
  };

  const scheduleInterviewBar = {
    ...scheduleInterview,
    Icon: (props: { className: string; fontSize: number }) => (
      <CalendarIcon {...props} />
    ),
    type: "tonal",
    render: {
      shouldAlwaysRender: true,
      renderif: () => true,
    },
  };

  const sendSchedulerLink = {
    label: actionItemDetails.sendSchedulerLink.label,
    onClick: () => {},
    id: actionItemDetails.sendSchedulerLink.label,
    Icon: (props: { className: string; fontSize: number }) => (
      <CalendarIcon {...props} />
    ),
    type: "tonal",
    render: {
      shouldAlwaysRender: true,
      renderif: () => true,
    },
  };

  const rejectBar = {
    ...reject,
    onClick: () => {
      setOpenRejectDrawer(true);
    },
    Icon: (props: { className: string; fontSize: number }) => (
      <XCircleIcon {...props} />
    ),
    type: "destructive",
    render: {
      shouldAlwaysRender: true,
      renderif: () => true,
    },
  };

  const matchToJobBar = {
    ...matchToJob,
    Icon: (props: { className: string; fontSize: number }) => (
      <CheckIcon {...props} />
    ),
    type: "tonal",
    render: {
      shouldAlwaysRender: true,
      renderif: () => true,
    },
  };

  return {
    chat: chatBar,
    makeOffer: makeOfferBar,
    advance: advanceBar,
    reject: rejectBar,
    matchToJob: matchToJobBar,
    scheduleInterview: scheduleInterviewBar,
    sendSchedulerLink,
  };
};

export const getMilestoneTypeProps = (props: any) => {
  switch (props.milestoneType) {
    case MILESTONE_TYPE.SOURCING: {
      const { advance, chat, reject } = getRowActionsMenuItems({
        ...props,
        isSourcingStage: true,
      });
      const {
        chat: chatAction,
        advance: advanceAction,
        reject: rejectAction,
      } = getRowActionsBarItems({ ...props, isSourcingStage: true });

      return {
        color: "01" as TableColorTypes,
        rowActionsMenuItems: [[chat, advance, reject]],
        actionsBarItems: {
          // single: [chatAction, advanceAction, rejectAction] as ActionBarItem[],
          // multiple: [rejectAction] as ActionBarItem[],
          single: [rejectAction] as ActionBarItem[],
          multiple: [rejectAction] as ActionBarItem[],
        },
      };
    }

    case MILESTONE_TYPE.APPLICATION_REVIEW: {
      const { advance, chat, reject, scheduleInterview } =
        getRowActionsMenuItems(props);
      const {
        chat: chatAction,
        advance: advanceAction,
        reject: rejectAction,
        scheduleInterview: scheduleInterviewAction,
        sendSchedulerLink,
      } = getRowActionsBarItems(props);

      return {
        color: "02" as TableColorTypes,
        rowActionsMenuItems: [[chat, scheduleInterview, advance, reject]],
        actionsBarItems: {
          // single: [
          //   chatAction,
          //   advanceAction,
          //   scheduleInterviewAction,
          //   rejectAction,
          // ] as ActionBarItem[],
          // multiple: [sendSchedulerLink, rejectAction] as ActionBarItem[],
          single: [rejectAction] as ActionBarItem[],
          multiple: [rejectAction] as ActionBarItem[],
        },
      };
    }

    case MILESTONE_TYPE.PRE_SCREEN: {
      const { advance, chat, reject, scheduleInterview } =
        getRowActionsMenuItems(props);
      const {
        chat: chatAction,
        advance: advanceAction,
        reject: rejectAction,
        scheduleInterview: scheduleInterviewAction,
        sendSchedulerLink,
      } = getRowActionsBarItems(props);

      return {
        color: "03" as TableColorTypes,
        rowActionsMenuItems: [[chat, advance, scheduleInterview, reject]],
        actionsBarItems: {
          // single: [
          //   chatAction,
          //   advanceAction,
          //   scheduleInterviewAction,
          //   rejectAction,
          // ] as ActionBarItem[],
          // multiple: [sendSchedulerLink, rejectAction] as ActionBarItem[],
          single: [rejectAction] as ActionBarItem[],
          multiple: [rejectAction] as ActionBarItem[],
        },
      };
    }

    case MILESTONE_TYPE.CUSTOM: {
      const { advance, chat, reject, scheduleInterview } =
        getRowActionsMenuItems(props);
      const {
        chat: chatAction,
        advance: advanceAction,
        reject: rejectAction,
        scheduleInterview: scheduleInterviewAction,
        sendSchedulerLink,
      } = getRowActionsBarItems(props);

      return {
        color: "04" as TableColorTypes,
        rowActionsMenuItems: [[chat, advance, scheduleInterview, reject]],
        actionsBarItems: {
          // single: [
          //   chatAction,
          //   advanceAction,
          //   scheduleInterviewAction,
          //   rejectAction,
          // ] as ActionBarItem[],
          // multiple: [sendSchedulerLink, rejectAction] as ActionBarItem[],
          single: [rejectAction] as ActionBarItem[],
          multiple: [rejectAction] as ActionBarItem[],
        },
      };
    }

    case MILESTONE_TYPE.OFFER: {
      const { advance, chat, reject, scheduleInterview } =
        getRowActionsMenuItems(props);
      const {
        chat: chatAction,
        advance: advanceAction,
        reject: rejectAction,
        scheduleInterview: scheduleInterviewAction,
        sendSchedulerLink,
      } = getRowActionsBarItems(props);

      return {
        color: "05" as TableColorTypes,
        rowActionsMenuItems: [[chat, advance, scheduleInterview, reject]],
        actionsBarItems: {
          // single: [
          //   chatAction,
          //   advanceAction,
          //   scheduleInterviewAction,
          //   rejectAction,
          // ] as ActionBarItem[],
          // multiple: [sendSchedulerLink, rejectAction] as ActionBarItem[],
          single: [rejectAction] as ActionBarItem[],
          multiple: [rejectAction] as ActionBarItem[],
        },
      };
    }

    case MILESTONE_TYPE.HIRED: {
      const { chat } = getRowActionsMenuItems(props);
      const { chat: chatAction } = getRowActionsBarItems();

      return {
        color: "06" as TableColorTypes,
        rowActionsMenuItems: [[chat]],
        actionsBarItems: {
          single: [chatAction] as ActionBarItem[],
          multiple: [] as ActionBarItem[],
        },
      };
    }

    default: {
      throw new Error(`Invalid milestone type: ${props.milestoneType}`);
    }
  }
};

export const getTitle = (milestoneName: string, stageName: string) => {
  if (milestoneName === stageName) {
    return milestoneName;
  }

  return (
    <>
      {milestoneName} <span className="text-xs">({stageName})</span>
    </>
  );
};
