import { ApplicationType, MilestoneType } from "@prisma/client";
import { QueryFunctionContext } from "@tanstack/react-query";
import qs from "qs";
import axios from "~/services/axios.service";
import { Notes, Skill } from "~/types/pipeline";

export interface Experience {
  title: string;
  company: string;
  level: string;
}

export interface Candidate {
  id: number;
  seekerId: number;
  uploadedCandidateId: null;
  applicationId: number;
  name: string;
  email: string;
  phone: string;
  role: string;
  appliedAt: string;
  source: string;
  sourceName?: string;
  hasInvite: boolean;
  applicationType: ApplicationType | string;
  alreadyInvitedToJob: boolean;
  recentlyInvitedToAnotherJob: boolean;
  matchScore: number;
  currentJob: string;
  currentCompany: string;
  currentIndustry: string;
  currentLocation: string;
  educationLevel: string;
  educationInstitution: string;
  photo?: string;
  lastUpdatedStatusAt?: string;
  experience?: Experience[];
  candidateNotes?: Notes[];
  skillsMatched?: { total: number; matched: number };
  skills?: Skill[];
  headline?: string;
  status?: string;
}

export interface Stage {
  stageId: number;
  name: string;
  visibility: string;
  count: number;
  candidates: Candidate[];
}

export interface Milestone {
  milestoneId: number;
  name: string;
  milestoneType: MilestoneType;
  color: string;
  stages: Stage[];
}

export interface CandidateListViewReturn {
  jobId: number;
  jobReqNumber: number;
  jobName: string;
  milestones: Milestone[];
}

export interface QueryParams {
  stageId?: number;
  page?: number;
  quantity?: number;
}

interface FetchCandidateListViewParams {
  jobId: number;
  queryParams?: QueryParams;
}

export const fetchCandidateListView = (
  { signal }: QueryFunctionContext,
  { jobId, queryParams }: FetchCandidateListViewParams,
): Promise<CandidateListViewReturn> =>
  axios
    .get<{ data: { result: CandidateListViewReturn } }>(
      `/api/pipeline/${jobId}/list-view`,
      {
        signal,
        params: queryParams,
        paramsSerializer: params => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      },
    )
    .then(({ data }) => data as unknown as CandidateListViewReturn);
