export enum ScheduleOptionEnum {
  ScheduleLater = 0,
  SendSchedulerLink = 1,
}

export const SCHEDULE_VALUES = ["LATER", "SCHEDULER"] as const;

export const SCHEDULE_OPTIONS = {
  SCHEDULE_LATER: "Schedule later",
  SEND_SCHEDULER_LINK: "Send scheduler link",
} as const;

export const FIELD_NAME_SCHEDULE_RADIO = "interview_schedule";
export const LABEL_SCHEDULE_RADIO = "Interview scheduling";
