import React from "react";
import { SelectField } from "~/components/v2/SelectField";
import { FIELD_NAME_DEFAULT_MESSAGE, LABEL_SELECT_DEFAULT_MESSAGE } from "../../employer-message.constants";
import { SelectDefaultMessageProps } from "./select-default-message.types";

export const SelectDefaultMessage: React.FC<SelectDefaultMessageProps> = ({
  defaultMessages,
  onValueChange,
  value,
  error,
}) => {

  return (
    <SelectField
      label={LABEL_SELECT_DEFAULT_MESSAGE}
      name={FIELD_NAME_DEFAULT_MESSAGE}
      placeholder="Select"
      searchPlaceholder="Search..."
      emptyTerm="default message"
      options={defaultMessages.map((message) => ({
        label: message.title ?? "",
        value: `${message.id}`,
      }))}
      className="w-full"
      setValue={onValueChange}
      value={value}
      error={error}
      required={true}
    />
  );
};
