import { Stage } from "@prisma/client";
import { CheckedState } from "@radix-ui/react-checkbox";
import { z } from "zod";
import { FIELD_NAME_INTERVIEWER_SELECTION } from "../interviewer-selection/interviewer-selection.constants";
import {
  FIELD_NAME_SCHEDULE_RADIO,
  ScheduleOptionEnum,
} from "../schedule-radio/schedule-radio.constants";
import { FIELD_NAME_STAGE_SELECT } from "../stage-select/stage-select.constants";
import {
  FIELD_NAME_CUSTOM_MESSAGE,
  FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE,
  FIELD_NAME_DEFAULT_MESSAGE,
  FIELD_NAME_NOTIFICATION_RADIO,
} from "~/scalis-components/pipeline/candidate-drawer";
export interface AdvanceCandidateFormProps {
  isSubmit?: boolean;
  onSubmit?: (data: FormValues) => void;
  onErrorSubmit?: (error: any) => void;
  stages?: Stage[];
}
export interface FormValues {
  stageId?: string;
  default_message?: string;
  cb_default_message?: CheckedState;
  custom_message?: string;
  interview_schedule?: string;
  interviewer?: Map<number, string>;
  should_notify_candidate?: string;
}

export const createSchema = (
  mustNotifyCandidate?: boolean,
  shouldNotifyCandidate?: boolean,
) => {
  return z
    .object({
      [FIELD_NAME_STAGE_SELECT]: z.string({
        required_error: "Stage is required",
      }),
      [FIELD_NAME_NOTIFICATION_RADIO]: z.string().optional(),
      [FIELD_NAME_SCHEDULE_RADIO]: z.string().optional(),
      [FIELD_NAME_CUSTOM_MESSAGE]: z.string().optional(),
      [FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE]: z.boolean().optional(),
      [FIELD_NAME_DEFAULT_MESSAGE]: z.string().optional(),
      [FIELD_NAME_INTERVIEWER_SELECTION]: z
        .map(z.number(), z.string())
        .optional(),
    })
    .superRefine(
      (
        {
          cb_default_message,
          default_message,
          custom_message,
          interview_schedule,
          interviewer,
        }: any,
        ctx: {
          addIssue: (arg0: {
            code: "custom";
            message: string;
            path: string[];
          }) => any;
        },
      ) => {
        if (!mustNotifyCandidate && !shouldNotifyCandidate) return;

        if (!cb_default_message) {
          const parsedMessage = custom_message?.replace(/<[^>]*>/g, "");
          if (!parsedMessage) {
            return ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Custom message is required",
              path: [FIELD_NAME_CUSTOM_MESSAGE],
            });
          }
        }
        if (cb_default_message && !default_message) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Default message is required",
            path: [FIELD_NAME_DEFAULT_MESSAGE],
          });
        }
        if (
          interview_schedule ===
            ScheduleOptionEnum.SendSchedulerLink.toString() &&
          !interviewer?.size
        ) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "At least one interviewer is required",
            path: [FIELD_NAME_INTERVIEWER_SELECTION],
          });
        }
      },
    );
};
