import { DefaultMessages } from "@prisma/client";
import { getDefaultMessage } from "~/scalis-components/pipeline";
import { InterviewSchedule } from "~/types/applications/interview-schedule.enum";
import { FormValues } from "./components/advance-candidate-form/advance-candidate-form.types";
import { SCHEDULE_VALUES } from "./components/schedule-radio/schedule-radio.constants";

export const parseFormData = (
  data: FormValues,
  applicationId: number,
  defaultMessages?: Partial<DefaultMessages>[],
) => {
  const defaultMessage = getDefaultMessage(
    defaultMessages || [],
    data.default_message ?? "0",
  );
  const message = defaultMessage ?? data.custom_message;
  const parsedMessage = message?.replace(/<[^>]*>/g, "");
  const interviewers = Array.from(data.interviewer?.values() || []).map(Number);
  const body = {
    stageId: parseInt(data.stageId || "0"),
    applicationId: applicationId || 0,
    ...(parsedMessage && {
      message,
      interviewSchedule: SCHEDULE_VALUES[
        parseInt(data?.interview_schedule || "0")
      ] as InterviewSchedule,
      interviewers,
    }),
  };
  return body;
};
