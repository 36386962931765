import { MilestoneType, Visibility } from "@prisma/client";

import { InterviewCandidate } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/schedule-interview-context.types";

import {
  Application,
  InterviewEvent,
  InterviewEventWithStage,
} from "./schedule-interview.types";

export const MOCK_CANDIDATE = {
  id: 1,
  type: "JOBSEEKER",
  name: "John Doe",
  email: "john.doe@example.com",
} as InterviewCandidate;

export const MOCK_STAGE: Application["stage"] = {
  id: 117,
  name: "Technical Interview",
  visibility: "PUBLIC" as Visibility,
  sequence: 1,
  milestoneId: 160,
  createdAt: new Date(),
  updatedAt: new Date(),
  milestone: {
    id: 160,
    name: "Interview(s)",
    sequence: 4,
    archived: false,
    pipelineId: 1,
    createdAt: new Date(),
    updatedAt: new Date(),
    milestoneType: "CUSTOM" as MilestoneType,
  },
  interview: {} as never,
};

export const MOCK_INTERVIEWERS = [
  {
    id: 1,
    name: "Jane Smith",
    email: "jane@example.com",
  },
  {
    id: 2,
    name: "Bob Johnson",
    email: "bob@example.com",
  },
];

export const MOCK_INTERVIEW: InterviewEvent = {
  id: 1,
  interviewId: 1,
  status: "Scheduled",
  title: "Mock Interview",
  startTime: "2022-01-01T09:00:00",
  endTime: "2022-01-01T10:00:00",
  timezone: "UTC",
  location: "Virtual",
  notes: "Please be prepared for technical questions.",
  user: {
    id: 1,
    full_name: "John Doe",
  },
  nylasCalendar: {
    name: "Interview Calendar",
    calendar_id: "calendar123",
  },
  internalHrAssociates: MOCK_INTERVIEWERS,
};

export const MOCK_INTERVIEW_WITH_STAGE: InterviewEventWithStage = {
  ...MOCK_INTERVIEW,
  stage: MOCK_STAGE,
};
