import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import {
  SchedulerInviteProps,
  UpdateSchedulerInviteProps,
  updateSchedulerInvite,
  createSchedulerInvite,
  DeleteSchedulerInviteProps,
  deleteSchedulerInvite,
  ResendSchedulerInviteProps,
  resendSchedulerInvite,
} from "~/mutations/scheduler/invite";

const useCreateSchedulerInvite = (
  options: UseMutationOptions<any, unknown, SchedulerInviteProps, unknown>,
) => useMutation({ mutationFn: createSchedulerInvite, ...options });

const useUpdateSchedulerInvite = (
  options: UseMutationOptions<
    any,
    unknown,
    UpdateSchedulerInviteProps,
    unknown
  >,
) => useMutation({ mutationFn: updateSchedulerInvite, ...options });

const useDeleteSchedulerInvite = (
  options: UseMutationOptions<
    any,
    unknown,
    DeleteSchedulerInviteProps,
    unknown
  >,
) => useMutation({ mutationFn: deleteSchedulerInvite, ...options });

const useResendSchedulerInvite = (
  options: UseMutationOptions<
    any,
    unknown,
    ResendSchedulerInviteProps,
    unknown
  >,
) => useMutation({ mutationFn: resendSchedulerInvite, ...options });

export {
  useCreateSchedulerInvite,
  useUpdateSchedulerInvite,
  useDeleteSchedulerInvite,
  useResendSchedulerInvite,
};
