"use client";

import { Button } from "~/components/v2/Button";
import { QuickFilters } from "./components/quick-filters";
import { useFormState } from "react-hook-form";
import { useCandidateFilters } from "../../context";

export const FiltersBar: React.FC = () => {
  const { setIsFilterDrawerOpen } = useCandidateFilters();
  const { dirtyFields } = useFormState();

  const activeFiltersNumber = Object.keys(dirtyFields).length;

  return (
    <div className="relative z-[100] flex justify-between rounded-xl bg-white p-2">
      <div className="flex items-center gap-2">
        <i className="fa-regular fa-filter p-1 text-typography-high-contrast" />
        {/* <SearchInput {...register(FIELD_NAME_SEARCH)} /> */}
        <QuickFilters />
      </div>
      <div>
        <Button variant="ghost" onClick={() => setIsFilterDrawerOpen(true)}>
          Advanced Filters{" "}
          {activeFiltersNumber > 0 && `(${activeFiltersNumber})`}
        </Button>
      </div>
    </div>
  );
};
