import { useFormContext } from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/v2/Form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/v2/Select";
import {
  FIELD_NAME_INTERVIEW_LENGHT,
  FORM_FIELDS,
} from "../../scheduler-link-form.constants";
import { INTERVIEW_LENGTH } from "./interview-length-field.utils";

export const InterviewLengthField = () => {
  const { control } = useFormContext();

  const { label, placeholder } = FORM_FIELDS[FIELD_NAME_INTERVIEW_LENGHT];

  return (
    <FormField
      control={control}
      name={FIELD_NAME_INTERVIEW_LENGHT}
      render={({ field }) => (
        <FormItem className="space-y-1" data-testid="selectCalendar">
          <FormLabel className="text-sm font-semibold text-typography-high-contrast">
            {label}
          </FormLabel>
          <Select onValueChange={field.onChange} defaultValue={"30"}>
            <FormControl>
              <SelectTrigger className="w-full h-8 rounded-lg">
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent className="bg-white">
              {INTERVIEW_LENGTH?.map(item => (
                <SelectItem value={item.value} key={item.value}>
                  {item.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
