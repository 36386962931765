"use client";

import {
  FIELD_NAME_LAST_ACTIVITY_DATE,
  FORM_FIELDS,
  FIELD_NAME_CANDIDATE_NAME,
  FIELD_NAME_SKILLS,
} from "~/scalis-components/pipeline/list-view/candidate-filters/candidate-flters.contants";
import { DateFilter, MatchScoreFilter, SelectFilter } from "../filters";
import { useCandidateFilters } from "~/scalis-components/pipeline/list-view/candidate-filters/context";

export const QuickFilters: React.FC = () => {
  const { onUpdateField, filterOptions } = useCandidateFilters();

  return (
    <>
      <MatchScoreFilter onApplyFilters={onUpdateField} />
      <SelectFilter
        name={FIELD_NAME_CANDIDATE_NAME}
        label={FORM_FIELDS[FIELD_NAME_CANDIDATE_NAME].label}
        onApplyFilters={onUpdateField}
        options={filterOptions[FIELD_NAME_CANDIDATE_NAME]}
      />
      <SelectFilter
        name={FIELD_NAME_SKILLS}
        label={FORM_FIELDS[FIELD_NAME_SKILLS].label}
        onApplyFilters={onUpdateField}
        options={filterOptions[FIELD_NAME_SKILLS]}
      />
      <DateFilter
        name={FIELD_NAME_LAST_ACTIVITY_DATE}
        label={FORM_FIELDS[FIELD_NAME_LAST_ACTIVITY_DATE].label}
        mode="range"
        onApplyFilters={onUpdateField}
      />
    </>
  );
};
