import { ScalisCandidate } from "~/types/pipeline";
import { SHOW_EXPERIENCES } from "./experiences.constants";
import React from "react";
import { cn } from "~/utils/cn";
import { Divider } from "~/components/v2/Divider";
import { Experience } from "./experience";
import { Candidate } from "~/queries/fetch-candidate-list-view";

export const Experiences: React.FC<Pick<Candidate, "experience">> = ({
    experience = [],
}) => {
    const [slice, setSlice] = React.useState(SHOW_EXPERIENCES);
    const toggleMore = () => {
        setSlice((current) =>
            current === SHOW_EXPERIENCES ? experience?.length : SHOW_EXPERIENCES,
        );
    };
    return (
        <div
            className={cn("flex w-full flex-col gap-2 text-sm", {
                hidden: !experience.length
            })}
        >
            <Divider />
            <h1>Experience</h1>
            <div>
                <div className="transition-all">
                    {experience.map((xp, i) => (
                        <Experience key={xp.title} {...xp} hidden={i >= slice} />
                    ))}
                </div>
                <div
                    className={cn("cursor-pointer text-xs font-semibold", {
                        hidden: experience.length <= SHOW_EXPERIENCES,
                    })}
                    onClick={toggleMore}
                >
                    see{" "}
                    {slice === SHOW_EXPERIENCES
                        ? `${experience.length - SHOW_EXPERIENCES} more`
                        : "less"}
                </div>
            </div>
        </div>
    );
};
