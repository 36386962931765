import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from "~/components/v2/DropdownMenu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/v2/Tooltip";
import { ActionsMenuProps, AlignMenu } from "./actions-menu.types";
import { ActionsMenuItems } from "../../list-view.types";
import React from "react";

export const ActionsMenu: React.FC<ActionsMenuProps> = ({
  Icon,
  actionsMenuItems,
  alignMenu = AlignMenu.start,
  tooltipText = "",
  row,
}) => {
  const GroupMenuItems = ({ groupItem }: { groupItem: ActionsMenuItems[] }) => {
    return (
      <DropdownMenuGroup>
        {groupItem.map(item => (
          <DropdownMenuItem
            key={item.label}
            className="group flex h-9 gap-2 px-4 py-2 hover:cursor-pointer hover:bg-background-hover"
            onClick={event => {
              !!item.onClick && item.onClick(row?.original ?? event);
            }}
          >
            <p className="text-sm font-normal text-typography-high-contrast">
              {item.label}
            </p>
          </DropdownMenuItem>
        ))}
      </DropdownMenuGroup>
    );
  };

  return (
    <div className="flex w-full items-center justify-center">
      <DropdownMenu modal={false}>
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <DropdownMenuTrigger className="rounded-full p-1 hover:bg-bluegray-40 focus:outline-none data-[state=open]:bg-bluegray-50">
                <Icon />
              </DropdownMenuTrigger>
            </TooltipTrigger>
            {!!tooltipText && (
              <TooltipContent>
                <p className="z-50">{tooltipText}</p>
              </TooltipContent>
            )}
          </Tooltip>
        </TooltipProvider>

        <DropdownMenuContent
          align={alignMenu}
          className="w-40 rounded-xl bg-white p-0 text-black shadow-sm"
        >
          {actionsMenuItems.map((groupItem, index) => {
            return (
              <React.Fragment key={`action-group-${index}`}>
                {index > 0 && (
                  <DropdownMenuSeparator className="mx-3 bg-borders-borders" />
                )}
                <GroupMenuItems groupItem={groupItem} />
              </React.Fragment>
            );
          })}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};
