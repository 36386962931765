import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { advanceUploadedCandidate } from "~/mutations/candidate/advanceUploadedCandidate";

export const useAdvanceUploadedCandidate = (
  options: UseMutationOptions<
    any,
    unknown,
    { applicationId: number; stageId: number },
    unknown
  >,
) => useMutation({ mutationFn: advanceUploadedCandidate, ...options });
