import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useState } from "react";
import { Button } from "~/components/v2/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/v2/Drawer";
import { RadioGroup, RadioGroupItem } from "~/components/v2/RadioGroup";
import { useAdvanceUploadedCandidate } from "~/hooks/candidate/useAdvanceUploadedCandidate";
import { useToast } from "~/hooks/useToast";
import { CandidateDrawerNote } from "~/scalis-components/pipeline";
import { errorHandler } from "~/utils/error";
import { InviteCandidateDrawerForm } from "./invite-candidate-drawer-form";
import {
  ACTION_ADVANCE,
  ACTION_INVITE,
  NOTE,
} from "./invite-candidate-drawer.constants";
import { InviteUploadedCandidateDrawerProps } from "./invite-candidate-drawer.types";

export const InviteUploadedCandidateDrawer = ({
  open,
  onOpenChange,
  companyId,
  applicationId,
  candidateName,
  stageId,
  alreadyInvitedToJob,
  jobNumber,
  jobTitle,
  recentlyInvitedToAnotherJob,
}: InviteUploadedCandidateDrawerProps) => {
  const [action, setAction] = useState(ACTION_INVITE);
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { mutate, isPending: isLoading } = useAdvanceUploadedCandidate({
    onSuccess: () => {
      onOpenChange(false);
      setAction(ACTION_INVITE);
      queryClient.invalidateQueries({ queryKey: ["milestones"] });
    },
    onError: e => {
      errorHandler(toast, e as AxiosError<any>);
    },
  });

  const handleSubmit = () => {
    if (action === ACTION_INVITE) {
      return;
    }

    mutate({ applicationId, stageId });
  };

  const handleChange = (value: string) => {
    setAction(value);
  };

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent asChild>
        <div>
          <DrawerHeader className="text-center">
            <h1 className="text-xl font-semibold text-typography-high-contrast">
              Advance Candidate
            </h1>
            <h2 className="text-sm text-typography-low-contrast">
              {candidateName}
            </h2>
          </DrawerHeader>
          <div className="flex flex-col gap-6 px-6 pt-4">
            <RadioGroup value={action} onValueChange={handleChange}>
              <div className="flex items-center space-x-2">
                <div className="w-4">
                  <RadioGroupItem value="advance" id="advance" />
                </div>
                <label className="text-sm" htmlFor="advance">
                  Advance candidate without SCALIS profile (candidate will not
                  receive email notification)
                </label>
              </div>
              <div className="flex items-center space-x-2">
                <div className="w-4">
                  <RadioGroupItem value={ACTION_INVITE} id={ACTION_INVITE} />
                </div>
                <label className="text-sm" htmlFor={ACTION_INVITE}>
                  Invite candidate to create a SCALIS profile and apply
                </label>
              </div>
            </RadioGroup>
            {action === ACTION_ADVANCE && <CandidateDrawerNote note={NOTE} />}
          </div>
          {action === ACTION_INVITE && (
            <InviteCandidateDrawerForm
              companyId={companyId}
              applicationId={applicationId}
              onOpenChange={onOpenChange}
              alreadyInvitedToJob={alreadyInvitedToJob}
              jobNumber={jobNumber}
              jobTitle={jobTitle}
              recentlyInvitedToAnotherJob={recentlyInvitedToAnotherJob}
              hideTitle
            />
          )}
          {action === ACTION_ADVANCE && (
            <DrawerFooter className="justify-end">
              <DrawerClose asChild>
                <Button variant="outline">Cancel</Button>
              </DrawerClose>
              <Button
                disabled={isLoading}
                className="min-w-20"
                isLoading={isLoading}
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </DrawerFooter>
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
};
export default InviteUploadedCandidateDrawer;
