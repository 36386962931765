import { z } from "zod";
import { errorMessage } from "~/utils/errorMessage";
import * as formConst from "./scheduler-link-form.constants";

const { FORM_FIELDS } = formConst;

export const SchedulerLinkFormSchema = z.object({
  [formConst.FIELD_NAME_CALENDAR]: z.string({
    required_error: errorMessage("calendar"),
  }),
  [formConst.FIELD_NAME_CANDIDATES]: z
    .array(
      z.object({
        id: z.number(),
        name: z.string().optional(),
        email: z.string().email({ message: "Invalid email" }),
        type: z.string().optional(),
      }),
    )
    .min(1, FORM_FIELDS[formConst.FIELD_NAME_CANDIDATES].errorMessage),
  [formConst.FIELD_NAME_INTERVIEW_TEAM]: z
    .array(
      z.string({
        message: errorMessage("interviewers"),
        invalid_type_error: errorMessage("interviewers"),
      }),
    )
    .nonempty({
      message: errorMessage("interviewers"),
    })
    .min(1, {
      message: errorMessage("interviewers"),
    }),
  [formConst.FIELD_NAME_INTERVIEW_LENGHT]: z
    .string({
      required_error: errorMessage("interview length"),
    })
    .min(1, errorMessage("interview length")),
  [formConst.FIELD_NAME_CUSTOM_MESSAGE]: z.string().optional(),
  [formConst.FIELD_NAME_HAS_DEFAULT_MESSAGE]: z.boolean().optional(),
  [formConst.FIELD_NAME_MESSAGE]: z.string().optional(),
});
