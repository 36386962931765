import { User } from "@prisma/client";
import { useQuery } from "@tanstack/react-query";
import {
  FetchBusinessUsersParams,
  fetchBusinessUsers,
} from "~/queries/businessUsers/fetchBusinessUsers";

export const useGetBusinessJobUsers = (
  queryParams?: FetchBusinessUsersParams,
  enabled?: boolean,
) =>
  useQuery<User[]>({
    queryKey: ["business-users", ...Object.values(queryParams ?? {})],
    queryFn: (params) => fetchBusinessUsers(params, queryParams),
    enabled,
  });
