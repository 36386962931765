import React from "react";
import { SelectField } from "~/components/v2/SelectField";
import { AdvanceCandidateDrawerContext } from "../advance-candidate-drawer-context";
import { FIELD_NAME_STAGE_SELECT, LABEL_STAGE_SELECT } from "./stage-select.constants";
import { StageSelectProps } from "./stage-select.types";

export const StageSelect: React.FC<StageSelectProps> = ({
  onValueChange,
  value,
  error,
  ...props
}) => {
  const { setStageVisibility, stages, stageId: droppedStageId } = React.useContext(AdvanceCandidateDrawerContext);
  const nextStages = (stages || props.stages)?.slice(1);
  const defaultValue = droppedStageId?.toString() || nextStages?.[0]?.id?.toString() || "";

  React.useEffect(() => {
    onValueChange?.(FIELD_NAME_STAGE_SELECT, defaultValue);
  }, []);

  React.useEffect(() => {
    const selectedStage = nextStages?.find((stage) => stage?.id?.toString() === value);
    if (selectedStage?.visibility) {
      setStageVisibility?.(selectedStage?.visibility);
    }
  }, [nextStages, value]);

  return (
    <SelectField
      label={LABEL_STAGE_SELECT}
      aria-label={LABEL_STAGE_SELECT}
      name={FIELD_NAME_STAGE_SELECT}
      placeholder="Select"
      searchPlaceholder="Search..."
      emptyTerm="stage"
      options={nextStages?.map((stage) => ({
        label: stage.name || "",
        value: stage.id?.toString() || "",
      })) || []}
      className="w-full"
      setValue={onValueChange}
      value={value || defaultValue}
      error={error}
      required={true}
    />
  );
};
