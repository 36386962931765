import { useQuery } from "@tanstack/react-query";
import { fetchCandidateApplication } from "~/queries/applications/fetchCandiidateApplication";

export const useGetCandidateApplication = (applicationId: number) => {
  return useQuery({
    queryKey: ["candidate-application", applicationId],
    queryFn: params => fetchCandidateApplication(params, applicationId),
    enabled: !!applicationId,
  });
};
