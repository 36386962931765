import { useS3Upload } from "next-s3-upload";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useCreateFile } from "./jobseeker/files/useCreateFile";

interface Props {
  applicationId?: number;
  employerId?: number;
  uploader: "jobseeker" | "employer";
}

const useDocumentUpload = ({ applicationId, uploader, employerId }: Props) => {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();

  const { mutateAsync, isPending: isLoading } = useCreateFile();
  const { uploadToS3 } = useS3Upload();

  const asPath = `${pathname}?${searchParams?.toString()}`;

  const handleUpload = (file: File | FileList | null) => {
    if (!file) return;
    mutateAsync({
      file: file as File,
      applicationId,
      uploadToS3,
      uploader,
      empId: employerId,
    }).then(() => {
      router.replace(asPath);
    });
  };

  return { handleUpload, loading: isLoading };
};

export default useDocumentUpload;
