import { CheckIcon } from "@radix-ui/react-icons";
import { HTMLProps, useRef } from "react";

interface ListViewCheckbox extends HTMLProps<HTMLInputElement> {
  indeterminate?: boolean;
}

export const ListViewCheckbox: React.FC<ListViewCheckbox> = ({
  indeterminate,
  className = "",
  ...rest
}) => {
  const ref = useRef<HTMLInputElement>(null!);

  return (
    <div className="relative left-[2px] flex items-center justify-center">
      <input
        type="checkbox"
        ref={ref}
        {...rest}
        className="peer relative h-4 w-4 shrink-0 appearance-none rounded border border-solid border-actions-secondary checked:bg-actions-secondary"
      />
      <CheckIcon className="pointer-events-none absolute h-4 w-4 stroke-transparent text-transparent peer-checked:!text-white" />
    </div>
  );
};
