import { Header } from "@tanstack/react-table";
import { cn } from "~/utils/cn";

interface ResizeIconProps {
  header: Header<any, unknown>;
  isSorting: boolean;
}

export const ResizeIcon: React.FC<ResizeIconProps> = ({
  header,
  isSorting,
}) => {
  return (
    <div
      {...{
        onDoubleClick: () => header.column.resetSize(),
        onMouseDown: header.getResizeHandler(),
        onTouchStart: header.getResizeHandler(),
      }}
      className={cn(
        "z-60 border-borders-border absolute right-0 top-0 h-full w-1 cursor-col-resize touch-none  select-none border border-solid bg-transparent",
        isSorting && "invisible",
      )}
    />
  );
};
