import { intervalToDuration } from "date-fns";

export const formatTime = (lastUpdatedStatusAt?: string): string => {
  if (!lastUpdatedStatusAt) {
    return "";
  }
  const duration = intervalToDuration({
    start: new Date(lastUpdatedStatusAt),
    end: new Date(),
  });

  for (const [unit, name] of [
    ["years", "year"],
    ["months", "month"],
    ["days", "day"],
    ["hours", "hour"],
    ["minutes", "minute"],
  ]) {
    const value = duration[unit as keyof typeof duration] ?? 0;
    if (value) {
      if (unit === "days" && value >= 7) {
        const weeks = Math.floor(value / 7);
        return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
      }
      return `${value} ${name}${value > 1 ? "s" : ""} ago`;
    }
  }

  return "Just now";
};
