"use client";

import { PlusCircleIcon } from "@heroicons/react/24/outline";
import { useQueryClient } from "@tanstack/react-query";
import React, { useMemo } from "react";

import { Button } from "~/components/v2/Button";
import { useCreateDetailsEntry } from "~/hooks/applications/useCreateDetailsEntry";
import { useToast } from "~/hooks/useToast";
import { EmailForm } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/forms/email";
import { LocationForm } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/forms/location";
import { MedalistForm } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/forms/medalist";
import { PhoneForm } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/forms/phone";
import { ProfileForm } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/forms/profile";
import { SourceForm } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/forms/source";
import { TimezoneForm } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/forms/timezone";
import { SectionItem } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/section-item";
import {
  DetailsEntry,
  DetailsEntryType,
  DetailsEntryTypeEnum,
} from "~/services/jobSeekerApplications.types";

interface Props {
  title: string;
  icon: React.ReactNode;
  items?: { value: string; index: number }[];
  subTitle?: string;
  type: DetailsEntryType;
  applicationId: number;
  companyId?: number | null;
  seekerId?: number | null;
}

const formMap = {
  [DetailsEntryTypeEnum.Email]: EmailForm,
  [DetailsEntryTypeEnum.Phone]: PhoneForm,
  [DetailsEntryTypeEnum.Location]: LocationForm,
  [DetailsEntryTypeEnum.Profile]: ProfileForm,
  [DetailsEntryTypeEnum.Source]: SourceForm,
  [DetailsEntryTypeEnum.MedalistDesignation]: MedalistForm,
  [DetailsEntryTypeEnum.Timezone]: TimezoneForm,
};

export const Section: React.FC<Props> = ({
  title,
  icon,
  items: [first, ...items] = [],
  subTitle,
  type,
  companyId,
  seekerId,
  applicationId,
}) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const [adding, setAdding] = React.useState(false);

  const { mutateAsync, isPending: isLoading } = useCreateDetailsEntry({
    onSuccess: () => {
      setAdding(false);
      queryClient.invalidateQueries({
        queryKey: ["candidate-application", applicationId],
      });
    },
    onError: error => {
      toast({
        description: (error as any)?.message ?? "Unexpected error ocurred",
        title: "Error",
        variant: "destructive",
      });
    },
  });

  const handleSubmit = async (data: DetailsEntry) => {
    return mutateAsync({ id: applicationId, data }).catch(() => {});
  };

  const handleClick = () => {
    setAdding(true);
  };

  const handleCancel = () => {
    setAdding(false);
  };

  const FormComponent = formMap[type];

  const sectionItems = useMemo(() => {
    return items.filter(
      (item, index, self) =>
        index ===
        self.findIndex(t => t.value === item.value && t.index === item.index),
    );
  }, [items]);

  return (
    <div className="flex flex-col gap-1 border-b border-borders px-4 last-of-type:border-none last-of-type:pb-0">
      <div className="flex items-center gap-1 text-sm text-typography-high-contrast">
        <div className="h-4 w-4">{icon}</div>
        {!!first && <span className="font-semibold">{first.value}</span>}
        {!first &&
          (!adding ? (
            <Button
              variant="ghost"
              size="sm"
              className="flex items-center gap-1 px-2 text-base font-semibold text-typography-color"
              onClick={handleClick}
            >
              {title} <PlusCircleIcon className="h-5 w-5" />
            </Button>
          ) : (
            <span>{title}</span>
          ))}
      </div>
      {subTitle && subTitle.length > 0 && (
        <span className="pl-8 text-sm text-typography-low-contrast-text">
          {subTitle}
        </span>
      )}
      <div className="flex flex-col gap-1 py-2 pl-8">
        {sectionItems?.map(item => (
          <SectionItem
            applicationId={applicationId}
            key={item.value}
            {...item}
          />
        ))}
        {adding ? (
          <FormComponent
            onSubmit={handleSubmit}
            loading={isLoading}
            onCancel={handleCancel}
            companyId={companyId}
            seekerId={seekerId}
          />
        ) : (
          !!first && (
            <Button
              variant="ghost"
              size="sm"
              className="-ml-4 w-fit items-center gap-1 px-2 py-0 text-base font-semibold text-typography-color"
              onClick={handleClick}
            >
              Add {title.toLowerCase()} <PlusCircleIcon className="h-5 w-5" />
            </Button>
          )
        )}
      </div>
    </div>
  );
};
