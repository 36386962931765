import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { SelectField } from "~/components/v2/SelectField";
import { timezones } from "~/helper/timezones";
import { FormButtons } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/form-buttons";
import {
  DetailsEntry,
  DetailsEntryTypeEnum,
  TimezoneEntrySchema as schema,
} from "~/services/jobSeekerApplications.types";

interface TimezoneFormProps {
  onSubmit: (data: DetailsEntry) => Promise<void>;
  loading?: boolean;
  onCancel: () => void;
}

const timezoneList = timezones();

export const TimezoneForm: React.FC<TimezoneFormProps> = ({
  onSubmit,
  loading,
  onCancel,
}) => {
  const {
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: { type: DetailsEntryTypeEnum.Timezone },
  });

  const submit = (data: z.infer<typeof schema>) => {
    onSubmit(data).then(() => {
      reset();
    });
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex flex-1 items-start gap-4"
    >
      <SelectField
        label=""
        error={errors.timezone?.message as string}
        emptyTerm="timezone"
        name="timezone"
        placeholder="Timezone"
        searchPlaceholder="Search..."
        setValue={setValue}
        value={watch("timezone")}
        containerClassName="flex-1"
        className="w-full"
        options={timezoneList.map(timezone => ({
          label: timezone.formattedText,
          value: timezone.timeZone,
        }))}
      />
      <FormButtons loading={loading} onCancel={onCancel} />
    </form>
  );
};
