import { useContext } from "react";
import { LoadingState } from "~/components/v2/list-status-placeholder/components/loading-state";

import { DetailedCandidateDrawerContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-drawer/detailed-candidate-drawer-context";
import { SidebarOpportunities } from "~/scalis-components/pipeline/detailed-candidate-view/components/sidebar-opportunities";
import { DetailedCandidateView } from "~/scalis-components/pipeline/detailed-candidate-view/detailed-candidate-view";
import { Candidate } from "~/services/jobSeekerApplications.types";

export const CandidateApplicationView = () => {
  const { candidateApplication, isLoading } = useContext(
    DetailedCandidateDrawerContext,
  );

  if (isLoading) {
    return <LoadingState />;
  }

  return (
    <main className="flex h-full flex-col">
      <SidebarOpportunities
        applicationId={candidateApplication.data.id}
        jobSeeker={
          candidateApplication.data?.candidate! as unknown as Candidate
        }
        job={candidateApplication.data.job_id}
        employerId={candidateApplication.userId}
      />
      <div className="ml-8 h-full">
        <DetailedCandidateView
          data={candidateApplication.data}
          userId={candidateApplication.userId}
          notes={candidateApplication.notes}
          activity={candidateApplication.activity}
          alreadyInvitedToJob={candidateApplication.alreadyInvitedToJob}
          recentlyInvitedToAnotherJob={
            candidateApplication.recentlyInvitedToAnotherJob
          }
        />
      </div>
    </main>
  );
};
