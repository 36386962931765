import { square, titleStyles } from "./candidate-list-view-title.styled";
import { CandidateListViewTitleProps } from "./candidate-list-view-title.types";

export const CandidateListViewTitle: React.FC<CandidateListViewTitleProps> = ({
  title,
  squareColor,
  action = null,
}) => {
  const { container, text } = titleStyles({ hasAction: !!action });

  return (
    <div className={container()} onClick={!!action ? action : () => null}>
      <div className={square({ color: squareColor })} />
      <h3 className={text()}>{title}</h3>
    </div>
  );
};
