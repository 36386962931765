import { DownloadIcon, TrashIcon } from "@radix-ui/react-icons";
import { useState } from 'react';
import { useGetFileUrl } from "~/hooks/use-get-file-url";
import { FileItemProps } from "./file-item.types";
import Spinner from "~/components/spinner";

export const FileItem = ({
    name, fileKey, index, handleRemove
}: FileItemProps) => {

    const { url, isLoadingUrl } = useGetFileUrl(fileKey);
    const [isDownloading, setIsDownloading] = useState(false);

    const handleDownload = async () => {
        setIsDownloading(true);
        try {
            const response = await fetch(url ?? '');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

        } catch (error) {
            console.error('Error fetching signed URL:', error);
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <div className="flex items-center justify-between w-full">
            <div>
                {name}
            </div>
            <div className='flex justify-end gap-x-2'>
                <button type="button" title='Donwload file' aria-label="Donwload file">
                    {isDownloading ? <Spinner /> : <DownloadIcon className="text-icons-secondary" onClick={handleDownload} width="20" height="20" />}
                </button>
                <button type="button" title='Remove file' aria-label='Remove file'>
                    <TrashIcon width="22" height="22" className="text-icons-secondary" onClick={() => handleRemove(index)} />
                </button>
            </div>
        </div>
    );
};
