import React from 'react';
import { CandidateDrawerNoteProps } from './candidate-drawer-note.type';

export const CandidateDrawerNote: React.FC<CandidateDrawerNoteProps> = ({ note }) => {
    return (
        <div className="border-borders-ring flex gap-2 rounded-lg border bg-primary-background px-3 py-2 ">
            <i className="fa-regular fa-info-circle text-icons mt-1" />
            <div className='text-typography-high-contrast text-sm'>
                <span className='font-semibold text-sm'>Note:</span> {note}
            </div>
        </div>
    );
};
