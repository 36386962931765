import { ActionsMenuItems } from "../../list-view.types";

export enum AlignMenu {
  center = "center",
  end = "end",
  start = "start",
}

export interface ActionsMenuProps {
  Icon: React.ComponentType;
  actionsMenuItems: ActionsMenuItems[][];
  alignMenu?: AlignMenu;
  tooltipText?: string;
  row?: any;
}
