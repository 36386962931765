import { ScreeningQuestionWithAnsJobSeeker } from "./screening-questions.types";

export const verifyQuestion = (item: ScreeningQuestionWithAnsJobSeeker) => {
  const { answer, type, ansJobSeeker } = item;

  switch (type) {
    case "binary":
      return answer.binary_ans?.toUpperCase() === ansJobSeeker;
    case "multiple":
      return answer.multi_ans === ansJobSeeker;
    default:
      if (
        answer.option === "to" &&
        answer.range1 !== undefined &&
        answer.range2 !== undefined
      ) {
        return (
          Number(ansJobSeeker) >= answer?.range1 &&
          Number(ansJobSeeker) <= answer?.range2
        );
      } else if (answer.option === "greater" && answer.range1 !== undefined) {
        return Number(ansJobSeeker) >= answer.range1;
      } else if (answer.option === "less" && answer.range1 !== undefined) {
        return Number(ansJobSeeker) <= answer.range1;
      }
  }
};
