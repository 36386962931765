import { Prisma } from "@prisma/client";
import React, { useState } from "react";
import { FaChevronRight } from "react-icons/fa6";

import { Button } from "~/components/v2/Button";
import { Candidate } from "~/services/jobSeekerApplications.types";
import { cn } from "~/utils/cn";
import { ClosedPanel } from "./components/closed-panel";
import { OpenPanel } from "./components/open-panel";

interface SidebarOpportunitiesProps {
  applicationId: number;
  employerId: number;
  jobSeeker?: Candidate;
  job: Prisma.JobGetPayload<{
    include: { user: { select: { companyId: true } } };
  }>;
}

export const SidebarOpportunities: React.FC<SidebarOpportunitiesProps> = ({
  applicationId,
  jobSeeker,
  job,
  employerId,
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(prev => !prev);
  const handleClose = () => setOpen(false);

  return (
    <div
      className={cn(
        "absolute z-20 h-full w-96 min-w-96 rounded-tr-lg border-r border-t border-borders bg-background-blue transition-all",
        {
          "w-9 min-w-9": !open,
        },
      )}
    >
      <Button
        size="icon"
        onClick={toggleOpen}
        className="absolute z-10 w-6 h-6 p-0 rounded-full -right-3 top-2"
      >
        <FaChevronRight
          className={cn("size-4 transition-transform", {
            "rotate-180": open,
          })}
        />
      </Button>

      {open ? (
        <OpenPanel
          employerId={employerId}
          jobId={job.id}
          applicationId={applicationId}
          jobSeeker={jobSeeker}
          companyId={job.user.companyId}
          onClose={handleClose}
        />
      ) : (
        <ClosedPanel job={job} />
      )}
    </div>
  );
};
