import {
  SideTab,
  SidebarTab,
  TabProps,
} from "~/scalis-components/pipeline/detailed-candidate-view";
import {
  ActivityLogTab,
  DetailsTab,
  DocumentsTab,
  InterviewsTab,
  NotesTab,
  ResumeTab,
  ScreenedByTab,
  ScreeningQuestionsTab,
} from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs";

export const isSidePanel = (tab: SidebarTab): tab is SideTab => {
  return ["notes", "details", "screened_by"].includes(tab);
};

export const panelMap: Record<SidebarTab, React.FC<TabProps>> = {
  resume: ResumeTab,
  screening_questions: ScreeningQuestionsTab,
  activity_log: ActivityLogTab,
  documents: DocumentsTab,
  notes: NotesTab,
  interview: InterviewsTab,
  details: DetailsTab,
  screened_by: ScreenedByTab,
};
