import React from "react";
import { z } from "zod";
import { StepRatingCandidatesProps } from "./step-rating-candidates.types";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "~/components/v2/Button";
import { DrawerFooter } from "~/components/v2/Drawer";
import { FORM_ID } from "../step-select-candidates/step-select-candidates.constants";
import { generateZodSchema } from "./step-rating-candidates.schema";
import { RatingSection } from "./components/rating-section";
import Spinner from "~/components/spinner";
import { useRejectCandidate } from "../../../reject-candidate-context";
import { parseDataToRejectContext } from "./step-rating-candidates.utils";
import { CandidateDrawerNote } from "~/scalis-components/pipeline";

export const StepRatingCandidates: React.FC<StepRatingCandidatesProps> = ({
  questions,
  onCancel,
  onSubmit,
  isLoadingQuestions,
}) => {
  const { updateRejectCandidateData } = useRejectCandidate();

  const formSchema = generateZodSchema(questions);

  const form = useForm({
    resolver: zodResolver(formSchema),
  });

  const onFormSubmit = (data: z.infer<typeof formSchema>) => {
    const parsedData = parseDataToRejectContext(data);
    updateRejectCandidateData({ ...parsedData });
    onSubmit();
  };

  return (
    <FormProvider {...form}>
      <form
        className="h-full overflow-y-auto p-4"
        onSubmit={form.handleSubmit(onFormSubmit)}
        id={FORM_ID}
      >
        {isLoadingQuestions ? (
          <div className="flex h-full flex-1 items-center justify-center">
            <Spinner />
          </div>
        ) : (
          <RatingSection questions={questions} />
        )}

        <CandidateDrawerNote note="Rejecting candidates cannot be undone." />
      </form>
      <DrawerFooter className="justify-end">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          aria-label="next"
          className="min-w-20"
          isLoading={false}
          type="submit"
          form={FORM_ID}
        >
          Next
        </Button>
      </DrawerFooter>
    </FormProvider>
  );
};
