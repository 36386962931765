import { useQuery } from "@tanstack/react-query";
import { fetchSources } from "~/queries/jobseeker/fetch-sources";

export const useGetSources = () => {
  const { data: sources, isLoading: isLoadingSources } = useQuery({
    queryKey: ["uploaded-candidate-sources"],
    queryFn: params => fetchSources(params),
  });
  return { sources, isLoadingSources };
};
