import { ActivityLogType } from "~/services/jobSeekerApplications.service";
import { Application } from "~/scalis-components/pipeline/detailed-candidate-view";
import { ActivityLogRow } from "./activity-log-row";

interface Props {
  data: Application;
  activity: ActivityLogType[];
}

export const ActivityLogTab: React.FC<Props> = ({ activity, data }) => {
  const notScreenedByActivity = activity.filter(
    act => act?.currentStage && act.action !== "VIEWED",
  );

  return (
    <div>
      <div className="p-4">
        <span className="font-semibold text-typography-color">
          Activity Log
        </span>
      </div>
      <table className="w-full">
        <thead>
          <tr className="text-sm font-semibold text-typography-high-contrast [&_th]:border-t [&_th]:border-borders [&_th]:px-2 [&_th]:py-4">
            <th className="pl-4 text-start">Date</th>
            <th className="text-left">Time</th>
            <th className="text-left">Req. #</th>
            <th className="w-[275px] text-left">Job Title</th>
            <th className="text-left">Action</th>
            <th className="pr-4 text-left"></th>
          </tr>
        </thead>
        <tbody>
          {notScreenedByActivity.map((act, i) => (
            <ActivityLogRow key={i} activity={act} data={data} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
