import { useRouter } from "next/navigation";
import { useContext } from "react";
import {
  FaChevronLeft,
  FaChevronRight,
  FaRegAddressCard,
  FaXmark,
} from "react-icons/fa6";

import { DetailedCandidateDrawerContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-drawer/detailed-candidate-drawer-context";
import { ActionDrawerButton } from "../action-drawer-button";

export const CandidateDrawerActions = () => {
  const { closeDrawer, previousCandidate, nextCandidate, applicationPageUrl } =
    useContext(DetailedCandidateDrawerContext);
  const router = useRouter();

  return (
    <aside className="fixed -left-8 flex flex-col gap-2 pt-6">
      <ActionDrawerButton
        test-id="next-candidate-button"
        icon={FaChevronRight}
        tooltipText="Next Candidate"
        onClick={nextCandidate}
      />
      <ActionDrawerButton
        icon={FaChevronLeft}
        tooltipText="Previous Candidate"
        onClick={previousCandidate}
      />
      <ActionDrawerButton
        icon={FaRegAddressCard}
        tooltipText="Candidate Page"
        onClick={() => router.replace(applicationPageUrl)}
      />
      <ActionDrawerButton
        icon={FaXmark}
        tooltipText="Close"
        onClick={closeDrawer}
      />
    </aside>
  );
};
