import {
  FocusEventHandler,
  InputHTMLAttributes,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { FaMagnifyingGlass, FaXmark } from "react-icons/fa6";
import { cn } from "~/utils/cn";
import { Button } from "../Button";

const SearchInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    onChangeFilter: (value: string) => void;
  }
>(({ className, value, onBlur, onChangeFilter, ...props }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [open, setOpen] = useState(false);

  useImperativeHandle<HTMLInputElement | null, HTMLInputElement | null>(
    ref,
    () => inputRef.current,
  );

  const handleFilterChange = (value: string) => {
    onChangeFilter(value);
  };

  const handleOpen = () => {
    if (!open) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
      handleFilterChange("");
    }
    setOpen(current => !current);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = e => {
    if (!inputRef.current?.value) setOpen(false);
    if (!onBlur) return;
    onBlur(e);
  };

  return (
    <div className="flex justify-end" data-testid="search-input">
      <div
        data-state={open ? "open" : "closed"}
        className={cn(
          "inline-flex h-8 items-center justify-end overflow-hidden rounded-full border border-borders bg-white p-2 hover:border-actions-muted hover:bg-background-muted-background active:border-actions-muted active:bg-background-muted-background",
          "w-full transition-[width,_padding] duration-500 data-[state=closed]:w-8",
          {
            "border-borders": !!value,
            "!bg-white py-2 pl-2 pr-0": open,
          },
          className,
        )}
      >
        <input
          ref={inputRef}
          type="text"
          data-state={open ? "open" : "closed"}
          className="w-full bg-transparent text-typography-high-contrast focus:outline-none"
          value={value}
          onChange={e => handleFilterChange(e.target.value)}
          onBlur={handleBlur}
          {...props}
        />
        <Button
          variant="ghost"
          size="icon"
          onClick={handleOpen}
          className={cn("hover:bg-transparent active:bg-transparent")}
        >
          {open ? (
            <FaXmark className="h-4 w-4 text-icons-low-contrast" />
          ) : (
            <FaMagnifyingGlass className="h-4 w-4 text-icons-low-contrast" />
          )}
        </Button>
      </div>
    </div>
  );
});

SearchInput.displayName = "SearchInput";

export default SearchInput;
