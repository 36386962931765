import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { useRouter } from "next/navigation";

import { Button } from "~/components/v2/Button";
import { Card } from "~/components/v2/Card";
import { JobApplicationStarRating } from "~/components/v2/job-application-star-rating";
import { Candidate } from "~/services/jobSeekerApplications.types";

export interface SeekerCardProps {
  applicationId: number;
  jobId: number;
  employerId: number;
  jobSeeker?: Candidate;
  onClose: () => void;
}

export const SeekerCard: React.FC<SeekerCardProps> = ({
  applicationId,
  jobSeeker,
  employerId,
  jobId,
  onClose,
}) => {
  const router = useRouter();

  if (!jobSeeker) return null;

  return (
    <Card className="border border-borders p-4">
      <div className="flex flex-col gap-1">
        <h2 className="flex items-center gap-2 font-semibold text-typography-high-contrast">
          {jobSeeker.fullName}
          {jobSeeker.hasSCALISProfile && (
            <JobApplicationStarRating
              applicationId={applicationId}
              jobId={jobId}
              seekerId={jobSeeker.id}
              empId={employerId}
              className="gap-0.5 text-score-colors-06"
            />
          )}
        </h2>
        <span className="text-xs font-semibold text-typography-high-contrast">
          {jobSeeker.currentEducation}
        </span>
        <span className="text-xs font-semibold text-typography-high-contrast">
          {jobSeeker.currentJob}
        </span>
      </div>

      <Button
        type="button"
        variant="tonal"
        size="sm"
        className="flex w-fit gap-1 self-end rounded-lg"
        onClick={async () => {
          await router.push(`/company/pipeline/${jobId}/${applicationId}`);
          onClose();
        }}
      >
        <span>See current profile</span>
        <ArrowRightIcon className="size-4" />
      </Button>
    </Card>
  );
};

export default SeekerCard;
