import { FieldValues, UseFormSetValue } from "react-hook-form";

export const setAllFormValues = (
  values: FieldValues,
  setValue: UseFormSetValue<FieldValues>,
) => {
  Object.keys(values).forEach(key => {
    setValue(key, values[key], { shouldDirty: true });
  });
};
