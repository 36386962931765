import axios from "~/services/axios.service";
import { AdvanceApplication } from "~/types/applications/advance-application";

export const advanceProcessingCandidate = ({
  applicationId,
  ...data
}: AdvanceApplication & { applicationId: number }) =>
  axios
    .post(`/api/applications/${applicationId}/advance`, { ...data })
    .then(response => response.data);
