import * as React from "react";
import { TEXTS } from "./application-already-exists-modal.constants";
import { Dialog, DialogContent } from "~/components/v2/Dialog";
import { DialogClose } from "@radix-ui/react-dialog";
import { ApplicationAlreadyExistsModalProps } from "./application-already-exists-modal.types";
import { Button } from "~/components/v2/Button";

export const ApplicationAlreadyExistsModal = ({
    popupType,
    jobNumber,
    jobTitle,
    open: controlledOpen,
    onOpenChange: controlledOpenChange,
}: ApplicationAlreadyExistsModalProps) => {
    const isControlled = typeof controlledOpen !== "undefined";
    const [innerOpen, setInnerOpen] = React.useState(false);

    const open = isControlled ? controlledOpen : innerOpen;
    const onOpenChange = isControlled ? controlledOpenChange : setInnerOpen;
    const { title, header, footer } = TEXTS[popupType as keyof typeof TEXTS] || {}
    return (
        <Dialog open={open} onOpenChange={onOpenChange}>
            <DialogContent className="max-w-96 p-4">
                <div className="flex flex-col">
                    <h1 className="text-center font-bold text-dark-color-text-100">
                        {title}
                    </h1>
                    <span className="text-center text-xs pt-2 text-black">
                        {header}
                    </span>
                    <span className="text-center whitespace-nowrap text-xs text-black">
                        {jobNumber} - {jobTitle}.
                    </span>{" "}
                    <span className="text-center text-xs py-6 text-black">
                        {footer}
                    </span>
                </div>
                <DialogClose><Button asChild
                    className=" w-full max-h-10 rounded-xl bg-actions p-2 text-center text-sm font-semibold text-white"
                >Ok</Button></DialogClose>
            </DialogContent>
        </Dialog>
    );
};

