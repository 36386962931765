import { MouseEventHandler, forwardRef } from "react";

import { cn } from "~/utils/cn";

interface ItemProps {
  label: string;
  onClick?: MouseEventHandler;
  active?: boolean;
}

export const TabBarItem = forwardRef<HTMLButtonElement, ItemProps>(
  ({ label, onClick, active }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          "group relative whitespace-nowrap px-3 py-2 text-center text-sm text-typography-high-contrast",
          {
            "font-semibold text-typography-color": active,
            "hover:text-typography-color": !active,
          },
        )}
        disabled={active}
        onClick={onClick}
      >
        {label}
        {!active && (
          <div className="bg- absolute -bottom-[calc(.5rem_+_1px)] left-0 hidden h-px w-full group-hover:block"></div>
        )}
      </button>
    );
  },
);
