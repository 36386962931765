import { CompanyEmployee } from "~/hooks/pipelines/useRecruiterTeam";

export const getInterviewerOptions = (
  companyTeam: CompanyEmployee[],
  interviewers: CompanyEmployee[],
  interviewer: CompanyEmployee,
) =>
  companyTeam
    ?.filter(
      (member: CompanyEmployee) =>
        interviewer.value === member.value ||
        !interviewers.find((i) => i.value === member.value),
    )
    .map((member) => ({
      label: member.nylas_account ? member.label : `${member.label}`,
      value: member.value,
      disabled: !member.nylas_account,
      disabledLabel: "Calendar not connected",
    }));
