import { SchedulerLinkSchema } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/scheduler-link-drawer/components/scheduler-link-form/scheduler-link-form.types";

export const parseFormData = (
  data: SchedulerLinkSchema,
  applicationId: number,
  stageId: number,
) => {
  return {
    applicationId,
    stageId,
    calendarId: Number(data.calendar),
    length: Number(data.interviewLength),
    interviewers:
      data?.interviewers.map(teamMember => Number(teamMember)) || undefined,
    candidates: data.candidates.map(candidate => ({
      id: Number(candidate.id),
      type: "JOBSEEKER",
    })),
    message: data?.custom_message || data?.message || undefined,
  };
};
