import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";

export const fetchApplicationsRejectQuestions = async ({
  signal,
}: QueryFunctionContext) => {
  return await axios
    .get(`/api/applications/reject/question`, {
      signal,
    })
    .then(response => response.data)
    .catch(e => e.message);
};
