import { errorMessage } from "~/utils/errorMessage";

export const FORM_ID = "step-select-candidates-form";

export const FIELD_CANDIDATES = "candidates";
export const FIELD_NAME_DEFAULT_MESSAGE = "default_message";
export const FIELD_NAME_CUSTOM_MESSAGE = "custom_message";
export const FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE = "cb_default_message";
export const FIELD_NAME_REJECT_REASON = "reason";
export const FIELD_NAME_MEDALIST_STATUS = "medalistDesignation";
export const FIELD_NAME_ADDIONAL_DETAILS = "details";

export const formDefaultValues = {
  [FIELD_CANDIDATES]: [],
  [FIELD_NAME_CUSTOM_MESSAGE]: "",
  [FIELD_NAME_DEFAULT_MESSAGE]: "",
  [FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE]: false,
  [FIELD_NAME_REJECT_REASON]: "",
  [FIELD_NAME_MEDALIST_STATUS]: "",
  [FIELD_NAME_ADDIONAL_DETAILS]: "",
};

export const FORM_FIELDS = {
  [FIELD_CANDIDATES]: {
    label: "Candidate(s) being rejected",
    placeholder: "Select candidate(s)",
    emptyMessage: "No candidates found",
    errorMessage: "Please select at least one candidate",
  },
  [FIELD_NAME_REJECT_REASON]: {
    placeholder: "chose an option",
    label: "Reject Reason",
    emptyTerm: "reject reason",
    searchPlaceholder: "Search...",
    errorMessage: errorMessage("reject reason"),
  },
  [FIELD_NAME_MEDALIST_STATUS]: {
    placeholder: "chose an option",
    label: "Medalist Status",
    emptyTerm: "medalist status",
    searchPlaceholder: "Search...",
    errorMessage: errorMessage("medalist status"),
  },
  [FIELD_NAME_ADDIONAL_DETAILS]: {
    placeholder: "Insert here additional details",
    label: "Additional Details",
    errorMessage: "Please provide additional details.",
  },
};
