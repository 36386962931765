import { DefaultMessages } from "@prisma/client";
import { FormValues } from "./components/match-candidate-drawer-form";
import { getDefaultMessage } from "~/scalis-components/pipeline";

export const parseFormData = (
  data: FormValues,
  jobSeekerId?: number,
  uploadedCandidateId?: number,
  defaultMessages?: Partial<DefaultMessages>[],
) => {
  const defaultMessage = getDefaultMessage(
    defaultMessages || [],
    data.default_message ?? "0",
  );
  const message = defaultMessage || data.custom_message;
  const parsedMessage = message?.replace(/<[^>]*>/g, "");
  const body = {
    ...(jobSeekerId && { jobSeekerId }),
    ...(uploadedCandidateId && { uploadedCandidateId }),
    jobId: parseInt(data.jobId || "0"),
    invite: !!parsedMessage,
    ...(parsedMessage && {
      message,
    }),
  };
  return body;
};
