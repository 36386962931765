import { forwardRef, useContext, useMemo } from "react";
import { GlobalListViewContext, ListView } from "~/components/v2/list-view";
import { ListViewPagination } from "~/components/v2/list-view-pagination";
import { CandidateListViewProps } from "./candidate-list-view.types";
import { candidateListViewColumns } from "./candidate-list-view.utils";
import { CandidateActionBar } from "../candidate-action-bar";

export const CandidateListView = forwardRef<
  HTMLDivElement,
  CandidateListViewProps
>(
  (
    {
      rows,
      rowActionsMenuItems,
      tableColor,
      id,
      excludeColumns,
      actionsBarItems,
      showActionBar = true,
    },
    ref,
  ) => {
    const { hideAllActionBars } = useContext(GlobalListViewContext);

    const columns = useMemo(() => {
      if (!excludeColumns) {
        return candidateListViewColumns;
      }

      return candidateListViewColumns.filter(
        c => !excludeColumns.includes(c.key),
      );
    }, [excludeColumns]);

    return (
      <ListView
        id={id}
        ref={ref}
        rows={rows}
        columns={columns}
        tableColor={tableColor}
        rowActionsMenuItems={rowActionsMenuItems}
      >
        {showActionBar && (
          <CandidateActionBar
            hideAllActionBars={hideAllActionBars}
            actionsBarItems={actionsBarItems}
          />
        )}
        <ListViewPagination />
      </ListView>
    );
  },
);
