import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";

export const fetchApplicationsByJobId = async (
  { signal }: QueryFunctionContext,
  jobId: number,
) => {
  return await axios
    .get(`/api/applications/by-job-id`, {
      signal,
      params: { jobId },
    })
    .then(response => response.data)
    .catch(e => e.message);
};
