import { MouseEvent, useEffect, useRef, useState } from "react";

import { MainTab, SideTab } from "~/scalis-components/pipeline/detailed-candidate-view";
import { cn } from "~/utils/cn";
import { TabBarItem } from "./tab-bar-item";

type PropsMain = {
  version: "main";
  currentTab: MainTab;
  onChange: (tab: MainTab) => void;
};

type PropsSide = {
  version: "side";
  currentTab: SideTab;
  onChange: (tab: SideTab) => void;
};

type Props = {
  visible: boolean;
  uploadedVersion?: boolean;
} & (PropsMain | PropsSide);

export const TabBar: React.FC<Props> = ({
  visible,
  currentTab,
  onChange,
  version,
  uploadedVersion,
}) => {
  const selectedRef = useRef<HTMLButtonElement>(null);
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (!selectedRef.current) return;
    setLeft(selectedRef.current.offsetLeft);
    setWidth(selectedRef.current.offsetWidth);
  }, [currentTab, selectedRef]);

  const handleChange =
    (tab: typeof currentTab) => (e: MouseEvent<HTMLButtonElement>) =>
      (onChange as (tab: typeof currentTab) => void)(tab);

  return (
    <div
      className={cn(
        "relative hidden w-full border-b border-b-borders-borders px-1 py-2",
        {
          "xl:block": visible,
        },
      )}
    >
      {version === "main" ? (
        <>
          {!uploadedVersion && (
            <>
              <TabBarItem
                label="Resumes"
                active={currentTab === "resume"}
                ref={currentTab === "resume" ? selectedRef : undefined}
                onClick={handleChange("resume")}
              />
              <TabBarItem
                label="Screening Questions"
                active={currentTab === "screening_questions"}
                ref={
                  currentTab === "screening_questions" ? selectedRef : undefined
                }
                onClick={handleChange("screening_questions")}
              />
            </>
          )}
          <TabBarItem
            label="Interview(s)"
            active={currentTab === "interview"}
            ref={currentTab === "interview" ? selectedRef : undefined}
            onClick={handleChange("interview")}
          />
          <TabBarItem
            label="Activity Log"
            active={currentTab === "activity_log"}
            ref={currentTab === "activity_log" ? selectedRef : undefined}
            onClick={handleChange("activity_log")}
          />
          <TabBarItem
            label="Documents"
            active={currentTab === "documents"}
            ref={currentTab === "documents" ? selectedRef : undefined}
            onClick={handleChange("documents")}
          />
        </>
      ) : (
        <>
          <TabBarItem
            label="Notes"
            active={currentTab === "notes"}
            ref={currentTab === "notes" ? selectedRef : undefined}
            onClick={handleChange("notes")}
          />
          <TabBarItem
            label="Details"
            active={currentTab === "details"}
            ref={currentTab === "details" ? selectedRef : undefined}
            onClick={handleChange("details")}
          />
          <TabBarItem
            label="Screened By"
            active={currentTab === "screened_by"}
            ref={currentTab === "screened_by" ? selectedRef : undefined}
            onClick={handleChange("screened_by")}
          />
        </>
      )}
      <div
        className="absolute bottom-0 h-0.5 bg-secondary-background transition-all duration-300"
        style={{ left, width }}
      />
    </div>
  );
};
