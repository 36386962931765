import axios from "~/services/axios.service";

export const inviteToApply = ({
  applicationId,
  inviteMessage,
}: {
  applicationId: number;
  inviteMessage: string;
}) =>
  axios
    .post(`/api/candidates/${applicationId}/inviteToApply`, {
      invite_message: inviteMessage,
    })
    .then(response => response.data);
