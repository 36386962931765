export const swapArrayElements = <T>(
  array: Array<T>,
  index1: number,
  index2: number,
) => {
  const newArray = [...array];

  const valueIndex1 = array[index1];
  const valueIndex2 = array[index2];

  newArray[index1] = valueIndex2;
  newArray[index2] = valueIndex1;

  return [...newArray];
};
