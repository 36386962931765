import axios from "~/services/axios.service";

export const deleteDetailsEntry = ({
  id,
  index,
}: {
  id: number;
  index: number;
}) =>
  axios
    .delete(`/api/applications/${id}/details`, { params: { index } })
    .then(response => response.data)
    .catch(error => {
      throw error.response.data;
    });
