import { useQuery } from "@tanstack/react-query";
import { fetchFileUrl } from "~/queries/fetch-file-url";

export const useGetFileUrl = (key: string) => {
  const { data: url, isLoading: isLoadingUrl } = useQuery({
    queryKey: ["fetch-file-url", key],
    queryFn: params => fetchFileUrl(params, { key }),
    enabled: !!key,
  });

  return { url, isLoadingUrl };
};
