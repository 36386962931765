import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import PhoneField from "~/components/jobseeker/EditForm/PhoneField";
import { FormButtons } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/form-buttons";
import {
  DetailsEntry,
  DetailsEntryTypeEnum,
  PhoneEntrySchema as schema,
} from "~/services/jobSeekerApplications.types";

interface PhoneFormProps {
  onSubmit: (data: DetailsEntry) => Promise<void>;
  loading?: boolean;
  onCancel: () => void;
}

export const PhoneForm: React.FC<PhoneFormProps> = ({
  onSubmit,
  loading,
  onCancel,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: { type: DetailsEntryTypeEnum.Phone },
  });

  const submit = (data: z.infer<typeof schema>) => {
    onSubmit(data).then(() => {
      reset();
    });
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex flex-1 items-start gap-4"
    >
      <PhoneField
        control={control}
        name="phone"
        containerClassName="flex-1"
        error={errors.phone?.message as string}
        autoFocus
      />
      <FormButtons loading={loading} onCancel={onCancel} />
    </form>
  );
};
