export const STATUS = {
  PROSPECT: { name: "Prospect", color: "bg-chardonnay-200", key: "PROSPECT" },
  INVITED_TO_APPLY: {
    name: "Invited to apply",
    color: "bg-forest-green",
    key: "INVITED_TO_APPLY",
  },
  INVITE_REJECTED: {
    name: "Invite rejected",
    color: "bg-monza-300",
    key: "INVITE_REJECTED",
  },
  SOURCING: { name: "Sourcing", color: "bg-extra-colors-01", key: "SOURCING" },
  APPLICATION_REVIEW: {
    name: "Application review",
    color: "bg-extra-colors-02",
    key: "APPLICATION_REVIEW",
  },
  INTERVIEW_NOT_SCHEDULED: {
    name: "Not scheduled",
    color: "bg-monza-200",
    key: "INTERVIEW_NOT_SCHEDULED",
  },
  SCREENING: {
    name: "Screening",
    color: "bg-extra-colors-03",
    key: "SCREENING",
  },
  INTERVIEW_ROUND: {
    name: "Interview round",
    color: "bg-extra-colors-04",
    key: "INTERVIEW_ROUND",
  },
  JOB_OFFER: {
    name: "Job offer",
    color: "bg-extra-colors-05",
    key: "JOB_OFFER",
  },
  HIRED: { name: "Hired", color: "bg-extra-colors-06", key: "HIRED" },
  REJECTED: { name: "Rejected", color: "bg-monza-300", key: "REJECTED" },
};
