import { Row } from "@tanstack/react-table";

import { useSortable } from "@dnd-kit/sortable";
import { getCellInsertPosition } from "../../../list-view.utils";
import { dndStyles, tr } from "../list-view-body.styles";
import { useListViewBody } from "../use-list-view-body";
import { BodyCell } from "./body-cell";

interface BodyRowProps {
  row: Row<any>;
}

export const BodyRow: React.FC<BodyRowProps> = ({ row }) => {
  const { activeRowIndex } = useListViewBody();

  const {
    transform,
    transition,
    setNodeRef,
    attributes,
    listeners,
    over,
    index,
    isSorting,
    active,
  } = useSortable({
    id: row.original.id,
  });

  const selectedRow = row.getIsSelected();

  const insertPosition = getCellInsertPosition({
    overId: over?.id!,
    currentId: row.original.id,
    index,
    activeCellIndex: activeRowIndex,
  });

  return (
    <div
      style={{ ...dndStyles({ transform, transition, isSorting }) }}
      className={tr({
        insertPosition: insertPosition,
      })}
      role="row"
    >
      {row.getVisibleCells().map(cell => {
        return (
          <BodyCell
            key={cell.id}
            attributes={attributes}
            cell={cell}
            listeners={listeners}
            isSelected={selectedRow}
            active={active?.id === row.original.id}
            ref={setNodeRef}
          />
        );
      })}
    </div>
  );
};
