import { useQuery } from "@tanstack/react-query";
import {
  fetchJobsSummary,
  JobSummaryQueryParams,
} from "~/queries/jobOpening/fetchJobsSummary";

export const useGetJobsSummary = (queryParams?: JobSummaryQueryParams) => {
  const { data, isLoading } = useQuery({
    queryKey: ["jobs-summary"],
    queryFn: params => fetchJobsSummary(params, queryParams),
  });
  return { jobs: data, isLoadingJobs: isLoading };
};
