import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { uploadCandidate } from "~/mutations/jobseeker/uploadCandidate";
import { PostUploadCandidateSchema } from "~/types/jobseeker/upload-candidate";

export const useUploadCandidate = (
  options: UseMutationOptions<
    any,
    unknown,
    { data: PostUploadCandidateSchema },
    unknown
  >,
) => useMutation({ mutationFn: uploadCandidate, ...options });
