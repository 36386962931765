"use client";

import { Job, JobSeekerApplication, Milestone, Stage } from "@prisma/client";
import { format } from "date-fns";
import React from "react";
import { cn } from "~/utils/cn";
import { MILESTONE_COLOR } from "~/utils/milestoneColors";
import { stateNameToCode } from "~/utils/statesMap";

interface OpportunityCardProps {
  application: JobSeekerApplication;
  job: Job;
  stages?: Stage[];
  milestone?: Milestone;
  wide?: boolean;
  active?: boolean;
  onClick?: () => void;
}

const OpportunityCard: React.FC<OpportunityCardProps> = ({
  application,
  stages = [],
  job,
  milestone,
  wide,
  active,
  onClick,
}) => {
  const currentStageIndex = stages.findIndex(
    stage => stage.id === application.stageId,
  );

  const milestoneLabel = React.useMemo(() => {
    if (application.status !== "REJECTED") {
      return milestone?.name;
    }
    if (!wide) return "Rejected";
    return `Rejected - ${stages[currentStageIndex]?.name}`;
  }, [milestone, application, stages, wide, currentStageIndex]);

  const milestoneColor = React.useMemo(() => {
    if (application.status !== "REJECTED") {
      return MILESTONE_COLOR[
        milestone?.milestoneType as keyof typeof MILESTONE_COLOR
      ];
    }
    return "#D7DAE2";
  }, [milestone, application]);

  return (
    <div
      className={cn(
        "group flex flex-col gap-3 rounded-xl border border-borders bg-white p-4 text-typography-high-contrast",
        {
          "cursor-pointer hover:border-borders-ring hover:bg-primary-background active:bg-primary-background-hover":
            !!onClick,
        },
        { "border-borders-ring": active },
      )}
      onClick={onClick}
    >
      <div>
        <div className="flex gap-2 text-typography-high-contrast">
          <h2 className="">
            {job.requisition_num.toString().padStart(5, "0")}
          </h2>
          <span>•</span>
          <h1 className="truncate font-semibold">{job.job_title}</h1>
        </div>
        <span className="text-sm italic text-typography-low-contrast-text">
          {job.city}, {stateNameToCode[job.state ?? ""] || job.state}
        </span>
      </div>
      <span className="text-xs text-typography-high-contrast">
        Applied on{" "}
        {application.appliedAt
          ? format(new Date(application.appliedAt), "PP")
          : ""}
      </span>
      <span
        className="w-fit rounded-full px-4 py-1 text-sm text-typography-high-contrast"
        style={{
          backgroundColor: milestoneColor,
        }}
      >
        {milestoneLabel}
      </span>
      <div className="flex gap-1">
        {stages.map((stage, index) => (
          <StageComponent
            key={stage.id}
            stage={stage.name}
            filled={index <= currentStageIndex}
          />
        ))}
      </div>
    </div>
  );
};

export default OpportunityCard;

interface StageComponentProps {
  filled?: boolean;
  stage?: string;
}

const StageComponent: React.FC<StageComponentProps> = ({ filled, stage }) => {
  return (
    <div
      className={cn(
        "h-2 w-6 rounded-full bg-background-muted-background group-hover:bg-white",
        {
          "bg-actions-pressed group-hover:bg-actions-pressed": filled,
        },
      )}
      title={stage}
    ></div>
  );
};
