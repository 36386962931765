import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FaRegClock } from "react-icons/fa6";
import { z } from "zod";

import { Button } from "~/components/v2/Button";
import { timezones } from "~/helper/timezones";
import { useCreateDetailsEntry } from "~/hooks/applications/useCreateDetailsEntry";
import { useDeleteDetailsEntry } from "~/hooks/applications/useDeleteDetailsEntry";
import { useToast } from "~/hooks/useToast";
import EditIcon from "~/icons/Edit";
import { TabProps } from "~/scalis-components/pipeline/detailed-candidate-view";
import { TimezoneForm } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/forms/timezone";
import {
  DetailsEntry,
  DetailsEntryTypeEnum,
  TimezoneEntrySchema,
} from "~/services/jobSeekerApplications.types";

interface TimezoneSectionProps {
  data: TabProps["data"];
}

const timezoneList = timezones();

export const TimezoneSection: React.FC<TimezoneSectionProps> = ({
  data: { details, ...data },
}) => {
  const queryClient = useQueryClient();
  const { toast } = useToast();

  const [editing, setEditing] = React.useState(false);

  const applicationId = data.id;

  const { mutateAsync: createEntry, isPending: creating } =
    useCreateDetailsEntry({
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["candidate-application", applicationId],
        });
      },
      onError: error => {
        toast({
          description: (error as any)?.message ?? "Unexpected error ocurred",
          title: "Error",
          variant: "destructive",
        });
      },
    });

  const { mutateAsync: deleteEntry, isPending: deleting } =
    useDeleteDetailsEntry({
      onSuccess: () => {
        setEditing(false);
        queryClient.invalidateQueries({
          queryKey: ["candidate-application", data.id],
        });
      },
      onError: error => {
        toast({
          description: (error as any)?.message ?? "Unexpected error ocurred",
          title: "Error",
          variant: "destructive",
        });
      },
    });

  const scalisTz = data.candidate.hasSCALISProfile
    ? data.candidate.job_seeker_basicinfo?.timezone
    : "";
  const tzIndex =
    details?.findIndex?.(
      (entry: DetailsEntry) => entry.type === DetailsEntryTypeEnum.Timezone,
    ) || -1;
  const detailsTz = details[tzIndex] as
    | z.infer<typeof TimezoneEntrySchema>
    | undefined;
  const timezone = timezoneList.find(
    tz => tz.timeZone === (detailsTz?.timezone || scalisTz),
  );

  const handleSubmit = (data: DetailsEntry) => {
    return new Promise<void>((resolve, reject) =>
      createEntry({ id: applicationId, data })
        .then(() => {
          if (!detailsTz) {
            setEditing(false);
            return resolve();
          }
          deleteEntry({
            id: applicationId,
            index: tzIndex ?? -1,
          })
            .then(resolve)
            .catch(reject);
        })
        .catch(reject),
    );
  };

  const handleClick = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditing(false);
  };

  return (
    <div className="flex flex-col gap-2 border-b border-borders px-4 py-2">
      <div className="flex items-center gap-1 font-semibold text-typography-high-contrast">
        <FaRegClock className="text-sm" />
        {!editing ? (
          <>
            <span className="text-sm">
              {timezone?.formattedText ?? "Not informed"}
              {!detailsTz && scalisTz && " (SCALIS profile)"}
            </span>
            <Button
              size="icon"
              variant="ghost"
              className="rounded-full text-primary-1-90"
              onClick={handleClick}
            >
              <EditIcon />
            </Button>
          </>
        ) : (
          <TimezoneForm
            onCancel={handleCancel}
            onSubmit={handleSubmit}
            loading={creating || deleting}
          />
        )}
      </div>
    </div>
  );
};
