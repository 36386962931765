import { FaLink, FaRegCalendar } from "react-icons/fa6";
import { Button } from "~/components/v2/Button";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/v2/Drawer";
import { CardButton } from "~/components/v2/card-button";
import { ConnectCalendarNote } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/components/interview-type-selection-drawer/components/connect-calendar-note";
import {
  InterviewTypeSelectionDrawerProps,
  ScheduleTypeEnum,
} from "./interview-type-selection-drawer.types";

export const InterviewTypeSelectionDrawer = ({
  open,
  isCalendarConnected,
  onOpenChange,
  onSelect,
}: InterviewTypeSelectionDrawerProps) => {
  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent asChild onResize={() => false}>
        <div>
          <DrawerHeader className="text-center">
            <h1 className="text-base font-semibold text-typography-high-contrast">
              Schedule
            </h1>
          </DrawerHeader>
          {isCalendarConnected ? (
            <main className="flex flex-col gap-2 px-6 py-4">
              <CardButton
                icon={FaLink}
                title="Scheduler link"
                description="Send a link to the candidate for them to self-schedule into your calendar."
                onClick={() => onSelect(ScheduleTypeEnum.SCHEDULER_LINK)}
              />
              <CardButton
                icon={FaRegCalendar}
                title="Manually Schedule"
                description="Schedule an interview with the candidate and assign stakeholders to participate."
                onClick={() => onSelect(ScheduleTypeEnum.MANUALLY_SCHEDULE)}
              />
            </main>
          ) : (
            <main className="flex flex-col gap-2 px-6 py-4">
              <span className="font-semibold text-typography-high-contrast">
                Send interview link and notify candidate
              </span>
              <ConnectCalendarNote />
            </main>
          )}

          <DrawerFooter className="justify-end">
            <DrawerClose asChild>
              <Button variant="outline">Cancel</Button>
            </DrawerClose>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
