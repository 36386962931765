import React from "react";
import { CollapsibleFilterFieldProps } from "./collapsible-filter-field.types";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "~/components/v2/Collapsible";
import { Reset } from "../reset";

export const CollapsibleFilterField: React.FC<CollapsibleFilterFieldProps> = ({
  label,
  children,
  onResetField,
  showReset,
  defaultOpen = false,
}) => {
  return (
    <Collapsible
      key={`collapsible-filter-${label}`}
      className="flex flex-col border-t border-solid border-borders-borders px-5 py-2"
      defaultOpen={defaultOpen}
    >
      <CollapsibleTrigger className="group flex items-center justify-between gap-2">
        <div className="flex flex-1 items-center justify-between">
          <h2 className="text-sm font-semibold text-typography-high-contrast">
            {label}
          </h2>
          {showReset && <Reset onClick={onResetField} />}
        </div>
        <i className="fa-regular fa-chevron-down duration-300 ease-in-out group-data-[state=open]:rotate-180" />
      </CollapsibleTrigger>
      <CollapsibleContent className="flex flex-col data-[state=open]:my-2">
        {children}
      </CollapsibleContent>
    </Collapsible>
  );
};
