import { useState } from "react";
import { Button } from "~/components/v2/Button";
import { Application } from "~/scalis-components/pipeline/detailed-candidate-view";
import { MOCK_INTERVIEW_WITH_STAGE } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/schedule-interview.mock";
import { ActivityLogType } from "~/services/jobSeekerApplications.service";
import { cn } from "~/utils/cn";
import { capitalizeFirstLetter } from "./activity-log.utils";

interface Props {
  activity: ActivityLogType;
  data: Application;
}

export const ActivityLogRow: React.FC<Props> = ({ activity, data }) => {
  const [showNotes, setShowNotes] = useState(false);

  function handleCollapseNotes() {
    setShowNotes(!showNotes);
  }

  const showInterview = activity.currentStage === "INTERVIEW SCHEDULED";
  const interview = MOCK_INTERVIEW_WITH_STAGE;

  return (
    <>
      <tr
        className={cn(
          "text-xs font-normal text-typography-high-contrast [&_td]:border-y [&_td]:border-borders [&_td]:px-2 [&_td]:py-2",
          {
            "[&_td]:border-b-0": showInterview,
          },
        )}
      >
        <td className="pl-4">{activity.logDate}</td>
        <td className="text-typography-low-contrast-text">
          {activity.logTime}
        </td>
        <td>{data.job_id.requisition_num}</td>
        <td className="w-[264px]">{data.job_id.job_title}</td>
        <td>{capitalizeFirstLetter(activity.currentStage!)}</td>
        <td className="justify-end pr-4">
          {activity.notes && (
            <Button onClick={handleCollapseNotes} variant="ghost" size="icon">
              <i
                className={cn(
                  "fa-regular fa-chevron-down rotate-0 transition-transform",
                  { "-rotate-180": showNotes },
                )}
              />
            </Button>
          )}
        </td>
      </tr>
      {/* {showInterview && (
        <tr className="text-xs font-normal text-typography-high-contrast [&_td]:border-y-0 [&_td]:border-borders [&_td]:px-2 [&_td]:py-2">
          <td colSpan={6}>
            <div className="flex w-full items-center justify-between">
              {interview && <InterviewEventCard interview={interview} />}
            </div>
          </td>
        </tr>
      )} */}
      {activity.notes && showNotes ? (
        <tr>
          <td
            className="px-4 pb-4 pt-2 text-sm italic text-typography-low-contrast-text"
            colSpan={6}
          >
            {activity.notes}
          </td>
        </tr>
      ) : (
        ""
      )}
    </>
  );
};
