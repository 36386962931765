import { DetailedCandidateViewProps } from "~/scalis-components/pipeline/detailed-candidate-view";
import { DetailedCandidateTabs } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/detailed-candidate-tabs";
import { DetailedCandidateHeader } from "./components/detailed-candidate-header";

export const DetailedCandidateView: React.FC<DetailedCandidateViewProps> = ({
  data,
  userId,
  notes,
  activity,
  alreadyInvitedToJob,
  recentlyInvitedToAnotherJob,
}) => {
  return (
      <div className="flex h-full flex-col gap-2 overflow-y-hidden">
        <DetailedCandidateHeader
          data={data}
          userId={userId}
          alreadyInvitedToJob={alreadyInvitedToJob}
          recentlyInvitedToAnotherJob={recentlyInvitedToAnotherJob}
        />
        <DetailedCandidateTabs
          data={data}
          notes={notes}
          userId={userId}
          activity={activity}
        />
      </div>
  );
};
