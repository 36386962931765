import { SidebarTab } from "~/scalis-components/pipeline/detailed-candidate-view";
import { cn } from "~/utils/cn";
import { SidebarItem } from "./sidebar-item";

interface Props {
  visible: boolean;
  currentTab: SidebarTab;
  resumeOnly?: boolean;
  uploadedVersion?: boolean;
  onChange: (tab: SidebarTab) => void;
}

export const Sidebar: React.FC<Props> = ({
  visible,
  currentTab,
  onChange,
  resumeOnly,
  uploadedVersion,
}) => {
  const handleChange = (tab: SidebarTab) => () => onChange(tab);

  return (
    <div
      className={cn(
        "border-r-borders-border flex w-fit min-w-[10.35rem] flex-col border-r py-4",
        {
          "xl:hidden": !visible,
        },
      )}
    >
      {!uploadedVersion && (
        <SidebarItem
          label="Resume"
          active={currentTab === "resume"}
          onClick={handleChange("resume")}
        />
      )}
      {!resumeOnly && (
        <>
          {!uploadedVersion && (
            <SidebarItem
              label="Screening Questions"
              active={currentTab === "screening_questions"}
              onClick={handleChange("screening_questions")}
            />
          )}
          <SidebarItem
            label="Activity Log"
            active={currentTab === "activity_log"}
            onClick={handleChange("activity_log")}
          />
          <SidebarItem
            label="Documents"
            active={currentTab === "documents"}
            onClick={handleChange("documents")}
          />
          <SidebarItem
            label="Notes"
            active={currentTab === "notes"}
            onClick={handleChange("notes")}
          />
          <SidebarItem
            label="Interview(s)"
            active={currentTab === "interview"}
            onClick={handleChange("interview")}
          />
          <SidebarItem
            label="Details"
            active={currentTab === "details"}
            onClick={handleChange("details")}
          />
          <SidebarItem
            label="Screened By"
            active={currentTab === "screened_by"}
            onClick={handleChange("screened_by")}
          />
        </>
      )}
    </div>
  );
};
