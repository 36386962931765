import { ApplicationType, MilestoneType } from "@prisma/client";
import { type Candidate } from "..";

export const MILESTONE_TYPE = {
  SOURCING: "SOURCING",
  APPLICATION_REVIEW: "APPLICATION_REVIEW",
  PRE_SCREEN: "PRE_SCREEN",
  CUSTOM: "CUSTOM",
  OFFER: "OFFER",
  HIRED: "HIRED",
} as const;

interface Stage {
  stageId: number;
  name: string;
  candidates: Omit<Candidate, "id">[];
}

export interface Milestone {
  milestoneId: number;
  name: string;
  milestoneType: string | null;
  stages: Stage[];
}

export interface MatchTojobProps {
  name: string;
  applicationType: ApplicationType;
  seekerId: number;
  uploadedCandidateId: number;
  applicationId: number;
  nextStageId: number;
  jobRequisitionNumber: number;
  jobTitle: string;
  milestoneType: MilestoneType;
  alreadyInvitedToJob: boolean;
  recentlyInvitedToAnotherJob: boolean;
}

export interface AdvanceProps {
  name: string;
  applicationType: ApplicationType;
  seekerId: number;
  uploadedCandidateId: number;
  applicationId: number;
  alreadyInvitedToJob: boolean;
  recentlyInvitedToAnotherJob: boolean;
}

export interface RejectProps {
  applicationId: number;
  name: string;
  email: string;
}
