import { flatten } from "lodash";
import {
  getMilestoneTypeProps,
  getTitle,
} from "~/scalis-components/pipeline/list-view/candidate-list-view/utils";
import { MapMilestonesToCollapsableListProps } from "./milestones-list.types";
import { MAX_CANDIDATES_PER_STAGE_LISTVIEW } from "./milestone-list.constants";

export const mapMilestonesToCollapsableList = ({
  milestones,
  jobId,
  ...props
}: MapMilestonesToCollapsableListProps) => {
  const listArray = milestones.map((milestone, index) => {
    const nextStageId = milestones[index + 1]
      ? milestones[index + 1].stages[0].stageId
      : -1;

    return milestone.stages.map(stage => {
      const milestoneType = milestone.milestoneType as string;

      const { color, rowActionsMenuItems, actionsBarItems } =
        getMilestoneTypeProps({ milestoneType, nextStageId, ...props });

      const title = getTitle(milestone.name, stage.name);

      const viewDetailsRoute = `/company/pipeline?jobId=${jobId}&stageId=${stage.stageId}&milestoneId=${milestone.milestoneId}`;
      const { candidates } = stage;
      const limitedCandidates = candidates.slice(
        0,
        MAX_CANDIDATES_PER_STAGE_LISTVIEW,
      );
      return {
        id: stage.stageId,
        title,
        squareColor: color,
        listView: {
          rows: limitedCandidates.map(candidate => ({
            ...candidate,
            id: candidate.applicationId,
          })),
          tableColor: color,
          rowActionsMenuItems,
          actionsBarItems,
        },
        viewDetailsRoute,
      };
    });
  });

  return flatten(listArray);
};
