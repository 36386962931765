import React, { useState } from "react";
import { RejectCandidateDrawer } from "../reject-candidate-drawer";
import { RejectCandidatesModal } from "../reject-candidate-modal";
import { RejectionFlowProps } from "./rejection-flow.types";
import { useRejectCandidate } from "../reject-candidate-context";

export const RejectionFlow: React.FC<RejectionFlowProps> = ({
  companyId,
  jobId,
}) => {
  const { openDrawer, setOpenDrawer } = useRejectCandidate();
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <RejectCandidateDrawer
        companyId={companyId}
        jobId={jobId}
        open={openDrawer}
        setOpen={setOpenDrawer}
        onSuccess={() => setOpenModal(true)}
      />
      <RejectCandidatesModal open={openModal} setOpen={setOpenModal} />
    </>
  );
};
