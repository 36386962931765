import { Button } from "~/components/v2/Button";
import { ActionTemplateProps } from "./action.types";
import { cn } from "~/utils/cn";

export const ActionTemplate: React.FC<ActionTemplateProps> = ({
  onClick,
  children,
  action,
  small,
}) => (
  <Button
    variant="ghost"
    onClick={() => onClick?.(action!)}
    className={cn(
      "flex h-8 items-center justify-between gap-2 rounded-xl bg-background-primarybackground px-2 leading-5 text-primary-1-90 hover:bg-primary-background-hover hover:text-primary-text",
      { "h-6": small },
    )}
  >
    {children}
  </Button>
);
