import React, { useState } from "react";
import { cn } from "~/utils/cn";
import { ratingButton } from "./rating.styles";
import { RatingProps } from "./rating.types";
import { isA } from "jest-mock-extended";

const MAX_RATING = 10;

const ratingValues = [...Array(MAX_RATING).keys()];

export const Rating: React.FC<RatingProps> = ({ value, name, onClick }) => {
  const [rating, setRating] = useState(value);

  const handleClick = (newValue: number) => {
    onClick(name, newValue, { shouldDirty: true, shouldValidate: true });
    setRating(newValue);
  };

  return (
    <div className="flex w-full">
      {ratingValues.map(ratingValue => {
        return (
          <button
            type="button"
            aria-label={`value-${ratingValue + 1}`}
            key={`rating-${ratingValue}`}
            className={ratingButton({
              isActive: rating === ratingValue + 1,
            })}
            onClick={() => handleClick(ratingValue + 1)}
          >
            {ratingValue + 1}
          </button>
        );
      })}
    </div>
  );
};
