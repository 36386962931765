import { tv } from "tailwind-variants";

export const square = tv({
  base: "h-4 w-4 rounded",
  variants: {
    color: {
      "01": "bg-extra-01",
      "02": "bg-extra-02",
      "03": "bg-extra-03",
      "04": "bg-extra-04",
      "05": "bg-extra-05",
      "06": "bg-extra-06",
      "07": "bg-extra-07",
      "08": "bg-extra-08",
      "09": "bg-extra-09",
      "10": "bg-extra-10",
    },
  },
});

export const titleStyles = tv({
  slots: {
    container: "group flex items-center gap-1 rounded-md p-1",
    text: "text-sm text-typography-high-contrast select-none",
  },

  variants: {
    hasAction: {
      true: {
        container:
          "cursor-pointer hover:bg-primary-background active:bg-primary-background-hover",
        text: "group-active:text-typography-color",
      },
    },
  },

  defaultVariants: {
    hasAction: false,
  },
});
