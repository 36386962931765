import React from "react";
import { TextFieldProps } from "./text-field.types";
import { Input } from "~/components/v2/Input";
import { CollapsibleFilterField } from "../collapsible-filter-field";
import { useFormContext } from "react-hook-form";

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, placeholder = "", name, ...inputProps }, ref) => {
    const { resetField, watch } = useFormContext();

    const value = watch(name);

    return (
      <CollapsibleFilterField
        label={label}
        onResetField={() => resetField(name)}
        showReset={!!value}
        defaultOpen={!!value}
      >
        <Input
          ref={ref}
          placeholder={placeholder}
          name={name}
          className="rounded-lg"
          {...inputProps}
        />
      </CollapsibleFilterField>
    );
  },
);
