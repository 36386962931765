import { useSearchParams } from "next/navigation";
import { useEffect, useState } from "react";
import { ActivityLogType } from "~/services/jobSeekerApplications.service";

import {
  Application,
  CandidateNote,
  MainTab,
  SideTab,
  SidebarTab,
} from "~/scalis-components/pipeline/detailed-candidate-view";
import { Sidebar } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/sidebar";
import { TabBar } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tab-bar";
import { ViewMode } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/view-mode";
import { cn } from "~/utils/cn";
import { isSidePanel, panelMap } from "./detailed-candidate-tabs.utils";

interface DetailedCandidateTabsProps {
  data: Application;
  notes: CandidateNote[];
  userId: number;
  activity: ActivityLogType[];
}

export const DetailedCandidateTabs: React.FC<DetailedCandidateTabsProps> = ({
  data,
  notes,
  userId,
  activity,
}) => {
  const hasSCALISProfile = data.candidate.hasSCALISProfile;

  const searchParams = useSearchParams();
  const showCurrentProfile = searchParams?.get("currentProfile") === "true";

  const [sideBySide, setSideBySide] = useState(false);
  const [mainPanelTab, setMainPanelTab] = useState<SidebarTab>(
    hasSCALISProfile ? "resume" : "activity_log",
  );
  const [sidePanelTab, setSidePanelTab] = useState<SideTab>("notes");

  const MainPanel = panelMap[mainPanelTab];
  const SidePanel = panelMap[sidePanelTab];

  const handleSideBySide = (value: boolean) => {
    setSideBySide(value);
    if (value) {
      if (isSidePanel(mainPanelTab)) {
        setSidePanelTab(mainPanelTab);
        setMainPanelTab("resume");
      } else {
        setSidePanelTab("notes");
      }
    }
  };

  useEffect(() => {
    setMainPanelTab(hasSCALISProfile ? "resume" : "activity_log");
    setSidePanelTab("notes");
  }, [hasSCALISProfile]);

  return (
    <>
      {!showCurrentProfile && (
        <ViewMode sideBySide={sideBySide} onChangeViewMode={handleSideBySide} />
      )}
      <div className="flex w-full h-full max-h-full gap-2 overflow-y-hidden">
        <div className="flex h-full max-h-full flex-[2] overflow-y-hidden rounded-xl bg-white">
          <Sidebar
            visible={!sideBySide}
            currentTab={mainPanelTab}
            onChange={setMainPanelTab}
            resumeOnly={showCurrentProfile}
            uploadedVersion={!hasSCALISProfile}
          />
          <div className="w-full h-full overflow-y-hidden">
            <TabBar
              version="main"
              visible={sideBySide}
              currentTab={mainPanelTab as MainTab}
              onChange={setMainPanelTab}
              uploadedVersion={!hasSCALISProfile}
            />
            <div
              className={cn("h-full max-h-full", {
                "xl:max-h-[calc(100%_-_3.56rem)]": sideBySide,
              })}
            >
              <MainPanel
                data={data}
                notes={notes}
                userId={userId}
                activity={activity}
                showCurrentProfile={showCurrentProfile}
              />
            </div>
          </div>
        </div>
        {sideBySide && (
          <div className="flex-col flex-1 hidden h-full overflow-y-hidden bg-white rounded-xl xl:flex">
            <TabBar
              version="side"
              visible
              currentTab={sidePanelTab}
              onChange={setSidePanelTab}
            />
            <div className="h-full max-h-full overflow-y-auto">
              <SidePanel
                data={data}
                notes={notes}
                userId={userId}
                activity={activity}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};
