import { DotFilledIcon } from "@radix-ui/react-icons";
import { ScalisCandidate } from "~/types/pipeline";

export const Skill: React.FC<ScalisCandidate["skills"][number]> = ({
    name,
    experience,
    level,
}) => (
    <div className="flex items-center gap-1 whitespace-nowrap rounded-full bg-background-muted px-3 py-1 text-xs">
        <span className="text-xs">{name}</span>
        <DotFilledIcon />
        {level}
        <DotFilledIcon />
        {experience}
    </div>
);
