"use client";

import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { BodyRow } from "./components/body-row";
import { useListViewBody } from "./use-list-view-body";

export const ListViewBody = () => {
  const {
    table,
    dataIds,
    handleDragCancel,
    handleDragEnd,
    handleDragStart,
    sensors,
    getActiveRow,
  } = useListViewBody();

  const rows = table.getRowModel().rows;

  const RowOverlay = () => {
    const row = getActiveRow();

    if (row) {
      return <BodyRow key={`overlay-${row.id}`} row={row} />;
    }

    return null;
  };

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      sensors={sensors}
    >
      <SortableContext items={dataIds} strategy={verticalListSortingStrategy}>
        {rows.map(row => (
          <BodyRow key={row.id} row={row} />
        ))}
      </SortableContext>
      <DragOverlay style={{ width: "100%" }}>
        <RowOverlay />
      </DragOverlay>
    </DndContext>
  );
};
