import {
  GlobeAltIcon,
  MapPinIcon,
  TrophyIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { FaLink, FaRegEnvelope, FaRegUser } from "react-icons/fa6";
import { FiPhone } from "react-icons/fi";
import { formatPhoneNumber } from "react-phone-number-input";

import { TabProps } from "~/scalis-components/pipeline/detailed-candidate-view";
import { FormattedDetails } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/details.types";
import { detailsReducer } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/details.utils";
import { Section } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/section";
import { DetailsEntryTypeEnum } from "~/services/jobSeekerApplications.types";
import { TimezoneSection } from "./timezone-section";

export const DetailsTab: React.FC<TabProps> = ({
  data: { details: detailsRaw, ...data },
}) => {
  const details = React.useMemo(() => detailsRaw ?? [], [detailsRaw]);
  const detailsReducerDefaults = React.useMemo(
    () => ({
      emails: [
        {
          value: `${data.candidate.email} (SCALIS profile)`,
          index: -1,
        },
      ],
      phoneNumbers: [
        {
          value: `${formatPhoneNumber(data.candidate.phone) || data.candidate.phone} (SCALIS profile)`,
          index: -1,
        },
      ],
      locations: data.candidate.hasSCALISProfile
        ? [
            {
              value: `${data.candidate?.job_seeker_basicinfo?.city}, ${data.candidate?.job_seeker_basicinfo?.state} (SCALIS profile)`,
              index: -1,
            },
          ]
        : [],
      profiles: [],
      sources: [],
      medalistDesignations: [],
      timezone: { value: "", index: -1 },
    }),
    [data],
  );

  const formattedDetails = React.useMemo(
    () =>
      details.reduce<FormattedDetails>(detailsReducer, detailsReducerDefaults),
    [details],
  );

  return (
    <div className="flex h-full flex-col">
      <div className="border-b border-borders p-4">
        <span className="font-semibold text-typography-color">Details</span>
      </div>

      <div className="scroll-container">
        <div className="menu-candidate-view-details-scroll">
          <div className="flex flex-col gap-2">
            <div className="flex flex-col gap-1 border-b border-borders px-4 py-2">
              <div className="flex items-center gap-1 text-sm text-typography-high-contrast">
                <FaRegUser className="text-sm" />
                <span className="font-semibold">{`${data?.jobseeker_account?.job_seeker_basicinfo?.f_name} ${data?.jobseeker_account?.job_seeker_basicinfo?.l_name}`}</span>
              </div>
              <span className="pl-4 text-sm text-typography-low-contrast-text">{`Job Seeker ID# ${data?.jobseeker_account?.id.toString().padStart(6, "0")}`}</span>
            </div>

            <Section
              type={DetailsEntryTypeEnum.Email}
              title="Email"
              icon={<FaRegEnvelope className="text-sm" />}
              applicationId={data.id}
              items={formattedDetails.emails}
            />
            <Section
              type={DetailsEntryTypeEnum.Phone}
              title="Phone number"
              icon={<FiPhone className="h-4 w-4" />}
              applicationId={data.id}
              items={formattedDetails.phoneNumbers}
            />
            <Section
              type={DetailsEntryTypeEnum.Location}
              title="Location"
              icon={<MapPinIcon className="h-4 w-4" />}
              applicationId={data.id}
              items={formattedDetails.locations}
            />
            <Section
              type={DetailsEntryTypeEnum.Profile}
              title="Existing Profile"
              icon={<FaLink className="text-sm" />}
              applicationId={data.id}
              items={formattedDetails.profiles}
            />
            <Section
              type={DetailsEntryTypeEnum.Source}
              title="Source"
              icon={<GlobeAltIcon className="h-4 w-4" />}
              items={formattedDetails.sources}
              applicationId={data.id}
              companyId={data.job_id.user.companyId}
            />
            <Section
              type={DetailsEntryTypeEnum.MedalistDesignation}
              title="Medalist Designation"
              icon={<TrophyIcon className="h-4 w-4" />}
              items={formattedDetails.medalistDesignations}
              applicationId={data.id}
              seekerId={data.seekerId}
            />
            <TimezoneSection data={{ ...data, details }} />
          </div>
        </div>
      </div>
    </div>
  );
};
