import { Badge } from "~/components/v2/Badge";
import { Option } from "../select.types";
import { Button } from "~/components/v2/Button";

export const BadgeView = ({
  v,
  removeJob,
  options,
}: {
  v: any;
  removeJob: (v: any) => void;
  options: Option[];
}) => (
  <div className="mt-2 flex gap-2">
    <Button
      variant="ghost"
      className="flex cursor-pointer items-center gap-2 rounded-full bg-primary-background-hover px-3 py-0 text-sm font-semibold"
      onClick={() => removeJob(v)}
    >
      {options.find(elem => elem.value === v)?.label}
      <i className="fa-light fa-xmark text-icons-secondary" />
    </Button>
  </div>
);
