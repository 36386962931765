export const getMatchScoreBgColor = (score: number) => {
  const MIN = "1";
  const MAX = "8";

  const decimal = Math.round(score / 10);

  if (decimal < parseInt(MIN)) return MIN;

  return decimal >= parseInt(MAX) ? MAX : decimal.toString();
};

export const getRoundMatchScore = (score: number) => {
  return Math.round(score * 100);
};
