export const REJECT_REASON_OPTIONS = [
  {
    label: "Doesn't meet minimum qualifications",
    value: "Doesn't meet minimum qualifications",
  },
  {
    label: "Overqualified for position",
    value: "Overqualified for position",
  },
  {
    label: "Lack of Relevant skills & abilities",
    value: "Lack of Relevant skills & abilities",
  },
  {
    label: "Lack of relevant experience",
    value: "Lack of relevant experience",
  },
  {
    label: "Inflated responsibilities",
    value: "Inflated responsibilities",
  },
  {
    label: "Poor interview performance",
    value: "Poor interview performance",
  },
  {
    label: "Poor communication skills",
    value: "Poor communication skills",
  },
  {
    label: "Lacked clarity in answers",
    value: "Lacked clarity in answers",
  },
  {
    label: "Impractical job expectations",
    value: "Impractical job expectations",
  },
  {
    label: "Unprofessional behavior during hiring process",
    value: "Unprofessional behavior during hiring process",
  },
  {
    label: "Better qualified candidate(s) chosen, education",
    value: "Better qualified candidate(s) chosen, education",
  },
  {
    label: "Better qualified candidate(s) chosen, skills",
    value: "Better qualified candidate(s) chosen, skills",
  },
  {
    label: "Better qualified candidates chosen, experience",
    value: "Better qualified candidates chosen, experience",
  },
  {
    label: "Position eliminated",
    value: "Position eliminated",
  },
  {
    label: "Position on hold",
    value: "Position on hold",
  },
  {
    label: "Position already filled",
    value: "Position already filled",
  },
  {
    label: "Failed drug test",
    value: "Failed drug test",
  },
  {
    label: "Failed background check",
    value: "Failed background check",
  },
  {
    label: "Failed assessment",
    value: "Failed assessment",
  },
  {
    label: "Failed to complete required assessment",
    value: "Failed to complete required assessment",
  },
  {
    label: "Interview no show",
    value: "Interview no show",
  },
  {
    label: "Requires visa sponsorship",
    value: "Requires visa sponsorship",
  },
  {
    label: "Job not aligned with career objectives",
    value: "Job not aligned with career objectives",
  },
  {
    label: "Unable to relocate as required",
    value: "Unable to relocate as required",
  },
  {
    label: "Candidate applied to wrong position",
    value: "Candidate applied to wrong position",
  },
  {
    label: "Limited availability or scheduling conflicts",
    value: "Limited availability or scheduling conflicts",
  },
  {
    label: "Salary expectations too high",
    value: "Salary expectations too high",
  },
  {
    label: "Failed to respond",
    value: "Failed to respond",
  },
  {
    label: "Declined interview",
    value: "Declined interview",
  },
  {
    label: "Candidate withdrawn",
    value: "Candidate withdrawn",
  },
  {
    label: "Offer Rejected: Doesn't meet salary expectations",
    value: "Offer Rejected: Doesn't meet salary expectations",
  },
  {
    label: "Offer Rejected: Job location",
    value: "Offer Rejected: Job location",
  },
  {
    label: "Offer Rejected: Job schedule",
    value: "Offer Rejected: Job schedule",
  },
  {
    label: "Offer Rejected: Other",
    value: "Offer Rejected: Other",
  },
  {
    label: "Other",
    value: "Other",
  },
  {
    label: "Negative References",
    value: "Negative References",
  },
  {
    label: "Duplicate Application",
    value: "Duplicate Application",
  },
  {
    label: "Missing required certifications",
    value: "Missing required certifications",
  },
  {
    label: "Lacked specific technical knowledge",
    value: "Lacked specific technical knowledge",
  },
];

export const MEDALIST_STATUS = {
  SILVER: "Silver",
  BRONZE: "Bronze",
  NONE: "None",
} as const;

export const MEDALIST_STATUS_OPTIONS = [
  {
    label: "Silver Medalist",
    value: MEDALIST_STATUS.SILVER,
  },
  {
    label: "Bronze Medalist",
    value: MEDALIST_STATUS.BRONZE,
  },
  {
    label: "None",
    value: MEDALIST_STATUS.NONE,
  },
];
