import { useContext } from "react";
import { GetFilteredCandidatesContext } from "./get-filtered-candidates-context";

export const useGetFilteredCandidates = () => {
  const { isLoadingMilestones, milestones, milestonesError } = useContext(
    GetFilteredCandidatesContext,
  );

  return {
    milestones,
    isLoadingMilestones,
    milestonesError,
  };
};
