import { DefaultMessages } from "@prisma/client";

export const getDefaultMessage = (
  defaultMessages: Partial<DefaultMessages>[],
  value: string,
) => {
  const selectedMessage = defaultMessages?.find(
    message => message.id === parseInt(value),
  )?.message;
  return selectedMessage;
};
