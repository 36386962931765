import React from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { RatingSectionProps } from "./rating-section.types";
import { SectionContainer } from "../../../section-container";
import { RatingField } from "../rating-field";

export const RatingSection: React.FC<RatingSectionProps> = ({ questions }) => {
  const { setValue, watch } = useFormContext();
  const { errors } = useFormState();

  return (
    <SectionContainer title="Rating">
      <div className="flex flex-col gap-4">
        {questions.map(({ id, text }) => {
          const name = String(id);

          return (
            <RatingField
              key={name}
              label={text}
              name={name}
              setValues={setValue}
              error={errors[id]?.message as string}
              value={watch(name)}
            />
          );
        })}
      </div>
    </SectionContainer>
  );
};
