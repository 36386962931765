"use client";

import React from "react";
import { Dialog, DialogContent } from "~/components/v2/Dialog";
import { Button } from "~/components/v2/Button";
import { RejectCandidatesModalProps } from "./reject-candidate-modal.types";
import { useRejectCandidate } from "../reject-candidate-context";
import { useRejectCandidates } from "~/hooks/pipelines/use-reject-candidates";
import { errorHandler } from "~/utils/error";
import { AxiosError } from "axios";
import { useToast } from "~/hooks/useToast";

export const RejectCandidatesModal: React.FC<RejectCandidatesModalProps> = ({
  open,
  setOpen,
}) => {
  const { toast } = useToast();
  const { resetRejectCandidateData, rejectCandidateData } =
    useRejectCandidate();

  const { mutate: postRejectCandidates, isPending: isLoading } =
    useRejectCandidates({
      onSuccess: () => {
        resetRejectCandidateData();
        setOpen(false);
        window?.location?.reload();
      },
      onError: e => {
        errorHandler(toast, e as AxiosError<any>);
      },
    });

  const handleReject = () => {
    postRejectCandidates(rejectCandidateData);
  };

  const handleCancel = () => {
    resetRejectCandidateData();
    setOpen(false);
  };

  return (
    <Dialog open={open}>
      <DialogContent hideClose className="max-w-[420px] py-6">
        <div className="flex flex-col">
          <h1 className="text-base font-medium">
            <i className="fa-solid fa-triangle-exclamation mr-2 text-destructive-default" />
            Reject Candidates
          </h1>

          <span className="mt-2 text-sm font-normal text-white-70">
            Are you sure you want to reject this candidate(s)? This action
            cannot be undone.
          </span>

          <div className="mt-6 flex justify-between gap-2">
            <Button variant="outline" className="flex-1" onClick={handleCancel}>
              <span className="text-base font-semibold">Cancel</span>
            </Button>

            <Button
              className="flex-1 bg-destructive-default text-white hover:bg-destructive-hover active:bg-destructive-pressed"
              onClick={handleReject}
              variant="destructive"
              isLoading={isLoading}
            >
              Reject
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
