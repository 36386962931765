import axios from "~/services/axios.service";
import { PostUploadCandidateSchema } from "~/types/jobseeker/upload-candidate";

export const uploadCandidate = async ({
  data,
}: {
  data: PostUploadCandidateSchema;
}) => {
  const response = await axios.post(`/api/jobseeker/uploadCandidate`, data);
  if (response.status >= 400) {
    throw new Error("Error uploading candidate");
  }
  return response.data;
};
