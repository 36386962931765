export const APPLICATION_TYPE = {
    DYNAMIC: "DYNAMIC",
    STATIC: "STATIC",
  } as const;
  
  export const SOURCES = {
    SOURCED: { name: "Sourced", value: "SOURCED" },
    DIRECT_APPLICANT: { name: "Direct Applicant", value: "DIRECT_APPLICANT" },
    AGENCY: { name: "Agency", value: "AGENCY" },
    REFERRED: { name: "Referred", value: "REFERRED" },
    UNIVERSITY: { name: "University", value: "UNIVERSITY" },
    INTERNAL: { name: "Internal", value: "INTERNAL" },
  };
  
  export const MILESTONE_TYPE = {
    SOURCING: "Sourcing",
    APPLICATION_REVIEW: "Application Review",
    PRE_SCREEN: "Pre-Screen",
    CUSTOM: "Custom",
    OFFER: "Offer",
    HIRED: "Hired",
  };
  
  export const VISIBILITY = {
    INTERNAL: "Internal",
    PUBLIC: "Public",
  };
  
  export const MILESTONE_BG_DEFAULT = "bg-[#E5C9FB]";
  
  export const MILESTONE_BG = {
    APPLICATION_REVIEW: "bg-[#C9D5FB]",
    PRE_SCREEN: "bg-[#BDE9FD]",
    CUSTOM: "bg-[#C8FBF6]",
    OFFER: "bg-[#DFFFB1]",
    HIRED: "bg-[rgb(166,251,208)]",
  };