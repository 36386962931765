import { ScreeningQuestion } from "~/types/postJob";

export const checkAnswer = (
  answer: string,
  question: Omit<ScreeningQuestion, "title">,
) => {
  switch (question.type) {
    case "binary":
      return question.answer.binary_ans === answer;
    case "multiple":
      return question.answer.multi_ans === answer;
    case "numerical":
      switch (question.answer.option) {
        case "to":
          const from = Number(question.answer.range1);
          const to = Number(question.answer.range2);
          const answerNum = Number(answer);
          return answerNum >= from && answerNum <= to;
        case "greater":
          return Number(answer) >= Number(question.answer.range1);
        case "less":
          return Number(answer) <= Number(question.answer.range2);
      }
    default:
      return true;
  }
};
