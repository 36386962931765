import { useFormContext } from "react-hook-form";

import { useContext } from "react";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/components/v2/Form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/v2/Select";
import { ScheduleInterviewContext } from "~/scalis-components/pipeline/detailed-candidate-view/components/schedule-interview/schedule-interview-context";
import {
  FIELD_NAME_CALENDAR,
  FORM_FIELDS,
} from "../../scheduler-link-form.constants";

export const CalendarField = () => {
  const { control } = useFormContext();
  const { calendars } = useContext(ScheduleInterviewContext);

  const { label, placeholder } = FORM_FIELDS[FIELD_NAME_CALENDAR];

  return (
    <FormField
      control={control}
      name={FIELD_NAME_CALENDAR}
      render={({ field }) => (
        <FormItem className="space-y-1" data-testid="selectCalendar">
          <FormLabel className="text-sm font-semibold text-typography-high-contrast">
            {label}
          </FormLabel>
          <Select onValueChange={field.onChange} defaultValue={field.value}>
            <FormControl>
              <SelectTrigger className="w-full h-8 rounded-lg">
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
            </FormControl>
            <SelectContent className="bg-white">
              {calendars?.map(calendar => (
                <SelectItem value={String(calendar.id)} key={calendar.id}>
                  {calendar.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
