import React from "react";
import { Label } from "~/components/v2/Label";
import { RadioGroup, RadioGroupItem } from "~/components/v2/RadioGroup";
import { ScheduleRadioProps } from "./schedule-radio.types";
import {
  FIELD_NAME_SCHEDULE_RADIO,
  LABEL_SCHEDULE_RADIO,
  SCHEDULE_OPTIONS,
  ScheduleOptionEnum,
} from "./schedule-radio.constants";
import { AdvanceCandidateDrawerContext } from "../advance-candidate-drawer-context";

export const ScheduleRadio: React.FC<ScheduleRadioProps> = ({
  onValueChange,
  value = ScheduleOptionEnum.SendSchedulerLink,
}) => {

  React.useEffect(() => {
    setShowInterviewerSelection?.(true);
    onValueChange?.(FIELD_NAME_SCHEDULE_RADIO, ScheduleOptionEnum.SendSchedulerLink.toString())
  }, []);

  const handleValueChange = (value: string) => {
    setShowInterviewerSelection?.(!!parseInt(value));
    onValueChange?.(FIELD_NAME_SCHEDULE_RADIO, value)
  }

  const { setShowInterviewerSelection } = React.useContext(AdvanceCandidateDrawerContext);

  return (
    <div className="flex flex-col gap-4">
      <div className="text-base font-semibold text-typography-high-contrast">
        {LABEL_SCHEDULE_RADIO}
      </div>
      <RadioGroup
        name={FIELD_NAME_SCHEDULE_RADIO}
        defaultValue={value.toString()}
        onValueChange={handleValueChange}
      >
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={ScheduleOptionEnum.ScheduleLater.toString()} id="schedule-later" />
          <Label htmlFor="schedule-later">
            {SCHEDULE_OPTIONS.SCHEDULE_LATER}
          </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value={ScheduleOptionEnum.SendSchedulerLink.toString()} id="send-schedule-link" />
          <Label htmlFor="send-schedule-link">
            {SCHEDULE_OPTIONS.SEND_SCHEDULER_LINK}
          </Label>
        </div>
      </RadioGroup>
    </div>
  );
};
