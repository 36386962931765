"use client";

import React from "react";
import { Drawer, DrawerContent, DrawerHeader } from "~/components/v2/Drawer";
import { RejectCandidateDrawerProps } from "./reject-candidate-drawer.types";
import { StepSelectCandidates } from "./components/step-select-candidates";
import { StepRatingCandidates } from "./components/step-rating-candidates/step-rating-candidates";
import { useGetApplicationsByJobId } from "~/hooks/applications/useGetApplicationsByJobId";
import { mapCandidatesDataToCandidates } from "./reject-candidate-drawer.utils";
import { useGetApplicationsRejectQuestions } from "~/hooks/applications/use-get-applications-reject-questions";
import useGetCompanyDefaultMessages from "~/hooks/defaultMessages/useGetCompanyDefaultMessages";
import { MessagType } from "~/queries/defaultMessages/fetchCompanyDefaultMessages";

export const RejectCandidateDrawer = ({
  jobId,
  companyId,
  open,
  setOpen,
  onSuccess,
  currentStep = 1,
}: RejectCandidateDrawerProps) => {
  const [step, setStep] = React.useState(currentStep);

  const { data: defaultMessages, isLoading: isLoadingMessages } =
    useGetCompanyDefaultMessages(companyId, MessagType.REJECT);

  const { data: candidatesData, isLoading: isLoadingCandidates } =
    useGetApplicationsByJobId(jobId);

  const { data: questions, isLoading: isLoadingQuestions } =
    useGetApplicationsRejectQuestions();

  const candidates = mapCandidatesDataToCandidates(candidatesData || []);

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerContent asChild>
        <div data-vaul-no-drag>
          <DrawerHeader
            className="text-left"
            actions={
              <span className="text-neutral-secondary">Step {step}/2</span>
            }
          >
            <h1 className="text-xl font-semibold text-typography-high-contrast">
              Reject candidates
            </h1>
          </DrawerHeader>

          {step === 1 ? (
            <StepSelectCandidates
              jobId={jobId}
              defaultMessages={defaultMessages}
              candidates={candidates}
              isLoading={isLoadingCandidates || isLoadingMessages}
              onSubmit={() => {
                setStep(2);
              }}
              onCancel={() => {
                setOpen(false);
              }}
            />
          ) : (
            <StepRatingCandidates
              questions={questions}
              isLoadingQuestions={isLoadingQuestions}
              onSubmit={() => {
                onSuccess();
                setOpen(false);
                setStep(1);
              }}
              onCancel={() => setOpen(false)}
            />
          )}
        </div>
      </DrawerContent>
    </Drawer>
  );
};
