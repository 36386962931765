"use client";

import * as PopoverPrimitive from "@radix-ui/react-popover";
import React, { forwardRef, useState } from "react";
import { Button } from "~/components/v2/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/components/v2/Command";
import { Popover, PopoverTrigger } from "~/components/v2/Popover";
import { ScrollArea } from "~/components/v2/ScrollArea";
import { cn } from "~/utils/cn";
import { BadgeView } from "./components/badge-view";
import { ICombobox } from "./select.types";
import { useSelect } from "./use-select";

export const Select = forwardRef<HTMLButtonElement, ICombobox>(
  (
    {
      emptyTerm,
      multi,
      name,
      options = [],
      placeholder,
      searchPlaceholder,
      setValue,
      value,
      searchByLabel,
      disabled,
      className,
      icon = (
        <i className="fa-regular fa-chevron-down transition-all group-data-[state=open]:rotate-180" />
      ),
      onBlur,
      onChange,
      ...props
    },
    ref,
  ) => {
    const { getLabel, handleSelect, open, removeJob, setOpen } = useSelect({
      name,
      options,
      setValue,
      value,
      multi: !!multi,
    });

    return (
      <>
        <Popover open={open} onOpenChange={() => setOpen(state => !state)}>
          <PopoverTrigger asChild>
            <Button
              ref={ref}
              variant="outline"
              role="combobox"
              aria-label={props["aria-label"]}
              disabled={disabled}
              className={cn(
                "group justify-between border-borders font-normal text-typography-low-contrast hover:bg-white active:bg-white disabled:opacity-50",
                value && "text-muted-foreground",
                className,
              )}
            >
              {multi ? `Select ${emptyTerm}` : getLabel(value) || placeholder}
              {icon}
            </Button>
          </PopoverTrigger>
          <PopoverPrimitive.Content
            className="text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 w-popover-trigger rounded-md border bg-white p-0 shadow-md outline-none"
            sideOffset={0}
          >
            <Command>
              <CommandInput placeholder={searchPlaceholder} className="h-9" />
              <CommandEmpty>No {emptyTerm} found.</CommandEmpty>
              <CommandList>
                <ScrollArea className="h-[200px]">
                  <CommandGroup className="overflow-none">
                    {options.map(option => (
                      <CommandItem
                        value={searchByLabel ? option.label : option.value}
                        key={option.value}
                        disabled={option.disabled}
                        onSelect={() => handleSelect(option.value)}
                        className={cn(
                          "line-clamp-1 flex cursor-pointer items-center gap-2 hover:bg-background-hover",
                          value?.includes(option.value) &&
                            "bg-background-pressed hover:bg-background-pressed",
                        )}
                      >
                        <div
                          className={cn(
                            "flex size-4 items-center justify-center rounded border border-solid border-actions-secondary",
                            value?.includes(option.value) &&
                              "bg-actions-secondary",
                          )}
                        >
                          {value?.includes(option.value) && (
                            <i className="fa-solid fa-check text-white" />
                          )}
                        </div>
                        <div className="text-base text-typography-high-contrast">
                          {option.label}
                        </div>
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </ScrollArea>
              </CommandList>
            </Command>
          </PopoverPrimitive.Content>
        </Popover>

        {multi && (
          <div className="flex max-h-28 flex-wrap gap-1 overflow-y-auto">
            {value.map((v: any) => {
              return (
                <BadgeView
                  key={v}
                  v={v}
                  removeJob={removeJob}
                  options={options}
                />
              );
            })}
          </div>
        )}
      </>
    );
  },
);
