import { useQuery } from "@tanstack/react-query";
import { fetchCandidateFilterOptionsView } from "~/queries/fetch-candidate-filter-options";

export const useGetCandidateFilterOptions = (
  jobId: number,
  filterSelects: string[],
) => {
  const { data, isLoading } = useQuery({
    queryKey: ["filter-options", jobId],
    queryFn: params =>
      fetchCandidateFilterOptionsView(params, { jobId, filterSelects }),
  });

  return { filterOptions: data, isLoadingFilterOptions: isLoading };
};
