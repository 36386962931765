import { z } from "zod";
import * as formConst from "./step-select-candidates.constants";

export type FormValues = z.infer<typeof formSchema>;

const { FORM_FIELDS } = formConst;

export const formSchema = z
  .object({
    [formConst.FIELD_CANDIDATES]: z
      .array(
        z.object({
          id: z.number(),
          name: z.string().optional(),
          email: z.string().email({ message: "Invalid email" }),
        }),
      )
      .min(1, FORM_FIELDS[formConst.FIELD_CANDIDATES].errorMessage),
    [formConst.FIELD_NAME_CUSTOM_MESSAGE]: z.string().optional(),
    [formConst.FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE]: z.boolean().optional(),
    [formConst.FIELD_NAME_DEFAULT_MESSAGE]: z.string().optional(),
    [formConst.FIELD_NAME_REJECT_REASON]: z
      .string()
      .min(1, FORM_FIELDS[formConst.FIELD_NAME_REJECT_REASON].errorMessage),
    [formConst.FIELD_NAME_MEDALIST_STATUS]: z.string().optional(),
    [formConst.FIELD_NAME_ADDIONAL_DETAILS]: z.string().optional(),
  })
  .superRefine(
    (
      {
        cb_default_message,
        default_message,
        candidates,
        custom_message,
        medalistDesignation,
        details,
      }: any,
      ctx: {
        addIssue: (arg0: {
          code: "custom";
          message: string;
          path: string[];
        }) => any;
      },
    ) => {
      if (!cb_default_message) {
        const parsedMessage = custom_message?.replace(/<[^>]*>/g, "");
        if (!parsedMessage) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Custom message is required",
            path: [formConst.FIELD_NAME_CUSTOM_MESSAGE],
          });
        }
      }
      if (cb_default_message && !default_message) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Default message is required",
          path: [formConst.FIELD_NAME_DEFAULT_MESSAGE],
        });
      }
      if (candidates.length <= 1 && !medalistDesignation) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Select the medalist status",
          path: [formConst.FIELD_NAME_MEDALIST_STATUS],
        });
      }
    },
  );
