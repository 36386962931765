import { CheckedState } from "@radix-ui/react-checkbox";
import { z } from "zod";
import { FIELD_NAME_JOB_SELECT } from "../job-select/job-select.constants";
import { FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE, FIELD_NAME_CUSTOM_MESSAGE, FIELD_NAME_DEFAULT_MESSAGE } from "~/scalis-components/pipeline/candidate-drawer";

export interface MatchCandidateDrawerFormProps {
  isSubmit?: boolean;
  onSubmit?: (data: FormValues) => void;
  onErrorSubmit?: (error: any) => void;
}
export interface FormValues {
  jobId?: string;
  should_notify_candidate?: boolean;
  default_message?: string;
  cb_default_message?: CheckedState;
  custom_message?: string;
}

export const createSchema = (
  isStaticProfile?: boolean,
  isNotifyCandidate?: boolean,
) => {
  return z
    .object({
      [FIELD_NAME_JOB_SELECT]: z.string({
        required_error: "Stage is required",
      }),
      [FIELD_NAME_CUSTOM_MESSAGE]: z.string().optional(),
      [FIELD_NAME_CHECKBOX_DEFAULT_MESSAGE]: z.boolean().optional(),
      [FIELD_NAME_DEFAULT_MESSAGE]: z.string().optional(),
    })
    .superRefine(
      (
        { cb_default_message, default_message, custom_message }: any,
        ctx: {
          addIssue: (arg0: {
            code: "custom";
            message: string;
            path: string[];
          }) => any;
        },
      ) => {
        if (isStaticProfile || !isNotifyCandidate) return;

        if (!cb_default_message) {
          const parsedMessage = custom_message?.replace(/<[^>]*>/g, "");
          if (!parsedMessage) {
            return ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Custom message is required",
              path: [FIELD_NAME_CUSTOM_MESSAGE],
            });
          }
        }
        if (cb_default_message && !default_message) {
          return ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Default message is required",
            path: [FIELD_NAME_DEFAULT_MESSAGE],
          });
        }
      },
    );
};
