import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "~/services/axios.service";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/v2/Tooltip";
import { StarRating } from "./components/star-rating";

export interface IJobApplicationStarRating {
  applicationId: number;
  empId: number;
  jobId: number;
  seekerId: number;
  className?: string;
}

const fetchComputedRate = async (jobId: number, seekerId: number) => {
  const response = await axios.get("/api/job/application/rate", {
    params: {
      jobId,
      seekerId,
    },
  });

  return response.data.scores;
};

const fetchUserRate = async (applicationId: number, empId: number) => {
  const response = await axios.get(`/api/job/application/rate/${empId}`, {
    params: {
      applicationId,
    },
  });

  return response.data.score;
};

const upsertUserRate = async (
  applicationId: number,
  empId: number,
  score: number,
) => {
  const response = await axios.post(`/api/job/application/rate/${empId}`, {
    applicationId,
    empId,
    score,
  });

  return response.data;
};

export const JobApplicationStarRating = ({
  applicationId,
  empId,
  jobId,
  seekerId,
  className,
}: IJobApplicationStarRating) => {
  const queryClient = useQueryClient();

  const { data: computedRate } = useQuery({
    queryKey: ["computedRate", jobId, seekerId],
    queryFn: () => fetchComputedRate(jobId, seekerId),
  });

  const { data: userRate } = useQuery({
    queryKey: ["userRate", applicationId, empId],
    queryFn: () => fetchUserRate(applicationId, empId),
  });

  const { mutateAsync } = useMutation({
    mutationKey: ["upsertUserRate", applicationId, empId],
    mutationFn: (score: number) => upsertUserRate(applicationId, empId, score),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["computedRate", jobId, seekerId],
      });
      queryClient.invalidateQueries({
        queryKey: ["userRate", applicationId, empId],
      });
    },
  });

  const handleSubmit = (value: number) => {
    mutateAsync(value);
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <StarRating
            activeStars={computedRate || 0}
            onClick={handleSubmit}
            className={className}
          />
        </TooltipTrigger>
        <TooltipContent>
          <div className="flex flex-col gap-1">
            <span>Your rate: {userRate?.score || "N/A"}</span>
            <span>Rating average: {computedRate}</span>
          </div>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
