import { ChangeEventHandler, FC, PropsWithChildren, useRef } from "react";

import { cn } from "~/utils/cn";

interface Props {
  onChange: (value: File | FileList | null) => void;
  multiple?: boolean;
  disabled?: boolean;
  className?: string;
}

const ButtonFileInput: FC<PropsWithChildren<Props>> = ({
  children,
  disabled,
  multiple,
  onChange,
  className,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
    onChange(multiple ? e.target.files : e.target.files?.[0] || null);
  };
  const clear = () => {
    if (!ref.current) return;
    ref.current.files = null;
  };
  return (
    <>
      <label
        data-disabled={disabled}
        role="button"
        htmlFor="upload-file-input"
        className={cn(
          "mt-4 flex h-10 w-36 items-center justify-center gap-2 rounded-xl border border-primary bg-white text-sm font-semibold text-primary hover:bg-primary-background active:bg-primary-background-hover data-[disabled=true]:cursor-default data-[disabled=true]:border-none data-[disabled=true]:bg-background-muted data-[disabled=true]:text-actions-muted",
          className,
        )}
        onClick={clear}
      >
        {children}
      </label>
      <input
        disabled={disabled}
        multiple={multiple}
        onChange={handleChange}
        type="file"
        hidden
        id="upload-file-input"
        ref={ref}
      />
    </>
  );
};

export default ButtonFileInput;
