import React, { useMemo } from "react";
import About from "~/components/jobseeker/About";
import Achievements from "~/components/jobseeker/Achievements";
import Certificates from "~/components/jobseeker/Certificates";
import Demographics from "~/components/jobseeker/Demographics";
import Education from "~/components/jobseeker/Education";
import Experience from "~/components/jobseeker/Experience";
import Skills from "~/components/jobseeker/Skills";
import SocialMedia from "~/components/jobseeker/SocialMedia";
import VolunteerWork from "~/components/jobseeker/VolunteerWork";
import { useGetInformation } from "~/hooks/jobseeker/useGetInformation";
import { TabProps } from "~/scalis-components/pipeline/detailed-candidate-view";
import { candidateInformation } from "./resume.utils";

const sections = [
  { Component: About, key: "About" },
  { Component: Experience, key: "Experience" },
  { Component: Education, key: "Education" },
  { Component: VolunteerWork, key: "VolunteerWork" },
  { Component: Skills, key: "Skills" },
  { Component: Certificates, key: "Certificates" },
  { Component: Achievements, key: "Achievements" },
  { Component: SocialMedia, key: "SocialMedia" },
  { Component: Demographics, key: "Demographics" },
];

export const ResumeTab: React.FC<TabProps> = ({ data, showCurrentProfile }) => {
  const shouldFetchCandidateProfile =
    data.candidate.hasSCALISProfile &&
    (showCurrentProfile || !data.snapshot_candidate);

  const { data: fetchedData, refetch } = useGetInformation(
    shouldFetchCandidateProfile ? data.candidate.id : undefined,
  );
  const snapshotData = candidateInformation(data);

  const jobSeekerData = fetchedData || snapshotData;

  const sectionProps = useMemo(
    () => ({
      data: jobSeekerData,
      loading: !jobSeekerData,
      refetch,
      isEdition: false,
    }),
    [jobSeekerData, refetch],
  );

  return (
    <div className="flex h-full flex-col">
      <div className="border-b border-borders p-4">
        <span className="font-semibold text-typography-color">Resume</span>
      </div>
      <div className="scroll-container">
        <div className="menu-candidate-view-details-scroll">
          {sections.map(({ Component, key }, index) => (
            <React.Fragment key={key}>
              <Component {...sectionProps} />
              {index < sections.length - 1 && (
                <div className="h-[4px] bg-background-blue" />
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};
