import { useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import React from "react";
import { Button } from "~/components/v2/Button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
} from "~/components/v2/Drawer";
import { useUploadCandidate } from "~/hooks/jobseeker/uploaded-candidate/use-uploaded-candidate";
import { useToast } from "~/hooks/useToast";
import { errorHandler } from "~/utils/error";
import {
  FormValues,
  UploadCandidateDrawerForm,
} from "./components/upload-candiate-drawer-form";
import {
  LABEL_BUTTON_CANCEL,
  LABEL_BUTTON_CONFIRM,
} from "./upload-candidate-drawer.constants";
import { UploadCandidateDrawerProps } from "./upload-candidate-drawer.types";
import { parseFormData } from "./upload-candidate-drawer.utils";

export const UploadCandidateDrawer = ({
  open,
  onOpenChange,
  onSubmit,
  jobTitle,
  jobId,
  companyId,
  defaultValues,
}: UploadCandidateDrawerProps) => {
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false);
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const {
    mutate,
    isPending: isLoading,
    error,
  } = useUploadCandidate({
    onSuccess: () => {
      onOpenChange?.(false);
      queryClient.invalidateQueries({ queryKey: ["job-applications"] });
      queryClient.invalidateQueries({ queryKey: ["milestones"] });
      queryClient.invalidateQueries({ queryKey: ["past-candidates"] });
      toast({
        title: "Candidate uploaded successfully",
      });
    },
    onError: (error: any) => {
      errorHandler(toast, error as AxiosError<any>);
    },
  });

  const handleSubmit = (data: FormValues) => {
    const body = parseFormData(data, jobId, companyId);
    setIsSubmit(false);
    mutate(body);
    if (error) {
      errorHandler(toast, error as AxiosError<any>);
    }
  };

  const onErrorSubmit = (error: any) => {
    console.error(error);
    setIsSubmit(false);
  };

  return (
    <Drawer open={open} onOpenChange={onOpenChange}>
      <DrawerContent asChild>
        <div>
          <DrawerHeader className="text-center">
            <h1 className="text-xl font-semibold text-typography-high-contrast">
              Upload Candidate
            </h1>
            <h2 className="text-sm text-typography-low-contrast">{jobTitle}</h2>
          </DrawerHeader>
          <UploadCandidateDrawerForm
            onSubmit={(data: FormValues) => {
              if (onSubmit) {
                onSubmit(data);
                return;
              }
              handleSubmit(data);
            }}
            isSubmit={isSubmit}
            onErrorSubmit={onErrorSubmit}
            defaultValues={defaultValues}
          />
          <DrawerFooter className="justify-end">
            <DrawerClose asChild>
              <Button variant="outline">{LABEL_BUTTON_CANCEL}</Button>
            </DrawerClose>
            <Button
              aria-label="Confirm"
              className="min-w-20"
              isLoading={isLoading}
              onClick={() => setIsSubmit(true)}
            >
              {LABEL_BUTTON_CONFIRM}
            </Button>
          </DrawerFooter>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
