import React, { useState } from "react";
import { FaArrowUpFromBracket } from "react-icons/fa6";

import Spinner from "~/components/spinner";
import ButtonFileInput from "~/components/v2/ButtonFileInput";
import { useGetApplicationsByApplicants } from "~/hooks/applications/useGetApplicationsByApplicants";
import useDocumentUpload from "~/hooks/useDocumentUpload";
import { UploadFile } from "~/scalis-components/pipeline/detailed-candidate-view";
import { CustomCombobox } from "~/scalis-components/pipeline/detailed-candidate-view/components/custom-combobox";
import { Document } from "./components/document";

export const DocumentsTab: React.FC<{ data: any; userId: number }> = ({
  data,
  userId,
}) => {
  const { handleUpload, loading } = useDocumentUpload({
    employerId: userId,
    applicationId: data.id,
    uploader: "employer",
  });

  const [process, setProcess] = useState<number[]>([data.jobId]);

  const { data: applications } = useGetApplicationsByApplicants(
    "all",
    data.jobseeker_account?.id,
    {
      filters: { companyId: data.job_id.user.companyId },
      limit: 100,
      includeStages: false,
      sortBy: "job_title",
      sortDirection: "desc",
    },
  );

  const options =
    applications?.docs.map(item => ({
      label: item?.job_title || "",
      value: item?.jobId,
    })) || [];

  const uploadFiles = (data?.job_id.uploaded_files ?? []) as UploadFile[];
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <div className="flex items-center justify-between border-b px-4 py-[10px]">
          <span className="font-semibold text-typography-color">
            Screening Questions
          </span>
          <CustomCombobox
            setValue={setProcess}
            placeholder="Select job process"
            searchPlaceholder="Search..."
            options={options}
            value={process}
            emptyTerm="process"
            name="job process"
            className="w-full xs:w-40 sm:w-48 md:w-64"
          />
        </div>
        <div className="grid grid-cols-3 p-4 text-sm border-b border-borders text-typography-high-contrast">
          <div className="col-span-1">
            <span>File</span>
          </div>
          <div className="col-span-2">
            <span>Job</span>
          </div>
        </div>

        <div className="scroll-container">
          <div className="menu-candidate-view-details-scroll">
            <div className="flex-1">
              {uploadFiles?.map(file => <Document key={file.id} {...file} />)}
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end px-10 py-4 border-t border-borders">
        <ButtonFileInput
          disabled={loading}
          onChange={handleUpload}
          className="m-0 w-fit border border-primary bg-actions p-4 font-semibold text-white hover:bg-actions-hover active:bg-actions-pressed data-[disabled=true]:border-white-80 data-[disabled=true]:bg-white-95 data-[disabled=true]:text-white-80"
        >
          {loading ? (
            <Spinner />
          ) : (
            <>
              Upload file <FaArrowUpFromBracket className="text-sm" />
            </>
          )}
        </ButtonFileInput>
      </div>
    </div>
  );
};
