"use client";

import { flexRender, Header } from "@tanstack/react-table";

import { useSortable } from "@dnd-kit/sortable";
import { useListViewHeader } from "../use-list-view-header";
import { ResizeIcon } from "./resize-icon";
import { HeaderCellActions } from "./header-cell-actions";
import {
  container,
  contentDiv,
  dndStyles,
  th,
} from "../list-view-header.styled";
import { useEffect, useRef } from "react";
import {
  getCellInsertPosition,
  getCommonPinningStyles,
} from "../../../list-view.utils";
import { SortColumnIcon } from "./sort-column-icon";

interface DragTableFlexColumnHeaderProps {
  header: Header<any, unknown>;
}

export const HeaderCell: React.FC<DragTableFlexColumnHeaderProps> = ({
  header,
}) => {
  const { table, activeColumnIndex, tableColor, getSylesVariants, sorting } =
    useListViewHeader();
  const headerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    table.getAllColumns().forEach(column => {
      const { meta } = column.columnDef;

      if (meta?.isPinned && !column.getIsPinned()) {
        column.pin("left");
      }
    });
  }, []);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    over,
    index,
    isSorting,
    isDragging,
  } = useSortable({
    id: header.column.id,
  });

  const { meta } = header.column.columnDef;
  const metaHeader = meta?.header ?? { styles: {} };

  const insertPosition = getCellInsertPosition({
    overId: over?.id!,
    currentId: header.column.id,
    index,
    activeCellIndex: activeColumnIndex,
  });

  const {
    hasHoverBg,
    isPinned,
    colorColumn,
    hasBorder,
    isHidden,
    hasElipseContent,
    select,
    hasActions,
  } = getSylesVariants({
    header,
  });

  return (
    <div
      style={{
        ...dndStyles({ transform, transition, isSorting }),
        ...getCommonPinningStyles(header.column),
      }}
      ref={setNodeRef}
      className={th({
        hasHoverBg,
        insertPosition,
        isPinned,
        colorColumn,
        tableColor,
        isDragging,
        isHidden,
      })}
    >
      <div
        className={container({
          hasActions,
          hasBorder,
          select,
        })}
        ref={headerRef}
      >
        <div
          className={contentDiv({
            isHidden,
            hasElipseContent,
          })}
          {...(metaHeader?.isDraggable && { ...attributes, ...listeners })}
        >
          {flexRender(header.column.columnDef.header, header.getContext())}
        </div>

        {metaHeader.hasActions && (
          <div
            className="invisible absolute right-2 flex items-center group-hover:visible group-hover:static"
            data-testid="header-cell-actions-container"
          >
            <SortColumnIcon header={header} sorting={sorting} />
            <HeaderCellActions header={header} />
          </div>
        )}
      </div>

      {header.column.getCanResize() && (
        <ResizeIcon header={header} isSorting={isSorting} />
      )}
    </div>
  );
};
