import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";

import { CandidateApplication } from "~/scalis-components/pipeline/detailed-candidate-view";

export const fetchCandidateApplication = async (
  { signal }: QueryFunctionContext,
  applicationId: number,
) => {
  return await axios
    .get<CandidateApplication>(`/api/applications/candidate-application`, {
      signal,
      params: { applicationId },
    })
    .then(response => response.data)
    .catch(e => e.message);
};
