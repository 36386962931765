import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";

export type FetchBusinessUsersParams = {
  email?: string;
  companyId?: number | null;
};

export const fetchBusinessUsers = (
  { signal }: QueryFunctionContext,
  params?: FetchBusinessUsersParams,
) =>
  axios
    .get(`/api/business_users`, {
      signal,
      params,
    })
    .then(({ data }) => data.data);
