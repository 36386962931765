import React from "react";
import { RatingFieldProps } from "./rating-field.types";
import { Rating } from "../rating";
import { cn } from "~/utils/cn";

export const RatingField: React.FC<RatingFieldProps> = ({
  label,
  name,
  value,
  setValues,
  error,
}) => {
  return (
    <div className="w-full">
      <label className="mb-2 text-xs text-neutral-primary">{label}</label>
      <Rating name={name} onClick={setValues} value={value} />
      {error && (
        <span className="flex items-center gap-1 text-xs text-error">
          <i className="mi-circle-error"></i>
          {error}
        </span>
      )}
    </div>
  );
};
