import React, { createContext } from "react";
import useGetCompanyDefaultMessages from "~/hooks/defaultMessages/useGetCompanyDefaultMessages";
import {
  MatchCandidateDrawerContextProps,
  MatchCandidateDrawerContextState,
} from "./match-candidate-drawer-context.types";
import { useGetJobsSummary } from "~/hooks/jobOpening/useGetJobsSummary";
import { APPLICATION_TYPE } from "~/scalis-components";

export const MatchCandidateDrawerContext =
  createContext<MatchCandidateDrawerContextState>(
    {} as MatchCandidateDrawerContextState,
  );

export const MatchCandidateDrawerProvider: React.FC<
  MatchCandidateDrawerContextProps
> = ({ children, jobSeekerId, companyId, applicationType, defaultValues }) => {
  const [isNotifyCandidate, setIsNotifyCandidate] = React.useState<boolean>(
    defaultValues?.isNotifyCandidate || false,
  );
  const [isStaticProfile, setIsStaticProfile] = React.useState<boolean>(
    defaultValues?.isStaticProfile || false,
  );
  const { data: defaultMessages } = useGetCompanyDefaultMessages(companyId!);
  const { jobs } = useGetJobsSummary({
    onlyJobsWithPipeline: true,
  });
  React.useEffect(() => {
    if (applicationType) {
      const isStaticProfile = applicationType === APPLICATION_TYPE.STATIC;
      setIsStaticProfile(isStaticProfile);
      if (isStaticProfile) return;
      setIsNotifyCandidate(true);
    }
  }, [applicationType]);

  const contextValue: MatchCandidateDrawerContextState = React.useMemo(
    () => ({
      ...defaultValues,
      defaultMessages,
      jobSeekerId,
      companyId,
      isNotifyCandidate,
      setIsNotifyCandidate,
      isStaticProfile,
      setIsStaticProfile,
      jobs,
    }),
    [
      defaultValues,
      companyId,
      jobSeekerId,
      defaultMessages,
      isNotifyCandidate,
      setIsNotifyCandidate,
      isStaticProfile,
      setIsStaticProfile,
      jobs,
    ],
  );

  return (
    <MatchCandidateDrawerContext.Provider value={contextValue}>
      {children}
    </MatchCandidateDrawerContext.Provider>
  );
};
