import { useRouter } from "next/navigation";
import { CollapsibleListView } from "../list-view/collapsible-list-view";
import { mapMilestonesToCollapsableList } from "./milestones-list-utils";
import { MilestonesListProps } from "./milestones-list.types";

export const MilestonesList: React.FC<MilestonesListProps> = ({
  milestones,
  jobId,
  ...props
}) => {
  const router = useRouter();

  const collapsibleList = mapMilestonesToCollapsableList({
    milestones,
    jobId,
    ...props,
  });

  return (
    <div className="flex w-full flex-col gap-4 py-5">
      {collapsibleList.map(item => {
        return (
          <CollapsibleListView
            key={item.id}
            {...item}
            id={item.id}
            handleClickTitle={() => router.push(item.viewDetailsRoute)}
            open={true}
          />
        );
      })}
    </div>
  );
};
