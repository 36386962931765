import { Action } from "./action";
import { ActionType, ActionsProps } from "./action.types";
import { ACTIONS } from "./action.constants";
import useAdvanceCandidate from "~/hooks/candidate/useAdvanceCandidate";
import React from "react";
import { ApplicationType } from "@prisma/client";
import { useRejectCandidate } from "~app/company/(dashboard)/pipeline/components/reject-candidates";

export const Actions: React.FC<ActionsProps> = ({ actions, ...props }) => {
  const { setPreviousSelectCandidates, setOpenDrawer: setOpenRejectDrawer } =
    useRejectCandidate();
  const advance = useAdvanceCandidate();
  const handleAdvanceCandidate = React.useCallback(
    (action: ActionType) => {
      return advance({
        candidateName: props.name!,
        companyId: props?.companyId!,
        isFromTalentPool: action === ACTIONS.match_to_job,
        applicationType: props.applicationType! as ApplicationType,
        jobSeekerId: props.seekerId,
        uploadedCandidateId: props.uploadedCandidateId!,
        applicationId: props.applicationId,
        stageId: props.nextStageId ?? -1,
        jobNumber: props?.jobRequisitionNumber!,
        jobTitle: props?.jobTitle!,
        milestoneType: props.milestoneType!,
        alreadyInvitedToJob: props.alreadyInvitedToJob!,
        recentlyInvitedToAnotherJob: props.recentlyInvitedToAnotherJob!,
      })();
    },
    [props],
  );
  const actionsList = {
    [ACTIONS.advance]: handleAdvanceCandidate,
    [ACTIONS.match_to_job]: handleAdvanceCandidate,
    [ACTIONS.chat]: () => {},
    [ACTIONS.invite_to_apply]: handleAdvanceCandidate,
    [ACTIONS.reject]: () => {
      setPreviousSelectCandidates([
        {
          id: Number(props.applicationId),
          name: props.name,
          email: props.email ?? "",
        },
      ]);
      setOpenRejectDrawer(true);
    },
    [ACTIONS.reject_x]: () => {},
  };

  return (
    <div className="flex w-full justify-end gap-2 pb-2">
      {actions?.map((action: ActionType) => (
        <Action onClick={actionsList[action]} key={action} action={action} />
      ))}
    </div>
  );
};
