import { QueryFunctionContext } from "@tanstack/react-query";
import axios from "~/services/axios.service";
import { jobSeekerAccount } from "~/types/jobseeker";

export const fetchSources = ({
  signal,
}: QueryFunctionContext): Promise<jobSeekerAccount | undefined> =>
  axios
    .get("/api/jobseeker/uploadCandidate/sources", {
      signal,
    })
    .then(({ data }) => data.data);
