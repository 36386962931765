"use client";

import {
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  PointerActivationConstraint,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { Cell } from "@tanstack/react-table";
import { useContext } from "react";
import { ListViewContext } from "../../list-view-context";
import { columnsIdWithoutText, defaultColumnsID } from "../../list-view.utils";

export const useListViewBody = () => {
  const {
    table,
    dataIds,
    data,
    setData,
    tableColor,
    activeRowId,
    setActiveRowId,
  } = useContext(ListViewContext);

  const activeRowIndex = data.findIndex(item => item.id === activeRowId);

  const rowCount = dataIds.length;

  const getActiveRow = () => {
    if (activeRowId) {
      return table.getRowModel().rows.find(row => row.id === activeRowId);
    }

    return null;
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active?.id !== over?.id) {
      setData(data => {
        const oldIndex = dataIds.indexOf(active.id);
        const newIndex = dataIds.indexOf(over?.id!);
        return arrayMove(data, oldIndex, newIndex);
      });

      setActiveRowId(null);
    }
  };

  const handleDragStart = ({ active }: DragStartEvent) => {
    setActiveRowId(active.id);
  };

  const handleDragCancel = () => {
    setActiveRowId(null);
  };

  const activationConstraint: PointerActivationConstraint = {
    delay: 200,
    tolerance: 5,
  };

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint }),
    useSensor(TouchSensor, { activationConstraint }),
    useSensor(KeyboardSensor, {}),
  );

  const getSylesVariants = ({
    cell,
    isSelected,
    active,
  }: {
    cell: Cell<unknown, unknown>;
    isSelected: boolean;
    active: boolean;
  }) => {
    const { meta } = cell.column.columnDef;
    const metaRows = meta?.rows ?? { styles: {} };

    return {
      isActive: isSelected || active,
      isHidden: metaRows?.styles?.hidden,
      isPinned: !!cell.column.getIsPinned(),
      isColoredColumn: cell.column.id === defaultColumnsID.select,
      hasHoverBg: !metaRows?.styles?.noHoverBg,
      isLastColumn: cell.row.index === rowCount - 1,
      isHideHeaderShadow: cell.column.id === defaultColumnsID.rowActions,
      hasBorder: !metaRows?.styles?.noBorders,
      hasElipseContent: !columnsIdWithoutText.includes(cell.column.id),
      colorColumn: cell.column.id === defaultColumnsID.select,
      hasLessPadding: metaRows?.lessPadding,
    };
  };

  return {
    table,
    dataIds,
    activeRowIndex,
    handleDragEnd,
    handleDragStart,
    handleDragCancel,
    sensors,
    tableColor,
    rowCount,
    getActiveRow,
    getSylesVariants,
  };
};
