import React from "react";
import { Label } from "~/components/v2/Label";
import { RadioGroup, RadioGroupItem } from "~/components/v2/RadioGroup";
import { NotificationRadioProps } from "./notification-radio.types";
import {
  FIELD_NAME_NOTIFICATION_RADIO,
} from "./notification-radio.constants";
import { kebabCase } from "lodash";

export const NotificationRadio: React.FC<NotificationRadioProps> = ({
  label,
  defaultValue,
  onValueChange,
  notificationOptions
}) => {

  React.useEffect(() => {
    onValueChange?.(FIELD_NAME_NOTIFICATION_RADIO, defaultValue)
  }, []);

  const handleValueChange = (value: string) => {
    onValueChange?.(FIELD_NAME_NOTIFICATION_RADIO, value)
  }

  return (
    <div className="flex flex-col gap-4">
      {label && <div className="text-base font-semibold text-typography-high-contrast">
        {label}
      </div>}
      <RadioGroup
        name={FIELD_NAME_NOTIFICATION_RADIO}
        defaultValue={defaultValue}
        onValueChange={handleValueChange}
      >
        {notificationOptions?.map((option) => (
          <div className="flex items-center space-x-2" key={option.label}>
            <RadioGroupItem value={option.value} id={kebabCase(option.label)} />
            <Label htmlFor={kebabCase(option.label)}>{option.label}</Label>
          </div>
        ))}
      </RadioGroup>
    </div>
  );
};
