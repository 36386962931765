import React from "react";
import { DateRangeFieldProps } from "./date-range-field.types";
import { CollapsibleFilterField } from "../collapsible-filter-field";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "~/components/v2/DatePicker";

export const DateRangeField = React.forwardRef<
  HTMLInputElement,
  DateRangeFieldProps
>(({ label, name, mode = "single" }, ref) => {
  const { resetField, control, watch } = useFormContext();

  const value = watch(name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <CollapsibleFilterField
          label={label}
          onResetField={() => resetField(name)}
          showReset={!!value}
          defaultOpen={!!value}
        >
          <DatePicker
            date={value}
            onSelect={onChange}
            mode={mode}
            className="rounded-md border-borders"
          />
        </CollapsibleFilterField>
      )}
    />
  );
});
