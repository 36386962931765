"use client";

import React, { useContext } from "react";
import { CandidatePagination } from "../../../scalis-components/pipeline/list-view/candidate-pagination";
import { ListViewContext } from "../list-view/list-view-context";

export const ListViewPagination = () => {
  const { pagination, data, table } = useContext(ListViewContext);

  const onPageChange = (page: number) => {
    table.setPageIndex(page - 1);
  };

  if (data.length < pagination.pageSize) {
    return null;
  }

  return (
    <div id="candidate-pagination" className="px-8 pb-1 pt-3">
      <CandidatePagination
        currentPage={pagination.pageIndex + 1}
        onPageChange={onPageChange}
        totalPages={Number(table.getPageCount().toLocaleString())}
        rowsPerPage={pagination.pageSize}
        className="left-8"
        position="sticky"
      />
    </div>
  );
};
