import { load } from "cheerio";
import { useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaRegCircleCheck,
  FaRegCircleXmark,
} from "react-icons/fa6";

import { Label } from "~/components/v2/Label";
import { RadioGroup, RadioGroupItem } from "~/components/v2/RadioGroup";
import { cn } from "~/utils/cn";
import { IStagePanelProps } from "./screening-questions.types";
import { verifyQuestion } from "./screening-questions.utils";

export const StagePanel = ({ item }: IStagePanelProps) => {
  const [hiddenQuestions, setHiddenQuestions] = useState(false);
  const [questionVisibility, setQuestionVisibility] = useState(
    Array(item.questions.length).fill(true),
  );

  const toggleQuestionVisibility = (index: number) => {
    const newVisibility = [...questionVisibility];
    newVisibility[index] = !newVisibility[index];
    setQuestionVisibility(newVisibility);
  };

  return (
    <div className="flex flex-col" data-testid="stage-panel-sq">
      <div
        className={cn("flex cursor-pointer items-center gap-1 px-3")}
        onClick={() => setHiddenQuestions(state => !state)}
        data-testid="div-click-sq"
      >
        <i
          className={cn("mi-caret-down text-lg text-icons-low-contrast", {
            "mi-caret-right": hiddenQuestions,
          })}
          data-testid="icon-mi-caret-sq"
        />
        <span className={cn("text-sm text-typography-high-contrast")}>
          {item?.jobTitle || ""}
        </span>
      </div>

      {item.questions.map((question: any, index: number) => {
        const $ = load(question.question);
        let contentQuestion = "";

        if ($("p").length > 0) {
          contentQuestion = $("p").text();
        } else if ($("p").length > 0) {
          contentQuestion = $("p").text();
        } else {
          contentQuestion = question.question;
        }

        return (
          <div
            className={cn({ hidden: hiddenQuestions })}
            key={index}
            data-testid="container-question-sq"
          >
            <div className="border-b border-borders">
              <div
                className="flex items-center justify-between px-4 py-[10px] hover:cursor-pointer hover:bg-background-hover"
                onClick={() => toggleQuestionVisibility(index)}
              >
                <div className="flex items-center">
                  <div className="flex items-center gap-2">
                    <span
                      className="text-xs text-icons-low-contrast"
                      data-testid="quantity-questions-sq"
                    >{`${index + 1}/${item.questions.length}`}</span>
                    {verifyQuestion(question) ? (
                      <FaRegCircleCheck
                        className="h-4 w-4 text-success-dark"
                        data-testid="checkcircle"
                      />
                    ) : (
                      <FaRegCircleXmark
                        className="h-4 w-4 text-destructive-default"
                        data-testid="xcircle"
                      />
                    )}
                  </div>

                  <span
                    className={cn(
                      "ml-4 text-sm text-typography-high-contrast",
                      {
                        "text-typography-muted": !question?.title,
                      },
                    )}
                  >
                    {question?.title || "No title provided"}
                  </span>
                </div>

                {questionVisibility[index] ? (
                  <FaChevronDown className="cursor-pointer text-sm text-icons-secondary" />
                ) : (
                  <FaChevronUp className="cursor-pointer text-sm text-icons-secondary" />
                )}
              </div>

              <div
                className={cn({ hidden: questionVisibility[index] })}
                data-testid="containerAnswers-sq"
              >
                <div className="flex flex-col pb-4 pl-[79px] pr-4 pt-2">
                  <span className="text-sm text-typography-high-contrast">
                    {contentQuestion}
                  </span>

                  {question.type === "numerical" && (
                    <div className="mt-2 flex flex-col gap-2">
                      <span className="text-sm text-typography-low-contrast-text">
                        {question.answer.option === "to" &&
                          `${question.answer?.range1} to ${question.answer?.range2} (preferred answer)`}

                        {question.answer.option === "greater" &&
                          `${question.answer?.range1} or greater (preferred answer)`}

                        {question.answer.option === "less" &&
                          `${question.answer?.range1} or less (preferred answer)`}
                      </span>
                      <span className="text-sm font-normal text-typography-low-contrast-text">
                        Answer: {question?.ansJobSeeker}
                      </span>
                    </div>
                  )}

                  {question.type === "binary" && (
                    <div className="mt-2 flex flex-col gap-2">
                      <RadioGroup onValueChange={() => false} disabled>
                        {["Yes", "No"].map((item: string) => (
                          <div
                            className="flex items-center space-x-2"
                            key={item}
                          >
                            <RadioGroupItem
                              id="yes"
                              value="1"
                              className="disabled:opacity-1 border-typography-high-contrast bg-white data-[state=checked]:border-typography-high-contrast data-[state=checked]:bg-white"
                              checked={
                                item.toUpperCase() ===
                                question?.ansJobSeeker?.toUpperCase()
                              }
                              classNamePrimitive={"bg-typography-high-contrast"}
                            />
                            <Label htmlFor="yes">{item}</Label>
                            {item.toUpperCase() ===
                              question.answer.binary_ans?.toUpperCase() && (
                              <span className="text-sm text-typography-low-contrast-text">
                                (preferred answer)
                              </span>
                            )}
                          </div>
                        ))}
                      </RadioGroup>
                    </div>
                  )}

                  {question.type === "multiple" && (
                    <div className="mt-2 flex items-center">
                      <RadioGroup
                        onValueChange={() => false}
                        disabled
                        className="flex flex-col gap-3"
                      >
                        {question.options?.map((item: any) => (
                          <div
                            className="flex items-center space-x-2"
                            key={item}
                          >
                            <RadioGroupItem
                              id="yes"
                              value="1"
                              className="disabled:opacity-1 border-typography-high-contrast bg-white data-[state=checked]:border-typography-high-contrast data-[state=checked]:bg-white"
                              checked={item === question?.ansJobSeeker}
                              classNamePrimitive={"bg-typography-high-contrast"}
                            />
                            <Label htmlFor="yes">{item}</Label>
                            {item === question.answer.multi_ans && (
                              <span className="text-sm font-normal text-typography-low-contrast-text">
                                (preferred answer)
                              </span>
                            )}
                          </div>
                        ))}
                      </RadioGroup>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
