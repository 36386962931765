import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import LocationField from "~/components/v2/LocationField";
import { TextField } from "~/components/v2/TextField";
import useSetLocation from "~/hooks/useSetLocation";
import { FormButtons } from "~/scalis-components/pipeline/detailed-candidate-view/components/detailed-candidate-tabs/components/tabs/details/form-buttons";
import {
  DetailsEntry,
  DetailsEntryTypeEnum,
  LocationEntrySchema as schema,
} from "~/services/jobSeekerApplications.types";

interface LocationFormProps {
  onSubmit: (data: DetailsEntry) => Promise<void>;
  loading?: boolean;
  onCancel: () => void;
}

export const LocationForm: React.FC<LocationFormProps> = ({
  onSubmit,
  loading,
  onCancel,
}) => {
  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: { type: DetailsEntryTypeEnum.Location },
  });

  const { handlePostalCode } = useSetLocation({
    setValue,
    setAddressLine: true,
    postalCodeKey: "postalCode",
    addressLineKey: "addressLine1",
  });

  const submit = (data: z.infer<typeof schema>) => {
    onSubmit(data).then(() => {
      reset();
    });
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className="flex flex-col gap-2 text-dark-color-text-100"
    >
      <div className="flex flex-1 items-start gap-4">
        <LocationField
          setValue={handlePostalCode}
          name="postalCode"
          value={watch("postalCode")}
          label="Postal Code"
          containerClassName="flex-1"
          types={["postal_code"]}
          error={errors.postalCode?.message as string}
        />
        <div className="h-px w-[4.5rem]" />
      </div>
      <div className="flex flex-1 items-start gap-4">
        <TextField
          {...register("state")}
          required
          label="State"
          containerClassName="flex-1"
          error={errors.state?.message as string}
        />
        <div className="h-px w-[4.5rem]" />
      </div>
      <div className="flex flex-1 items-start gap-4">
        <TextField
          {...register("city")}
          label="City"
          required
          containerClassName="flex-1"
          error={errors.city?.message as string}
        />
        <div className="h-px w-[4.5rem]" />
      </div>
      <div className="flex flex-1 items-start gap-4">
        <TextField
          {...register("addressLine1")}
          label="Address Line 1"
          containerClassName="flex-1"
          error={errors.addressLine1?.message as string}
        />
        <div className="h-px w-[4.5rem]" />
      </div>
      <div className="flex flex-1 items-start gap-4">
        <TextField
          {...register("addressLine2")}
          label="Address Line 2"
          containerClassName="flex-1"
          error={errors.addressLine2?.message as string}
        />
        <FormButtons
          className="mt-[27px]"
          loading={loading}
          onCancel={onCancel}
        />
      </div>
    </form>
  );
};
