import { Candidate } from "~/queries/fetch-candidate-list-view"
import { Note } from "./note"
import { Divider } from "~/components/v2/Divider"

export const Notes: React.FC<Pick<Candidate, "candidateNotes">> = ({ candidateNotes }) => {
    if (!candidateNotes?.length) return null

    return <><h1>Notes</h1>
        {candidateNotes?.map((note, index: number) => (
            <div key={note.content}>
                {index !== 0 ? <Divider /> : null}
                <Note {...note} />
            </div>
        ))}
    </>
}
