import React from "react";
import { CandidatePaginationProps } from "./candidate-pagination.types";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { PageButtons } from "./components/page-buttons";
import { pagination } from "./candidate-pagination.styles";

import { ToolTipButton } from "./components/tooltip-button";

export const CandidatePagination: React.FC<CandidatePaginationProps> = ({
  totalPages,
  currentPage,
  onPageChange,
  rowsPerPage,
  className,
  position,
}) => {
  const { buttonsContainer, container, rowsContainer } = pagination({
    position,
  });

  return (
    <div className={container()}>
      <div className={rowsContainer({ class: className })}>
        {rowsPerPage} rows per page
      </div>
      <div className={buttonsContainer()}>
        <div className="text-xs text-typography-low-contrast-text">
          Page {currentPage} of {totalPages}
        </div>
        <div className="flex items-center">
          <ToolTipButton
            className="pr-0"
            Icon={ChevronDoubleLeftIcon}
            disabled={currentPage === 1}
            onClick={() => onPageChange(1)}
            tooltip="First Page"
          />

          <ToolTipButton
            className="pl-0"
            Icon={ChevronLeftIcon}
            disabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
            tooltip="Previous Page"
          />

          <PageButtons
            currentPage={currentPage}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />

          <ToolTipButton
            className="pr-0"
            Icon={ChevronRightIcon}
            disabled={currentPage === totalPages}
            onClick={() => onPageChange(currentPage + 1)}
            tooltip="Next Page"
          />

          <ToolTipButton
            className="pl-0"
            Icon={ChevronDoubleRightIcon}
            disabled={currentPage === totalPages}
            onClick={() => onPageChange(totalPages)}
            tooltip="Last Page"
          />
        </div>
      </div>
    </div>
  );
};
