import { DefaultMessages } from "@prisma/client";
import type { RejectCandidateData } from "../../../reject-candidate-context";
import { FormValues } from "./step-select-candidates.schema";
import { Candidate } from "./step-select-candidates.types";
import { getDefaultMessage } from "~/scalis-components/pipeline";
import { MEDALIST_STATUS } from "./components/internal-notes-section/internal-notes-section.constants";

export const parseDataToRejectContext = (
  data: FormValues,
  defaultMessages: Partial<DefaultMessages>[] = [],
): Partial<RejectCandidateData> => {
  const { candidates, reason, details, medalistDesignation } = data;

  const applicationIds = parseCandidates(candidates);
  const message = parseMessage(data, defaultMessages);

  const parsedData = {
    message,
    applicationIds,
    reason,
    details,
  } as Partial<RejectCandidateData>;

  if (medalistDesignation && medalistDesignation !== MEDALIST_STATUS.NONE) {
    parsedData.medalistDesignation = medalistDesignation;
  }

  return parsedData;
};

const parseCandidates = (candidates: Candidate[]) => {
  return candidates.map(({ id }) => id);
};

const parseMessage = (
  data: FormValues,
  defaultMessages: Partial<DefaultMessages>[],
) => {
  const { custom_message, default_message, cb_default_message } = data;

  if (!cb_default_message) {
    return custom_message;
  }

  return getDefaultMessage(defaultMessages || [], default_message ?? "0");
};
