import React from "react";
import { LoadingState } from "./components/loading-state";
import { ErrorState } from "./components/error-state";
import { EmptyState } from "./components/empty-state";
import { ListStatusPlaceholderProps } from "./list-status-placeholder.types";

export const ListStatusPlaceholder: React.FC<ListStatusPlaceholderProps> = ({
  hasError,
  isLoading,
  isEmpty,
  loaderComponentProps = {},
  errorComponentProps = {},
  emptyComponentProps = {},
  children,
  name,
}) => {
  if (hasError) {
    return <ErrorState {...errorComponentProps} />;
  }

  if (isLoading) {
    return <LoadingState {...loaderComponentProps} />;
  }

  if (isEmpty) {
    return <EmptyState {...emptyComponentProps} name={name} />;
  }

  return children ?? null;
};
