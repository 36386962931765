"use client";

import { Controller, useFormContext } from "react-hook-form";
import { PopUpFilter } from "../../pop-up-filter";
import { Select } from "../../../../filter-type";

interface SelectFilterProps {
  options?: { label: string; value: string }[];
  name: string;
  label: string;
  onApplyFilters: (data: any) => void;
}

export const SelectFilter: React.FC<SelectFilterProps> = ({
  options,
  name,
  label,
  onApplyFilters,
}) => {
  const { setValue, control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, ref } }) => (
        <PopUpFilter
          name={name}
          label={label}
          active={!!value?.length}
          onApplyFilters={onApplyFilters}
        >
          <Select
            multi
            emptyTerm=""
            options={options}
            placeholder=""
            name={name}
            searchPlaceholder="search"
            value={value}
            setValue={setValue}
            className="w-full rounded-lg"
            ref={ref}
          />
        </PopUpFilter>
      )}
    />
  );
};
