import React, { useState } from "react";
import { Input } from "~/components/v2/Input";

interface SearchInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

export const SearchInput = React.forwardRef<HTMLInputElement, SearchInputProps>(
  (inputProps, ref) => {
    return (
      <div className="relative flex items-center">
        <i className="fa-regular fa-magnifying-glass absolute left-3" />
        <Input
          ref={ref}
          placeholder="Search"
          className="rounded-lg pl-10"
          {...inputProps}
        />
      </div>
    );
  },
);
