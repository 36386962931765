import { PlusIcon } from "@radix-ui/react-icons";
import { ScrollArea } from "@radix-ui/react-scroll-area";
import { useState } from "react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/components/v2/Command";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "~/components/v2/Popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/v2/Tooltip";
import { AddTableButtonProps } from "./add-table-button.types";
import { cn } from "~/utils/cn";

export const AddTableButton: React.FC<AddTableButtonProps> = ({ options }) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>
              <div
                data-testid="addColumn"
                className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full p-1 hover:bg-bluegray-40 focus:outline-none data-[state=open]:bg-bluegray-50"
              >
                <PlusIcon className="h-4 w-4 text-dark-color-text-100" />
              </div>
            </PopoverTrigger>
          </TooltipTrigger>
          <TooltipContent>
            <p className="z-50">Add Column</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <PopoverContent
        className={cn(
          "text-popover-foreground z-50 rounded-md border bg-white p-0 shadow-md outline-none",
          "data-[state=open]:animate-in data-[state=open]:fade-in-0 data-[state=open]:zoom-in-95",
          "data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95",
          "data-[side=bottom]:slide-in-from-top-2 data-[side=top]:slide-in-from-bottom-2",
          "data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2",
        )}
        sideOffset={0}
        align="end"
      >
        <Command>
          <CommandInput placeholder="Find column name" className="h-9" />
          <CommandEmpty>No column name found.</CommandEmpty>
          <ScrollArea className="h-[200px]">
            <CommandGroup className="overflow-none">
              {options.map(({ id, label, handleClick }) => (
                <CommandItem
                  value={id}
                  key={id}
                  onSelect={() => {
                    handleClick();
                    setOpen(false);
                  }}
                  className="line-clamp-1 data-[selected=true]:bg-accent-1-40"
                >
                  {label}
                </CommandItem>
              ))}
            </CommandGroup>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
