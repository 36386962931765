import { Drawer, DrawerContent, DrawerHeader } from "~/components/v2/Drawer";
import { InviteCandidateDrawerForm } from "./invite-candidate-drawer-form";
import { InviteScalisCandidateDrawerProps } from "./invite-candidate-drawer.types";

export const InviteScalisCandidateDrawer = ({
    open,
    onOpenChange,
    companyId,
    applicationId,
    candidateName,
}: InviteScalisCandidateDrawerProps) => {
    return (
        <Drawer open={open} onOpenChange={onOpenChange}>
            <DrawerContent asChild>
                <div>
                    <DrawerHeader className="text-center">
                        <h1 className="text-xl font-semibold text-typography-high-contrast">
                            Advance Candidate
                        </h1>
                        <h2 className="text-sm text-typography-low-contrast">
                            {candidateName}
                        </h2>
                    </DrawerHeader>
                    <InviteCandidateDrawerForm
                        companyId={companyId}
                        applicationId={applicationId}
                        onOpenChange={onOpenChange}
                    />
                </div>
            </DrawerContent>
        </Drawer>
    );
};
