const EARTH_RADIUS_MILES = 3963;

const toRad = (degree: number) => (degree * Math.PI) / 180;

type Point = { lat: number; lng: number };

export const distance = (pointA: Point, pointB: Point) => {
  const r = EARTH_RADIUS_MILES;
  const deltaLat = toRad(pointB.lat - pointA.lat);
  const deltaLng = toRad(pointB.lng - pointA.lng);
  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(toRad(pointA.lat)) *
      Math.cos(toRad(pointB.lat)) *
      Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = r * c;
  return distance;
};
