import question from "~/pages/api/applications/reject/question";

export const DEFAULT_SELECT_CANDIDATE_FORM_DATA = {
  jobId: "",
  applicationIds: [],
  reason: "",
  details: "",
  message: "",
  questions: [],
};
