import { ChevronDownIcon } from "@heroicons/react/24/outline";
import * as PopoverPrimitive from "@radix-ui/react-popover";
import { useState } from "react";

import { Button } from "~/components/v2/Button";
import { Checkbox } from "~/components/v2/Checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "~/components/v2/Command";
import { Popover, PopoverTrigger } from "~/components/v2/Popover";
import { ScrollArea } from "~/components/v2/ScrollArea";
import { cn } from "~/utils/cn";

export type Option = {
  label: string;
  value: number;
  details?: string;
};

type ICombobox = {
  emptyTerm: string;
  name: string;
  options: Option[];
  placeholder: string;
  searchPlaceholder: string;
  disabled?: boolean;
  className?: string;
  onBlur?: () => void;
  setValue: (value: number[]) => void;
  value: number[];
};

export function CustomCombobox({
  emptyTerm,
  name,
  options,
  placeholder,
  searchPlaceholder,
  setValue,
  value,
  disabled,
  className,
  onBlur,
}: ICombobox) {
  const [open, setOpen] = useState<boolean>(false);
  const handleSelect = (newValue: number) => {
    const values = value;
    if (values?.includes(newValue)) {
      setValue(values.filter((v: any) => v !== newValue));
    } else {
      setValue([...(values || []), newValue]);
    }
  };

  const handleOpenChange = (state: boolean) => {
    if (!state && onBlur) {
      onBlur();
    }
    setOpen(state);
  };

  return (
    <div className="flex flex-col gap-1" data-testid="combo-box-sq">
      <Popover open={open} onOpenChange={handleOpenChange}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            disabled={disabled}
            className={cn(
              "justify-between border-white-90 font-medium text-white-85 hover:bg-white active:bg-white disabled:opacity-50",
              className,
            )}
          >
            {placeholder}
            <ChevronDownIcon className="h-4 w-4 text-dark-color-text-100" />
          </Button>
        </PopoverTrigger>
        <PopoverPrimitive.Content
          className="text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 w-popover-trigger rounded-md border bg-white p-0 shadow-md outline-none"
          sideOffset={0}
        >
          <Command>
            <CommandInput placeholder={searchPlaceholder} className="h-9" />
            <CommandEmpty>No {emptyTerm} found.</CommandEmpty>
            <ScrollArea className="h-[200px]">
              <CommandGroup className="overflow-none">
                {options.map(option => (
                  <CommandItem
                    value={option.label}
                    key={option.value}
                    onSelect={() => {
                      handleSelect(option.value);
                    }}
                    className={cn(
                      "line-clamp-1 flex items-center gap-2 data-[selected=true]:bg-accent-1-40",
                      {
                        "bg-accent-1-40": value.includes(option.value),
                      },
                    )}
                  >
                    <Checkbox checked={value.includes(option.value)} />
                    <div className="flex flex-col">
                      {option.label}
                      <span className="text-xs font-light text-white-70">
                        {option.details}
                      </span>
                    </div>
                  </CommandItem>
                ))}
              </CommandGroup>
            </ScrollArea>
          </Command>
        </PopoverPrimitive.Content>
      </Popover>
    </div>
  );
}
