import { formatDistanceToNowStrict } from "date-fns";

import { CandidateNote } from "~/scalis-components/pipeline/detailed-candidate-view";

export const NoteCard: React.FC<CandidateNote & { userId: number }> = ({
  notes,
  createdAt,
  user,
  userId,
}) => {
  return (
    <div className="p-4 border-b border-borders">
      <div className="mx-auto max-lg:max-w-[32.5rem]">
        <div className="flex items-center justify-between gap-1 text-xs text-typography-low-contrast-text lg:justify-start">
          {createdAt
            ? formatDistanceToNowStrict(new Date(createdAt)) + " ago"
            : ""}
          <span className="hidden lg:block">⋅</span>
          <span>
            {userId === user.id
              ? "you"
              : `${user.first_name} ${user.last_name}`}
          </span>
        </div>
        <div>
          <span className="text-sm text-typography-high-contrast">{notes}</span>
        </div>
      </div>
    </div>
  );
};
