import React from "react";
import { Input } from "~/components/v2/Input";
import { Slider } from "~/components/v2/Slider";
import { MatchScoreProps } from "./match-score.types";

export const MatchScore: React.FC<MatchScoreProps> = ({ onChange, value }) => {
  return (
    <div className="mb-[-21px]">
      <div className="mb-3 flex items-center gap-7">
        <Input
          value={value[0]}
          onChange={({ target }) => {
            onChange([target.value, value[1]]);
          }}
          type="number"
          min={0}
          max={value[1] - 1}
          name="minMatchScore"
        />
        to
        <Input
          value={value[1]}
          onChange={({ target }) => {
            onChange([value[0], target.value]);
          }}
          type="number"
          min={value[0] + 1}
          max={100}
          name="maxMatchScore"
        />
      </div>
      <Slider
        minStepsBetweenThumbs={5}
        value={value}
        onValueChange={onChange}
      />
    </div>
  );
};
