import { useRouter } from "next/navigation";
import { createContext, useState } from "react";

import { DetailedCandidateDrawer } from "./components/detailed-candidate-drawer";
import {
  DetailedCandidateContextProps,
  DetailedCandidateProviderProps,
} from "./detailed-candidate-context.types";

export const DetailedCandidateContext = createContext(
  {} as DetailedCandidateContextProps,
);

export const DetailedCandidateProvider = ({
  initialJobId,
  initialApplicationId,
  children,
}: DetailedCandidateProviderProps) => {
  const router = useRouter();

  const [openCandidateDrawer, setOpenCandidateDrawer] = useState(false);
  const [applicationId, setApplicationId] = useState<number | null>(
    initialApplicationId || null,
  );
  const [jobId, setJobId] = useState<number | null>(initialJobId || null);

  const applyApplicationId = (id: number) => {
    setApplicationId(id);
  };

  const applyJobId = (id: number) => {
    setJobId(id);
  };

  const goToDetailedCandidatePage = () => {
    const url = `/company/pipeline/${jobId}/${applicationId}`;

    router.push(url);
  };

  const openDetailedCandidateDrawer = () => {
    setOpenCandidateDrawer(true);
  };

  return (
    <DetailedCandidateContext.Provider
      value={{
        jobId,
        applicationId,
        applyApplicationId,
        applyJobId,
        goToDetailedCandidatePage,
        openDetailedCandidateDrawer,
      }}
    >
      {children}
      {jobId && applicationId && (
        <DetailedCandidateDrawer
          jobId={jobId}
          initialApplicationId={applicationId}
          open={openCandidateDrawer}
          onOpenChange={setOpenCandidateDrawer}
        />
      )}
    </DetailedCandidateContext.Provider>
  );
};
