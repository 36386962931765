import { degreeOptions } from "~/components/forms/v2/jobseeker/Education/utils";
import {
  FIELD_NAME_CANDIDATE_NAME,
  FIELD_NAME_CANDIDATE_TIME_ZONE,
  FIELD_NAME_COMPANY,
  FIELD_NAME_DEGREE,
  FIELD_NAME_EMAIL,
  FIELD_NAME_JOB_TITLE,
  FIELD_NAME_SKILLS,
  FORM_FIELDS,
} from "./candidate-flters.contants";
import { timezones } from "~/helper/timezones";

export const filterTabs = [
  {
    label: "Candidates",
    value: "candidates",
  },
  // {
  //   label: "Active Candidates",
  //   value: "activeCandidates",
  // },
  // {
  //   label: "SCALIS Candidates",
  //   value: "scalisCandidates",
  // },
  // {
  //   label: "Uploaded",
  //   value: "uploaded",
  // },
  // {
  //   label: "My Candidates",
  //   value: "myCandidates",
  // },
  // {
  //   label: "Referrals",
  //   value: "referrals",
  // },
  // {
  //   label: "Invited to Apply",
  //   value: "invitedToApply",
  // },
  // {
  //   label: "Rejected",
  //   value: "rejected",
  // },
];

export const filterSelects = [
  FIELD_NAME_JOB_TITLE,
  FIELD_NAME_COMPANY,
  FIELD_NAME_DEGREE,
  FIELD_NAME_CANDIDATE_NAME,
  FIELD_NAME_EMAIL,
  FIELD_NAME_SKILLS,
];

export const mapFieldOptions = (fields: { [key: string]: any[] }) => {
  const fieldKeys = Object.keys(fields) ?? [];

  fieldKeys.push(FIELD_NAME_CANDIDATE_TIME_ZONE);

  return fieldKeys.reduce<any>((acc, field) => {
    switch (field) {
      case FIELD_NAME_CANDIDATE_TIME_ZONE: {
        acc[field] = timezones().map(({ timeZone, formattedText }) => {
          return {
            label: formattedText,
            value: timeZone,
          };
        });

        return acc;
      }

      case FIELD_NAME_JOB_TITLE: {
        acc[field] = fields[field].map(({ jobTitle }) => {
          return {
            label: jobTitle,
            value: jobTitle,
          };
        });

        return acc;
      }

      case FIELD_NAME_COMPANY: {
        acc[field] = fields[field].map(({ company }) => {
          return {
            label: company,
            value: company,
          };
        });

        return acc;
      }

      case FIELD_NAME_DEGREE: {
        acc[field] = fields[field].map(option => {
          return degreeOptions.find(degree => degree.value === option);
        });

        return acc;
      }

      default: {
        acc[field] = fields[field].map(option => {
          return {
            label: option,
            value: option,
          };
        });

        return acc;
      }
    }
  }, {});
};
