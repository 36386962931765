import { CandidateNotes } from "@prisma/client";
import axios from "~/services/axios.service";

export const createNote = ({
  data,
  applicationId,
}: {
  data: Partial<CandidateNotes>;
  applicationId: number;
}) =>
  axios
    .post(`/api/candidates/${applicationId}/note`, data)
    .then(response => response.data)
    .catch(e => e.message);
